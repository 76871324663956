export const TransactionsActionTypes = {
    API_RESPONSE_SUCCESS: '@@transactions/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@transactions/API_RESPONSE_ERROR',
    // Transactions
    GET_TRANSACTIONS: '@@transactions/GET_TRANSACTIONS',
    GET_TRANSACTION_BY_ID: '@@transactions/GET_TRANSACTION_BY_ID',
    GET_TRANSACTIONS_BY_USER: '@@transactions/GET_TRANSACTIONS_BY_USER',
    CREATE_TRANSACTION: '@@transactions/CREATE_TRANSACTION',
    UPDATE_TRANSACTION: '@@transactions/UPDATE_TRANSACTION',
    DELETE_TRANSACTION: '@@transactions/DELETE_TRANSACTION',
    ARCHIVE_TRANSACTION: '@@transactions/ARCHIVE_TRANSACTION',
    RESET_TRANSACTIONS: '@@transactions/RESET_TRANSACTIONS',
};
