// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

/**
 * Review an extension
 * @param {*} params
 * @returns
 */
function reviewPDF(params: any): any {
    const { parent, id, document, data } = params;
    const baseUrl = `${config.server.api}/v1/${parent}/${id}/${document}/review`;
    return api.get(`${baseUrl}`, data);
}

/**
 * Review an extension
 * @param {*} params
 * @returns
 */
function signEtchPacket(params: any): any {
    const { parent, id, document, data } = params;
    const baseUrl = !document
        ? `${config.server.api}/v1/${parent}/${id}/sign`
        : `${config.server.api}/v1/${parent}/${id}/${document}/sign`;
    return api.create(`${baseUrl}`, data);
}

export { reviewPDF, signEtchPacket };
