import { InvitationsActionTypes } from './constants';

const INIT_STATE = {
    data: null,
    loading: false,
    error: null,
    updateSuccess: null,
};

const Invitation = (state = INIT_STATE, action) => {
    switch (action.type) {
        case InvitationsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case InvitationsActionTypes.GET_INVITATION_BY_ID:
                    return { ...state, data: action.payload.data, loading: false };
                case InvitationsActionTypes.CREATE_INVITATION:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        createSuccess: true,
                    };
                case InvitationsActionTypes.SEND_INVITATION_BY_OFFER:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        createSuccess: true,
                    };
                case InvitationsActionTypes.SEND_INVITATION_BY_LISTING:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        createSuccess: true,
                    };
                case InvitationsActionTypes.UPDATE_INVITATION:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                case InvitationsActionTypes.CANCEL_INVITATION:
                    return {
                        ...state,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                case InvitationsActionTypes.ACCEPT_INVITATION:
                    return {
                        ...state,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                case InvitationsActionTypes.RESEND_INVITATION:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                case InvitationsActionTypes.REVOKE_INVITATION_BY_OFFER:
                    return {
                        ...state,
                        id: action.payload.id,
                        loading: false,
                        revokedSuccess: true,
                    };
                case InvitationsActionTypes.REVOKE_INVITATION_BY_LISTING:
                    return {
                        ...state,
                        id: action.payload.id,
                        loading: false,
                        revokedSuccess: true,
                    };
                case InvitationsActionTypes.DELETE_INVITATION:
                    return { ...state, data: action.payload.data, loading: false };
                default:
                    return { ...state };
            }
        case InvitationsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case InvitationsActionTypes.GET_INVITATION_BY_ID:
                    return { ...state, error: action.payload, loading: false };
                case InvitationsActionTypes.CREATE_INVITATION:
                    return { ...state, error: action.payload, loading: false, createSuccess: false };
                case InvitationsActionTypes.SEND_INVITATION_BY_OFFER:
                    return { ...state, error: action.payload, loading: false, createSuccess: false };
                case InvitationsActionTypes.SEND_INVITATION_BY_LISTING:
                    return { ...state, error: action.payload, loading: false, createSuccess: false };
                case InvitationsActionTypes.UPDATE_INVITATION:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case InvitationsActionTypes.CANCEL_INVITATION:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case InvitationsActionTypes.ACCEPT_INVITATION:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case InvitationsActionTypes.RESEND_INVITATION:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case InvitationsActionTypes.REVOKE_INVITATION_BY_OFFER:
                    return { ...state, error: action.payload, loading: false, revokedSuccess: false };
                case InvitationsActionTypes.REVOKE_INVITATION_BY_LISTING:
                    return { ...state, error: action.payload, loading: false, revokedSuccess: false };
                case InvitationsActionTypes.DELETE_INVITATION:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                default:
                    return { ...state };
            }
        // Get a single task
        case InvitationsActionTypes.GET_INVITATION_BY_ID:
            return { ...state, data: null, loading: true };
        // Update a task
        case InvitationsActionTypes.UPDATE_INVITATION:
            return { ...state, id: action.payload.id, data: action.payload.data, loading: true };
        case InvitationsActionTypes.CANCEL_INVITATION:
            return { ...state, id: action.payload.id, data: action.payload.data, loading: true };
        case InvitationsActionTypes.ACCEPT_INVITATION:
            return { ...state, id: action.payload.id, data: action.payload.data, loading: true };
        case InvitationsActionTypes.REVOKE_INVITATION_BY_OFFER:
            return { ...state, id: action.payload.id, data: action.payload.data, loading: true };
        case InvitationsActionTypes.REVOKE_INVITATION_BY_LISTING:
            return { ...state, id: action.payload.id, data: action.payload.data, loading: true };
        case InvitationsActionTypes.RESEND_INVITATION:
            return { ...state, id: action.payload.id, data: action.payload.data, loading: true };

        case InvitationsActionTypes.RESET_INVITATION:
            return INIT_STATE;
        default:
            return { ...state };
    }
};

const Invitations = (state = INIT_STATE, action) => {
    switch (action.type) {
        case InvitationsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case InvitationsActionTypes.GET_INVITATIONS:
                    return { ...state, data: action.payload.data, loading: false };
                case InvitationsActionTypes.GET_INVITATIONS_BY_TRANSACTION:
                    return { ...state, data: action.payload.data, loading: false };
                case InvitationsActionTypes.GET_INVITATIONS_BY_LISTING:
                    return { ...state, data: action.payload.data, loading: false };
                case InvitationsActionTypes.GET_INVITATIONS_BY_OFFER:
                    return { ...state, data: action.payload.data, loading: false };
                default:
                    return { ...state };
            }
        case InvitationsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case InvitationsActionTypes.GET_INVITATIONS:
                    return { ...state, error: action.payload, loading: false };
                case InvitationsActionTypes.GET_INVITATIONS_BY_TRANSACTION:
                    return { ...state, error: action.payload, loading: false };
                case InvitationsActionTypes.GET_INVITATIONS_BY_LISTING:
                    return { ...state, error: action.payload, loading: false };
                case InvitationsActionTypes.GET_INVITATIONS_BY_OFFER:
                    return { ...state, error: action.payload, loading: false };
                default:
                    return { ...state };
            }
        // Get Invitations
        case InvitationsActionTypes.GET_INVITATIONS:
            return { ...state, data: null, loading: true };
        case InvitationsActionTypes.GET_INVITATIONS_BY_TRANSACTION:
            return { ...state, data: null, loading: true };
        case InvitationsActionTypes.GET_INVITATIONS_BY_LISTING:
            return { ...state, data: null, loading: true };
        case InvitationsActionTypes.GET_INVITATIONS_BY_OFFER:
            return { ...state, data: null, loading: true };
        case InvitationsActionTypes.RESET_INVITATIONS:
            return INIT_STATE;
        default:
            return { ...state };
    }
};

export { Invitation, Invitations };
