import { AnvilActionTypes } from './constants';

const INIT_STATE = {};

const NewAnvil = (state = INIT_STATE, action) => {
    switch (action.type) {
        case AnvilActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AnvilActionTypes.SIGN_ETCH_PACKET2:
                    return {
                        ...state,
                        [action.payload.formId]: {
                            id: action.payload.id,
                            data: action.payload.data,
                            loading: false,
                            getFormSuccess: true,
                        },
                    };
                default:
                    return { ...state };
            }
        case AnvilActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AnvilActionTypes.SIGN_ETCH_PACKET2:
                    return {
                        ...state,
                        [action.payload.formId]: {
                            error: action.payload,
                            loading: false,
                            getFormSuccess: false,
                            submitSuccess: false,
                        },
                    };
                default:
                    return { ...state };
            }
        case AnvilActionTypes.SIGN_ETCH_PACKET2:
            return {
                ...state,
                [action.payload.formId]: {
                    id: action.payload.id,
                    data: action.payload.data,
                    loading: false,
                },
            };
        case AnvilActionTypes.RESET_ANVIL2:
            return INIT_STATE;
        default:
            return { ...state };
    }
};

export default NewAnvil;
