import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { propertyStyleApiResponseSuccess, propertyStyleApiResponseError } from './actions';
import { PropertyStyleActionTypes } from './constants';
import { toggleLoading } from '../actions';
import {
    createPropertyStyle as createPropertyStyleApi,
    getPropertyStyle as getPropertyStyleApi,
    getPropertyStyles as getPropertyStylesApi,
    updatePropertyStyle as updatePropertyStyleApi,
} from '../../helpers';

/**
 * Get Listings
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.page
 * @param {string} action.payload.limit
 * @param {string} action.payload.sort
 * @param {string} action.payload.order
 * @param {string} action.payload.search
 * @param {string} action.payload.category
 */
function* getPropertyStyles({ payload = {} }) {
    yield put(toggleLoading(true));
    try {
        const res = yield call(getPropertyStylesApi, payload);
        yield put(propertyStyleApiResponseSuccess(PropertyStyleActionTypes.GET_PROPERTY_STYLES, res.data));
    } catch (error) {
        console.log(error);
        yield put(propertyStyleApiResponseError(PropertyStyleActionTypes.GET_PROPERTY_STYLES, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get property by ID
 * @param {*} param0
 */
function* getPropertyStyle({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getPropertyStyleApi, { id });
        yield put(propertyStyleApiResponseSuccess(PropertyStyleActionTypes.GET_PROPERTY_STYLE_BY_ID, response.data));
    } catch (error) {
        console.log(error);
        yield put(propertyStyleApiResponseError(PropertyStyleActionTypes.GET_PROPERTY_STYLE_BY_ID, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get properties by user
 * @param {*} param0
 */
function* createPropertyStyle({ payload: { data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(createPropertyStyleApi, { data });
        yield put(propertyStyleApiResponseSuccess(PropertyStyleActionTypes.CREATE_PROPERTY_STYLE, response.data));
    } catch (error) {
        console.log(error);
        yield put(propertyStyleApiResponseError(PropertyStyleActionTypes.CREATE_PROPERTY_STYLE, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Update a property
 */
function* updatePropertyStyle({ payload: { id, data } }) {
    try {
        const response = yield call(updatePropertyStyleApi, { id, data });
        yield put(propertyStyleApiResponseSuccess(PropertyStyleActionTypes.UPDATE_PROPERTY_STYLE, response.data));
    } catch (error) {
        console.log(error);
        yield put(propertyStyleApiResponseError(PropertyStyleActionTypes.UPDATE_PROPERTY_STYLE, error));
    }
    yield put(toggleLoading(false));
}

export function* watchGetPropertyStyles() {
    yield takeEvery(PropertyStyleActionTypes.GET_PROPERTY_STYLES, getPropertyStyles);
}

export function* watchGetPropertyStyle() {
    yield takeEvery(PropertyStyleActionTypes.GET_PROPERTY_STYLE_BY_ID, getPropertyStyle);
}

export function* watchCreatePropertyStyle() {
    yield takeEvery(PropertyStyleActionTypes.CREATE_PROPERTY_STYLE, createPropertyStyle);
}

export function* watchUpdatePropertyStyle() {
    yield takeEvery(PropertyStyleActionTypes.UPDATE_PROPERTY_STYLE, updatePropertyStyle);
}

function* propertyStyleSaga() {
    yield all([
        fork(watchGetPropertyStyles),
        fork(watchGetPropertyStyle),
        fork(watchCreatePropertyStyle),
        fork(watchUpdatePropertyStyle),
    ]);
}

export default propertyStyleSaga;
