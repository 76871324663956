// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

/**
 * Create checkout session
 * @param {*} data
 * @returns
 */
function createCheckoutSession(data: any): any {
    const baseUrl = `${config.server.api}/v1/billing/checkout`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Create a new Billing
 * @param {*} params
 * @returns
 */
function createBilling(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/billing/${id}`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Update the details of a Billing
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function updateBilling(params: any): any {
    const baseUrl = `${config.server.api}/v1/billing/${params.id}`;
    return api.updatePatch(`${baseUrl}`, params.data);
}

/**
 * Get a single Billing
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getBillingById(params: any): any {
    const baseUrl = `${config.server.api}/v1/billing/${params.id}`;
    return api.get(`${baseUrl}`);
}

/**
 * Get Billing
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getBilling(params: any): any {
    const baseUrl = `${config.server.api}/v1/billing`;
    return api.get(`${baseUrl}`);
}

/**
 * Get billing for a user
 * @param {*} params
 * @returns
 */
function getUserBilling(params: any): any {
    const baseUrl = `${config.server.api}/v1/users/${params.user}/billing`;
    return api.get(`${baseUrl}`);
}

/**
 * Get billing for a listing
 * @param {*} params
 * @returns
 */
function getBillingByListings(params: any): any {
    const baseUrl = `${config.server.api}/v1/listings/${params.id}/billing`;
    return api.get(`${baseUrl}`);
}

/**
 * Get billing for a organization
 * @param {*} params
 * @returns
 */
function getBillingByOrganization(params: any): any {
    const baseUrl = `${config.server.api}/v1/organizations/${params.slug}/billing`;
    return api.get(`${baseUrl}`);
}

export {
    createBilling,
    updateBilling,
    getBilling,
    getBillingById,
    getUserBilling,
    getBillingByListings,
    createCheckoutSession,
    getBillingByOrganization,
};
