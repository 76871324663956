export const AddendumActionTypes = {
    API_RESPONSE_SUCCESS: '@@addendum/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@addendum/API_RESPONSE_ERROR',
    GET_ADDENDUM: '@@addendum/GET_ADDENDUM',
    SUBMIT_ADDENDUM: '@@addendum/SUBMIT_ADDENDUM',
    CONFIRM_ADDENDUM: '@@addendum/CONFIRM_ADDENDUM',
    RETRACT_ADDENDUM: '@@addendum/RETRACT_ADDENDUM',
    SUBMIT_COUNTER_ADDENDUM: '@@addendum/SUBMIT_COUNTER_ADDENDUM',
    RESET_ADDENDUM: '@@addendum/RESET_ADDENDUM',
};
