// @flow
import { UserActionTypes } from './constants';

type UserAction = { type: string, payload: {} | string };

// common success
export const userApiResponseSuccess = (actionType: string, data: any): UserAction => ({
    type: UserActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const userApiResponseError = (actionType: string, error: string): UserAction => ({
    type: UserActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getUsers = (): UserAction => ({
    type: UserActionTypes.GET_USERS,
});

export const getUser = (id: string): UserAction => ({
    type: UserActionTypes.GET_USER_BY_ID,
    payload: { id },
});

export const updateUser = (id: string, data: object): UserAction => ({
    type: UserActionTypes.UPDATE_USER,
    payload: { id, data },
});

export const createUserAttachment = (id: string, files: object): UserAction => ({
    type: UserActionTypes.CREATE_ATTACHMENT,
    payload: { id, files },
});

export const resetUsers = (): UserAction => ({
    type: UserActionTypes.RESET_USERS,
});
