import { OfferActionTypes } from './constants';

type OfferAction = { type: string, payload: {} | string };

// common success
export const offerApiResponseSuccess = (actionType: string, data: any): OfferAction => ({
    type: OfferActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const offerApiResponseError = (actionType: string, error: string): OfferAction => ({
    type: OfferActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const createOffer = (listing: string, data): OfferAction => ({
    type: OfferActionTypes.CREATE_OFFER,
    payload: { listing, data },
});

export const createCounterOffer = (listing: string, data): OfferAction => ({
    type: OfferActionTypes.CREATE_COUNTER_OFFER,
    payload: { listing, data },
});

export const getOffers = (options): OfferAction => ({
    type: OfferActionTypes.GET_OFFERS,
    payload: options,
});

export const getOffer = (id: string): OfferAction => ({
    type: OfferActionTypes.GET_OFFER_BY_ID,
    payload: { id },
});

export const getAccessByOffer = (id: string): OfferAction => ({
    type: OfferActionTypes.GET_OFFER_ACCESS,
    payload: { id },
});

export const revokeAccessByOffer = (id: string, data: object): OfferAction => ({
    type: OfferActionTypes.REVOKE_OFFER_ACCESS,
    payload: { id, data },
});

export const submitOffer = (id: string, data: object): OfferAction => ({
    type: OfferActionTypes.SUBMIT_OFFER,
    payload: { id, data },
});

export const retractOffer = (id: string, data: object): OfferAction => ({
    type: OfferActionTypes.RETRACT_OFFER,
    payload: { id, data },
});

export const acceptOffer = (id: string, data: object): OfferAction => ({
    type: OfferActionTypes.ACCEPT_OFFER,
    payload: { id, data },
});

export const rejectOffer = (id: string, data: object): OfferAction => ({
    type: OfferActionTypes.REJECT_OFFER,
    payload: { id, data },
});

export const submitCounterOffer = (id: string, data: object): OfferAction => ({
    type: OfferActionTypes.COUNTER_OFFER,
    payload: { id, data },
});

export const getOffersByUser = (user: string, { isBuying, isSelling }): OfferAction => ({
    type: OfferActionTypes.GET_OFFERS_BY_USER,
    payload: { user, isBuying, isSelling },
});

export const getOffersByListing = (listing: string): OfferAction => ({
    type: OfferActionTypes.GET_OFFERS_BY_LISTING,
    payload: { listing },
});

export const updateOffer = (id: string, data: object): OfferAction => ({
    type: OfferActionTypes.UPDATE_OFFER,
    payload: { id, data },
});

export const resetOffers = (): OfferAction => ({
    type: OfferActionTypes.RESET_OFFERS,
});
