import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { favoriteApiResponseSuccess, favoriteApiResponseError } from './actions';
import { FavoritesActionTypes } from './constants';
import { toggleLoading } from '../actions';
import {
    createFavorite as createFavoriteApi,
    getFavorite as getFavoriteApi,
    getFavorites as getFavoritesApi,
    updateFavorite as updateFavoriteApi,
    addFavoriteToUser as addFavoriteToUserApi,
    removeFavoriteToUser as removeFavoriteToUserApi,
} from '../../helpers';

/**
 * Get Favorites
 * @param {object} action
 * @param {string} action.payload
 */
function* getFavorites() {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getFavoritesApi);
        yield put(favoriteApiResponseSuccess(FavoritesActionTypes.GET_FAVORITES, response.data));
    } catch (error) {
        console.log(error);
        yield put(favoriteApiResponseError(FavoritesActionTypes.GET_FAVORITES, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Favorites by ID
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 */
function* getFavorite({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getFavoriteApi, { id });
        yield put(favoriteApiResponseSuccess(FavoritesActionTypes.GET_FAVORITE_BY_ID, response.data));
    } catch (error) {
        console.log(error);
        yield put(favoriteApiResponseError(FavoritesActionTypes.GET_FAVORITE_BY_ID, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Update a Favorite
 * @param {object} action
 * @param {string} action.payload
 * @param {object} action.payload.data
 */
function* createFavorite({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(createFavoriteApi, { id, data });
        yield put(favoriteApiResponseSuccess(FavoritesActionTypes.CREATE_FAVORITE, response.data));
    } catch (error) {
        console.log(error);
        yield put(favoriteApiResponseError(FavoritesActionTypes.CREATE_FAVORITE, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Update a Favorite
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* updateFavorite({ payload: { id, data } }) {
    try {
        const response = yield call(updateFavoriteApi, { id, data });
        yield put(favoriteApiResponseSuccess(FavoritesActionTypes.UPDATE_FAVORITE, response.data));
    } catch (error) {
        console.log(error);
        yield put(favoriteApiResponseError(FavoritesActionTypes.UPDATE_FAVORITE, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Send an Favorite by offer
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* addFavoriteToUser({ payload: { id, data } }) {
    try {
        const response = yield call(addFavoriteToUserApi, { id, data });
        yield put(favoriteApiResponseSuccess(FavoritesActionTypes.ADD_FAVORITE_TO_USER, response.data));
    } catch (error) {
        console.log(error);
        yield put(favoriteApiResponseError(FavoritesActionTypes.ADD_FAVORITE_TO_USER, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Send an Favorite by listing
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* removeFavoriteToUser({ payload: { id, data } }) {
    console.log('id, data', id, data);
    try {
        const response = yield call(removeFavoriteToUserApi, { id, data });
        yield put(favoriteApiResponseSuccess(FavoritesActionTypes.REMOVE_FAVORITE_TO_USER, response.data));
    } catch (error) {
        console.log(error);
        yield put(favoriteApiResponseError(FavoritesActionTypes.REMOVE_FAVORITE_TO_USER, error));
    }
    yield put(toggleLoading(false));
}

export function* watchGetFavorites() {
    yield takeEvery(FavoritesActionTypes.GET_FAVORITES, getFavorites);
}

export function* watchGetFavorite() {
    yield takeEvery(FavoritesActionTypes.GET_FAVORITE_BY_ID, getFavorite);
}

export function* watchCreateFavorite() {
    yield takeEvery(FavoritesActionTypes.CREATE_FAVORITE, createFavorite);
}

export function* watchUpdateFavorite() {
    yield takeEvery(FavoritesActionTypes.UPDATE_FAVORITE, updateFavorite);
}

export function* watchAddFavoriteToUser() {
    yield takeEvery(FavoritesActionTypes.ADD_FAVORITE_TO_USER, addFavoriteToUser);
}

export function* watchRemoveFavoriteToUser() {
    yield takeEvery(FavoritesActionTypes.REMOVE_FAVORITE_TO_USER, removeFavoriteToUser);
}

function* favoriteSaga() {
    yield all([
        fork(watchGetFavorites),
        fork(watchGetFavorite),
        fork(watchUpdateFavorite),
        fork(watchCreateFavorite),
        fork(watchAddFavoriteToUser),
        fork(watchRemoveFavoriteToUser),
    ]);
}

export default favoriteSaga;
