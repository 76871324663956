import { CommentsActionTypes } from './constants';

const INIT_STATE = {
    data: null,
    loading: false,
    error: null,
    updateSuccess: null,
    querySuccess: null,
};

const Comments = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CommentsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case CommentsActionTypes.GET_COMMENTS:
                    return { ...state, data: action.payload.data, loading: false, querySuccess: true };
                case CommentsActionTypes.GET_COMMENT_BY_ID:
                    return { ...state, data: action.payload.data, loading: false, querySuccess: true };
                case CommentsActionTypes.GET_COMMENTS_BY_USER:
                    return { ...state, data: action.payload.data, loading: false, querySuccess: true };
                case CommentsActionTypes.GET_COMMENTS_BY_TASK:
                    return { ...state, data: action.payload.data, loading: false, querySuccess: true };
                case CommentsActionTypes.CREATE_COMMENT:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                case CommentsActionTypes.UPDATE_COMMENT:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                case CommentsActionTypes.DELETE_COMMENT:
                    return { ...state, data: action.payload.data, loading: false };
                case CommentsActionTypes.ARCHIVE_COMMENT:
                    return { ...state, data: action.payload.data, loading: false };
                case CommentsActionTypes.CREATE_ATTACHMENT:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                default:
                    return { ...state };
            }
        case CommentsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case CommentsActionTypes.GET_COMMENTS:
                    return { ...state, error: action.payload, loading: false };
                case CommentsActionTypes.GET_COMMENT_BY_ID:
                    return { ...state, error: action.payload, loading: false };
                case CommentsActionTypes.GET_COMMENTS_BY_USER:
                    return { ...state, error: action.payload, loading: false };
                case CommentsActionTypes.GET_COMMENTS_BY_TASK:
                    return { ...state, error: action.payload, loading: false };
                case CommentsActionTypes.CREATE_COMMENT:
                    return {
                        ...state,
                        error: action.payload,
                        loading: false,
                        updateSuccess: false,
                        querySuccess: false,
                    };
                case CommentsActionTypes.UPDATE_COMMENT:
                    return {
                        ...state,
                        error: action.payload,
                        loading: false,
                        updateSuccess: false,
                        querySuccess: false,
                    };
                case CommentsActionTypes.DELETE_COMMENT:
                    return {
                        ...state,
                        error: action.payload,
                        loading: false,
                        updateSuccess: false,
                        querySuccess: false,
                    };
                case CommentsActionTypes.ARCHIVE_COMMENT:
                    return {
                        ...state,
                        error: action.payload,
                        loading: false,
                        updateSuccess: false,
                        querySuccess: false,
                    };
                case CommentsActionTypes.CREATE_ATTACHMENT:
                    return {
                        ...state,
                        error: action.payload,
                        loading: false,
                        updateSuccess: false,
                        querySuccess: false,
                    };
                default:
                    return { ...state };
            }
        // Get Comments
        case CommentsActionTypes.GET_COMMENTS:
            return { ...state, loading: true };
        // Get a single comment
        case CommentsActionTypes.GET_COMMENT_BY_ID:
            return { ...state, data: null, loading: true };
        // Get an offer by ID
        case CommentsActionTypes.GET_COMMENTS_BY_USER:
            return { ...state, data: null, loading: true };
        case CommentsActionTypes.GET_COMMENTS_BY_TASK:
            return { ...state, data: null, loading: true };
        // Update a comment
        case CommentsActionTypes.UPDATE_COMMENT: {
            return { ...state, id: action.payload.id, data: action.payload.data, loading: true };
        }
        case CommentsActionTypes.RESET_COMMENTS:
            return INIT_STATE;
        default:
            return { ...state };
    }
};

export default Comments;
