import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { repairApiResponseSuccess, repairApiResponseError } from './actions';
import { RepairActionTypes } from './constants';
import { toggleLoading } from '../actions';
import {
    confirmRepair as confirmRepairApi,
    submitRepair as submitRepairApi,
    retractRepair as retractRepairApi,
    getRepair as getRepairApi,
    submitCounterRepair as submitCounterRepairApi,
} from '../../helpers';

/**
 * Get Repair by ID
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 */
function* getRepair({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getRepairApi, { id });
        yield put(repairApiResponseSuccess(RepairActionTypes.GET_REPAIR, response.data));
    } catch (error) {
        console.log(error);
        yield put(repairApiResponseError(RepairActionTypes.GET_REPAIR, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Extend a Repair
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* submitRepair({ payload: { id, data } }) {
    try {
        const response = yield call(submitRepairApi, { id, data });
        yield put(repairApiResponseSuccess(RepairActionTypes.SUBMIT_REPAIR, response.data));
    } catch (error) {
        console.log(error);
        yield put(repairApiResponseError(RepairActionTypes.SUBMIT_REPAIR, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Confirm a Repair
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* confirmRepair({ payload: { id, data } }) {
    try {
        const response = yield call(confirmRepairApi, { id, data });
        yield put(repairApiResponseSuccess(RepairActionTypes.CONFIRM_REPAIR, response.data));
    } catch (error) {
        console.log(error);
        yield put(repairApiResponseError(RepairActionTypes.CONFIRM_REPAIR, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Submit a counter Repair
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* submitCounterRepair({ payload: { id, data } }) {
    try {
        const response = yield call(submitCounterRepairApi, { id, data });
        yield put(repairApiResponseSuccess(RepairActionTypes.SUBMIT_COUNTER_REPAIR, response.data));
    } catch (error) {
        console.log(error);
        yield put(repairApiResponseError(RepairActionTypes.SUBMIT_COUNTER_REPAIR, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Retract a Repair
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* retractRepair({ payload: { id, data } }) {
    try {
        const response = yield call(retractRepairApi, { id, data });
        yield put(repairApiResponseSuccess(RepairActionTypes.RETRACT_REPAIR, response.data));
    } catch (error) {
        console.log(error);
        yield put(repairApiResponseError(RepairActionTypes.RETRACT_REPAIR, error));
    }
    yield put(toggleLoading(false));
}

export function* watchGetRepair() {
    yield takeEvery(RepairActionTypes.GET_REPAIR, getRepair);
}
export function* watchSubmitRepair() {
    yield takeEvery(RepairActionTypes.SUBMIT_REPAIR, submitRepair);
}
export function* watchConfirmRepair() {
    yield takeEvery(RepairActionTypes.CONFIRM_REPAIR, confirmRepair);
}
export function* watchSubmitCounterRepair() {
    yield takeEvery(RepairActionTypes.SUBMIT_COUNTER_REPAIR, submitCounterRepair);
}
export function* watchRetractRepair() {
    yield takeEvery(RepairActionTypes.RETRACT_REPAIR, retractRepair);
}

function* repairSaga() {
    yield all([
        fork(watchGetRepair),
        fork(watchSubmitRepair),
        fork(watchConfirmRepair),
        fork(watchSubmitCounterRepair),
        fork(watchRetractRepair),
    ]);
}

export default repairSaga;
