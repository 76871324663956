// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

/**
 * Create Extension Addendum
 * @param {*} transaction - The transaction ID to use
 * @param {*} data - The form data to send
 * @returns
 */
function submitExtension(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/transactions/${id}/extension/submit`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Complete a extension
 * @param {*} transaction - The transaction ID to extend
 * @param {*} data - The form data to send
 * @returns
 */
function confirmExtension(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/extension/${id}/confirm`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Retract a extension
 * @param {*} transaction - The transaction ID to extend
 * @param {*} data - The form data to send
 * @returns
 */
function retractExtension(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/extension/${id}/retract`;
    return api.create(`${baseUrl}`, data);
}

/**
 * get a extension
 * @param {*} transaction - The transaction ID to extend
 * @param {*} data - The form data to send
 * @returns
 */
function getExtension(params: any): any {
    const { id } = params;
    const baseUrl = `${config.server.api}/v1/extension/${id}`;
    return api.get(`${baseUrl}`);
}

export { submitExtension, confirmExtension, retractExtension, getExtension };
