import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { disclosuresApiResponseSuccess, disclosuresApiResponseError } from './actions';
import { DisclosuresActionTypes } from './constants';
import { toggleLoading } from '../actions';
import {
    createDisclosure as createDisclosureApi,
    getDisclosure as getDisclosureApi,
    getDisclosures as getDisclosuresApi,
    getDisclosuresByUser as getDisclosuresByUserApi,
    getDisclosuresByListing as getDisclosuresByListingApi,
    updateDisclosure as updateDisclosureApi,
    deleteDisclosure as deleteDisclosureApi,
    submitDisclosures as submitDisclosuresApi,
    confirmDisclosures as confirmDisclosuresApi,
    rejectDisclosures as rejectDisclosuresApi,
} from '../../helpers';

/**
 * Create a new disclosure
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.listing
 */
function* createDisclosure({ payload: { listing, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(createDisclosureApi, { listing, data });
        const disclosure = response.data;
        yield put(disclosuresApiResponseSuccess(DisclosuresActionTypes.CREATE_DISCLOSURE, disclosure));
    } catch (error) {
        console.log(error);
        yield put(disclosuresApiResponseError(DisclosuresActionTypes.CREATE_DISCLOSURE, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Create a new disclosure
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 */
function* deleteDisclosure({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(deleteDisclosureApi, { id });
        yield put(disclosuresApiResponseSuccess(DisclosuresActionTypes.DELETE_DISCLOSURE, response.data));
    } catch (error) {
        console.log(error);
        yield put(disclosuresApiResponseError(DisclosuresActionTypes.DELETE_DISCLOSURE, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Disclosures
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.listing
 */
function* getDisclosures({ payload: { listing } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getDisclosuresApi, { listing });
        yield put(disclosuresApiResponseSuccess(DisclosuresActionTypes.GET_DISCLOSURES, response.data));
    } catch (error) {
        console.log(error);
        yield put(disclosuresApiResponseError(DisclosuresActionTypes.GET_DISCLOSURES, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get disclosures submitted by a given user
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.user
 */
function* getDisclosuresByUser({ payload: { user } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getDisclosuresByUserApi, { user });
        yield put(disclosuresApiResponseSuccess(DisclosuresActionTypes.GET_DISCLOSURES_BY_USER, response.data));
    } catch (error) {
        console.log(error);
        yield put(disclosuresApiResponseError(DisclosuresActionTypes.GET_DISCLOSURES_BY_USER, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get disclosures submitted for a given listing
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.listing
 */
function* getDisclosuresByListing({ payload: { listing } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getDisclosuresByListingApi, { listing });
        yield put(disclosuresApiResponseSuccess(DisclosuresActionTypes.GET_DISCLOSURES_BY_LISTING, response.data));
    } catch (error) {
        console.log(error);
        yield put(disclosuresApiResponseError(DisclosuresActionTypes.GET_DISCLOSURES_BY_LISTING, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get disclosure by ID
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 */
function* getDisclosure({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getDisclosureApi, { id });
        yield put(disclosuresApiResponseSuccess(DisclosuresActionTypes.GET_DISCLOSURE_BY_ID, response.data));
    } catch (error) {
        console.log(error);
        yield put(disclosuresApiResponseError(DisclosuresActionTypes.GET_DISCLOSURE_BY_ID, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Update a disclosure
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* updateDisclosure({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(updateDisclosureApi, { id, data });
        yield put(disclosuresApiResponseSuccess(DisclosuresActionTypes.UPDATE_DISCLOSURE, response.data));
    } catch (error) {
        console.log(error);
        yield put(disclosuresApiResponseError(DisclosuresActionTypes.UPDATE_DISCLOSURE, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Extend a Disclosures
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* submitDisclosures({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(submitDisclosuresApi, { id, data });
        yield put(disclosuresApiResponseSuccess(DisclosuresActionTypes.SUBMIT_DISCLOSURES, response.data));
    } catch (error) {
        console.log(error);
        yield put(disclosuresApiResponseError(DisclosuresActionTypes.SUBMIT_DISCLOSURES, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Confirm a Disclosures
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* confirmDisclosures({ payload: { id, data } }) {
    try {
        const response = yield call(confirmDisclosuresApi, { id, data });
        yield put(disclosuresApiResponseSuccess(DisclosuresActionTypes.CONFIRM_DISCLOSURES, response.data));
    } catch (error) {
        console.log(error);
        yield put(disclosuresApiResponseError(DisclosuresActionTypes.CONFIRM_DISCLOSURES, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Reject a Disclosures
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* rejectDisclosures({ payload: { id, data } }) {
    try {
        const response = yield call(rejectDisclosuresApi, { id, data });
        yield put(disclosuresApiResponseSuccess(DisclosuresActionTypes.REJECT_DISCLOSURES, response.data));
    } catch (error) {
        console.log(error);
        yield put(disclosuresApiResponseError(DisclosuresActionTypes.REJECT_DISCLOSURES, error));
    }
    yield put(toggleLoading(false));
}

export function* watchCreateDisclosure() {
    yield takeEvery(DisclosuresActionTypes.CREATE_DISCLOSURE, createDisclosure);
}

export function* watchGetDisclosures() {
    yield takeEvery(DisclosuresActionTypes.GET_DISCLOSURES, getDisclosures);
}

export function* watchGetDisclosuresByUser() {
    yield takeEvery(DisclosuresActionTypes.GET_DISCLOSURES_BY_USER, getDisclosuresByUser);
}

export function* watchGetDisclosuresByListing() {
    yield takeEvery(DisclosuresActionTypes.GET_DISCLOSURES_BY_LISTING, getDisclosuresByListing);
}

export function* watchGetDisclosure() {
    yield takeEvery(DisclosuresActionTypes.GET_DISCLOSURE_BY_ID, getDisclosure);
}

export function* watchUpdateDisclosure() {
    yield takeEvery(DisclosuresActionTypes.UPDATE_DISCLOSURE, updateDisclosure);
}

export function* watchDeleteeDisclosure() {
    yield takeEvery(DisclosuresActionTypes.DELETE_DISCLOSURE, deleteDisclosure);
}

export function* watchSubmitDisclosures() {
    yield takeEvery(DisclosuresActionTypes.SUBMIT_DISCLOSURES, submitDisclosures);
}

export function* watchConfirmDisclosures() {
    yield takeEvery(DisclosuresActionTypes.CONFIRM_DISCLOSURES, confirmDisclosures);
}

export function* watchRejectDisclosures() {
    yield takeEvery(DisclosuresActionTypes.REJECT_DISCLOSURES, rejectDisclosures);
}

function* disclosureSaga() {
    yield all([
        fork(watchCreateDisclosure),
        fork(watchGetDisclosures),
        fork(watchGetDisclosuresByUser),
        fork(watchGetDisclosuresByListing),
        fork(watchGetDisclosure),
        fork(watchUpdateDisclosure),
        fork(watchDeleteeDisclosure),
        fork(watchSubmitDisclosures),
        fork(watchConfirmDisclosures),
        fork(watchRejectDisclosures),
    ]);
}

export default disclosureSaga;
