import { AnvilActionTypes } from './constants';

const INIT_STATE = {
    data: null,
    loading: false,
    error: null,
    getFormSuccess: null,
    submitSuccess: null,
};

const Anvil = (state = INIT_STATE, action) => {
    switch (action.type) {
        case AnvilActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AnvilActionTypes.REVIEW_PDF:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                    };
                case AnvilActionTypes.SIGN_ETCH_PACKET:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        getFormSuccess: true,
                    };
                default:
                    return { ...state };
            }
        case AnvilActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AnvilActionTypes.REVIEW_PDF:
                    return {
                        ...state,
                        error: action.payload,
                        loading: false,
                        getFormSuccess: false,
                        submitSuccess: false,
                    };
                case AnvilActionTypes.SIGN_ETCH_PACKET:
                    return {
                        ...state,
                        error: action.payload,
                        loading: false,
                        getFormSuccess: false,
                        submitSuccess: false,
                    };
                default:
                    return { ...state };
            }
        case AnvilActionTypes.REVIEW_PDF:
            return { ...state, id: action.payload.id, data: action.payload.data, loading: false };
        case AnvilActionTypes.SIGN_ETCH_PACKET:
            return { ...state, id: action.payload.id, data: action.payload.data, loading: false };
        case AnvilActionTypes.RESET_ANVIL:
            return INIT_STATE;
        default:
            return { ...state };
    }
};

export default Anvil;
