const config = {
    env: process.env.NODE_ENV,
    port: process.env.PORT,
    server: {
        url: process.env.REACT_APP_PUBLIC_URL,
        api: process.env.REACT_APP_API_URL,
    },
    google: {
        maps: {
            api_key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        },
    },
    stripe: {
        pricing_table_id: process.env.REACT_APP_STRIPE_PRICING_TABLE_ID,
        publishable_key: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
    },
    emailjs: {
        public_key: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
        service: process.env.REACT_APP_EMAILJS_SERVICE,
        template: {
            contactUs: process.env.REACT_APP_EMAILJS_TEMPLATE_CONTACT_US,
            contactSeller: process.env.REACT_APP_EMAILJS_TEMPLATE_CONTACT_SELLER,
            contactSupport: process.env.REACT_APP_EMAILJS_TEMPLATE_CONTACT_SUPPORT,
        },
    },
};

export default config;
