// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

/**
 * Create a new Notification
 * @param {*} params
 * @returns
 */
function createNotification(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/notifications/${id}`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Update the details of a Notification
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function updateNotification(params: any): any {
    const baseUrl = `${config.server.api}/v1/notifications/${params.id}`;
    return api.updatePatch(`${baseUrl}`, params.data);
}

/**
 * dismiss a Notification
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function dismissNotification(params: any): any {
    const baseUrl = `${config.server.api}/v1/notifications/${params.id}/dismiss`;
    return api.updatePatch(`${baseUrl}`);
}

/**
 * acknowledge a Notification
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function acknowledgeNotification(params: any): any {
    const baseUrl = `${config.server.api}/v1/notifications/${params.id}/acknowledge`;
    return api.updatePatch(`${baseUrl}`);
}

/**
 * Get a single Notification
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getNotification(params: any): any {
    const baseUrl = `${config.server.api}/v1/notifications/${params.id}`;
    return api.get(`${baseUrl}`);
}

/**
 * Get Notifications
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getNotifications(params: any): any {
    const baseUrl = `${config.server.api}/v1/notifications`;
    return api.get(`${baseUrl}`);
}

/**
 * Get notifications for a user
 * @param {*} params
 * @returns
 */
function getUserNotifications(params: any): any {
    const baseUrl = `${config.server.api}/v1/users/${params.user}/notifications`;
    return api.get(`${baseUrl}`);
}

/**
 * Get notifications for a transaction
 * @param {*} params
 * @returns
 */
function getNotificationsByTransaction(params: any): any {
    const baseUrl = `${config.server.api}/v1/transactions/${params.id}/notifications`;
    return api.get(`${baseUrl}`);
}

export {
    createNotification,
    updateNotification,
    getNotification,
    getNotifications,
    getUserNotifications,
    dismissNotification,
    acknowledgeNotification,
    getNotificationsByTransaction,
};
