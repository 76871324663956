// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

/**
 * Create a new Organization
 * @param {*} params
 * @returns
 */
function createOrganization(params: any): any {
    const { data } = params;
    const baseUrl = `${config.server.api}/v1/organizations`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Update the details of an Organization
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function updateOrganization(params: any): any {
    const { slug, data } = params;
    const baseUrl = `${config.server.api}/v1/organizations/${slug}`;
    return api.updatePatch(`${baseUrl}`, data);
}

/**
 * Delete an Organization
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function deleteOrganization(params: any): any {
    const baseUrl = `${config.server.api}/v1/organizations/${params.slug}`;
    return api.delete(`${baseUrl}`);
}

/**
 * Get a single Organization
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getOrganization(params: any): any {
    const baseUrl = `${config.server.api}/v1/organizations/${params.slug}`;
    return api.get(`${baseUrl}`);
}

/**
 * Get Organizations
 * @returns
 */
function getOrganizations(): any {
    const baseUrl = `${config.server.api}/v1/organizations`;
    return api.get(`${baseUrl}`);
}

/**
 * Get Organizations by user
 * @returns
 */
function getOrganizationsByUser(params: any): any {
    const baseUrl = `${config.server.api}/v1/users/${params.user}/organizations`;
    return api.get(`${baseUrl}`);
}

/**
 * Get organization members
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getOrganizationMembers(params: any): any {
    const baseUrl = `${config.server.api}/v1/organizations/${params.slug}/members`;
    return api.get(`${baseUrl}`);
}

/**
 * Delete a new member for an Organization
 * @param {*} params
 * @returns
 */
function removeOrganizationMember(params: any): any {
    const { slug, member } = params;
    const baseUrl = `${config.server.api}/v1/organizations/${slug}/members/${member}`;
    return api.delete(`${baseUrl}`);
}

/**
 * Create a new member for an Organization
 * @param {*} params
 * @returns
 */
function addOrganizationMember(params: any): any {
    const { slug, data } = params;
    const baseUrl = `${config.server.api}/v1/organizations/${slug}/members/add`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Update a member for an Organization
 * @param {*} params
 * @returns
 */
function updateOrganizationMember(params: any): any {
    const { slug, member, data } = params;
    const baseUrl = `${config.server.api}/v1/organizations/${slug}/members/${member}`;
    return api.updatePatch(`${baseUrl}`, data);
}

/**
 * Update the settings for an Organization
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function updateOrganizationSettings(params: any): any {
    const { slug, data } = params;
    const baseUrl = `${config.server.api}/v1/organizations/${slug}/settings`;
    return api.updatePatch(`${baseUrl}`, data);
}

/**
 * Get settings for a single Organization
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getOrganizationSettings(params: any): any {
    const baseUrl = `${config.server.api}/v1/organizations/${params.slug}/settings`;
    return api.get(`${baseUrl}`);
}

export {
    createOrganization,
    updateOrganization,
    deleteOrganization,
    getOrganization,
    getOrganizations,
    getOrganizationsByUser,
    getOrganizationMembers,
    removeOrganizationMember,
    addOrganizationMember,
    updateOrganizationMember,
    updateOrganizationSettings,
    getOrganizationSettings,
};
