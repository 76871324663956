import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { extensionApiResponseSuccess, extensionApiResponseError } from './actions';
import { ExtensionActionTypes } from './constants';
import { toggleLoading } from '../actions';
import {
    submitExtension as submitExtensionApi,
    confirmExtension as confirmExtensionApi,
    retractExtension as retractExtensionApi,
    getExtension as getExtensionApi,
} from '../../helpers';

/**
 * Get Extension by ID
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 */
function* getExtension({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getExtensionApi, { id });
        yield put(extensionApiResponseSuccess(ExtensionActionTypes.GET_EXTENSION, response.data));
    } catch (error) {
        console.log(error);
        yield put(extensionApiResponseError(ExtensionActionTypes.GET_EXTENSION, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Extend a Extension
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* submitExtension({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(submitExtensionApi, { id, data });
        yield put(extensionApiResponseSuccess(ExtensionActionTypes.SUBMIT_EXTENSION, response.data));
    } catch (error) {
        console.log(error);
        yield put(extensionApiResponseError(ExtensionActionTypes.SUBMIT_EXTENSION, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Confirm a Extension
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* confirmExtension({ payload: { id, data } }) {
    try {
        const response = yield call(confirmExtensionApi, { id, data });
        yield put(extensionApiResponseSuccess(ExtensionActionTypes.CONFIRM_EXTENSION, response.data));
    } catch (error) {
        console.log(error);
        yield put(extensionApiResponseError(ExtensionActionTypes.CONFIRM_EXTENSION, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Retract a Extension
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* retractExtension({ payload: { id, data } }) {
    try {
        const response = yield call(retractExtensionApi, { id, data });
        yield put(extensionApiResponseSuccess(ExtensionActionTypes.RETRACT_EXTENSION, response.data));
    } catch (error) {
        console.log(error);
        yield put(extensionApiResponseError(ExtensionActionTypes.RETRACT_EXTENSION, error));
    }
    yield put(toggleLoading(false));
}

export function* watchGetExtension() {
    yield takeEvery(ExtensionActionTypes.GET_EXTENSION, getExtension);
}

export function* watchSubmitExtension() {
    yield takeEvery(ExtensionActionTypes.SUBMIT_EXTENSION, submitExtension);
}

export function* watchConfirmExtension() {
    yield takeEvery(ExtensionActionTypes.CONFIRM_EXTENSION, confirmExtension);
}

export function* watchRetractExtension() {
    yield takeEvery(ExtensionActionTypes.RETRACT_EXTENSION, retractExtension);
}

function* extensionSaga() {
    yield all([
        fork(watchGetExtension),
        fork(watchSubmitExtension),
        fork(watchConfirmExtension),
        fork(watchRetractExtension),
    ]);
}

export default extensionSaga;
