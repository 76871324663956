export const ListingActionTypes = {
    API_RESPONSE_SUCCESS: '@@listing/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@listing/API_RESPONSE_ERROR',

    GET_LISTINGS: '@@listings/GET_LISTINGS',
    GET_LISTING_BY_ID: '@@listing/GET_LISTING_BY_ID',
    GET_LISTINGS_BY_USER: '@@listings/GET_LISTINGS_BY_USER',
    GET_LISTING_ACCESS: '@@listing/GET_LISTING_ACCESS',
    REVOKE_LISTING_ACCESS: '@@listing/REVOKE_LISTING_ACCESS',
    CREATE_LISTING: '@@listing/CREATE_LISTING',
    UPDATE_LISTING: '@@listing/UPDATE_LISTING',
    DELETE_LISTING: '@@listing/DELETE_LISTING',
    RESET_LISTINGS: '@@listing/RESET_LISTINGS',
};
