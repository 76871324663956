// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

/**
 * Create Repair Addendum
 * @param {*} transaction - The transaction ID to use
 * @param {*} data - The form data to send
 * @returns
 */
function submitAddendum(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/transactions/${id}/addendum/submit`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Complete a Addendum
 * @param {*} transaction - The transaction ID to extend
 * @param {*} data - The form data to send
 * @returns
 */
function confirmAddendum(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/addendum/${id}/confirm`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Retract a addendum
 * @param {*} transaction - The transaction ID to extend
 * @param {*} data - The form data to send
 * @returns
 */
function retractAddendum(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/addendum/${id}/retract`;
    return api.create(`${baseUrl}`, data);
}

/**
 * get a addendum
 * @param {*} transaction - The transaction ID to extend
 * @param {*} data - The form data to send
 * @returns
 */
function getAddendum(params: any): any {
    const { id } = params;
    const baseUrl = `${config.server.api}/v1/addendum/${id}`;
    return api.get(`${baseUrl}`);
}

/**
 * Create Counter Repair Addendum
 * @param {*} transaction - The transaction ID to use
 * @param {*} data - The form data to send
 * @returns
 */
function submitCounterAddendum(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/transactions/${id}/addendum/counter/submit`;
    return api.create(`${baseUrl}`, data);
}

export { submitAddendum, confirmAddendum, retractAddendum, getAddendum, submitCounterAddendum };
