import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
//import { ReviewDoc } from '../pages/forms/Offer/pages';
//import NewOfferSuccess from '../pages/forms/Offer/sections/success';
import NewDisclosuresSuccess from '../pages/forms/Disclosures/success';

import PrivateRoute from './PrivateRoute';

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/account/Login'));
const Logout = React.lazy(() => import('../pages/account/Logout'));
const Register = React.lazy(() => import('../pages/account/Register'));
const Confirm = React.lazy(() => import('../pages/account/Confirm'));
const VerifyEmail = React.lazy(() => import('../pages/account/VerifyEmail'));
const ForgetPassword = React.lazy(() => import('../pages/account/ForgetPassword'));
const ResetPassword = React.lazy(() => import('../pages/account/ResetPassword'));
const LockScreen = React.lazy(() => import('../pages/account/LockScreen'));
const Profile = React.lazy(() => import('../pages/account/profile'));
const MyListings = React.lazy(() => import('../pages/account/Listings'));
const SavedListings = React.lazy(() => import('../pages/account/SavedListings'));

// Organizations
const ManageOrganization = React.lazy(() => import('../pages/apps/Organizations'));
const OrganizationProfile = React.lazy(() => import('../pages/apps/Organizations/Profile'));
const OrganizationMembers = React.lazy(() => import('../pages/apps/Organizations/Members'));
const OrganizationDocuments = React.lazy(() => import('../pages/apps/Organizations/Documents'));
const OrganizationBilling = React.lazy(() => import('../pages/apps/Organizations/Billing'));
const OrganizationSettings = React.lazy(() => import('../pages/apps/Organizations/Settings'));
const OrganizationForms = React.lazy(() => import('../pages/apps/Organizations/CustomForms'));

// dashboard
const Dashboard = React.lazy(() => import('../pages/dashboard'));
const BuyingDashboard = React.lazy(() => import('../pages/dashboard/Buying'));
const SellingDashboard = React.lazy(() => import('../pages/dashboard/Selling'));

// apps - Zicarta Core
//const Transactions = React.lazy(() => import('../pages/apps/Transactions/'));
const TransactionDetail = React.lazy(() => import('../pages/apps/Transactions/Detail/'));
const ProServices = React.lazy(() => import('../pages/apps/ProServices/'));
const ProServices_Profile = React.lazy(() => import('../pages/apps/ProServices/profile'));
const Learning = React.lazy(() => import('../pages/apps/Learning/'));
const Learning_Articles = React.lazy(() => import('../pages/apps/Learning/Articles'));
const Listings = React.lazy(() =>
    import('../pages/apps/Marketplace').then((module) => ({ default: module.Marketplace }))
);

/**
 * Forms and form pages
 */
// Listings
const Listing = React.lazy(() => import('../pages/apps/Listing'));
const NewListing = React.lazy(() => import('../pages/forms/Listing'));
const EditListing = React.lazy(() => import('../pages/forms/Listing/Edit'));
const EditListing_OwnerInfo = React.lazy(() => import('../pages/forms/Listing/sections/owner-information'));
const EditListing_PropertyAddress = React.lazy(() => import('../pages/forms/Listing/sections/property-address'));
const EditListing_ConstructionFeatures = React.lazy(() =>
    import('../pages/forms/Listing/sections/construction-features')
);
const EditListing_PropertyFeatures = React.lazy(() => import('../pages/forms/Listing/sections/property-features'));
const EditListing_LotFeatures = React.lazy(() => import('../pages/forms/Listing/sections/lot-features'));
const EditListing_PropertyImages = React.lazy(() => import('../pages/forms/Listing/sections/property-images'));
const EditListing_PropertyDescription = React.lazy(() =>
    import('../pages/forms/Listing/sections/property-description')
);
const EditListing_PropertyUpdates = React.lazy(() => import('../pages/forms/Listing/sections/property-updates'));
//const EditListing_PropertyAmenities = React.lazy(() => import('../pages/forms/NewListing/sections/property-amenities'));
const EditListing_PropertyPricing = React.lazy(() => import('../pages/forms/Listing/sections/property-pricing'));
const EditListing_PropertyUtilities = React.lazy(() => import('../pages/forms/Listing/sections/property-utilities'));
const EditListing_TenantRentback = React.lazy(() => import('../pages/forms/Listing/sections/tenant-rentback'));
const EditListing_PropertyRegime = React.lazy(() => import('../pages/forms/Listing/sections/property-regime'));

// Short Listing
const ShortListing = React.lazy(() => import('../pages/forms/AgentOffer/listing'));

// Offer
const Offer = React.lazy(() => import('../pages/forms/Offer'));
const OfferReview = React.lazy(() => import('../pages/forms/Offer/sections/review'));
const OfferSuccess = React.lazy(() => import('../pages/forms/Offer/sections/success'));
const EditOffer = React.lazy(() => import('../pages/forms/Offer/Edit'));
const EditOffer_BuyerInfo = React.lazy(() => import('../pages/forms/Offer/sections/buyer-information'));
const EditOffer_OfferPrice = React.lazy(() => import('../pages/forms/Offer/sections/offer-price'));
const EditOffer_OfferPriceOref = React.lazy(() => import('../pages/forms/Offer/sections/offer-price-oref'));
const EditOffer_FundingFinancing = React.lazy(() => import('../pages/forms/Offer/sections/funding-financing'));
const EditOffer_FundingFinancingOref = React.lazy(() => import('../pages/forms/Offer/sections/funding-financing-oref'));
const EditOffer_PropertyInspections = React.lazy(() => import('../pages/forms/Offer/sections/property-inspections'));
const EditOffer_PropertyInspectionsOref = React.lazy(() =>
    import('../pages/forms/Offer/sections/property-inspections-oref')
);
const EditOffer_TenantRentback = React.lazy(() => import('../pages/forms/Offer/sections/tenant-rentback'));
const EditOffer_FixturesProperty = React.lazy(() => import('../pages/forms/Offer/sections/fixtures-property'));
const EditOffer_Closing = React.lazy(() => import('../pages/forms/Offer/sections/closing'));
const EditOffer_ClosingOref = React.lazy(() => import('../pages/forms/Offer/sections/closing-oref'));

// AgentOffer
const AgentOffer = React.lazy(() => import('../pages/forms/AgentOffer'));

// Disclosures
const Disclosures = React.lazy(() => import('../pages/forms/Disclosures'));
const DisclosuresRevisit = React.lazy(() => import('../pages/forms/Disclosures/revisit'));
// General Disclosures
//const GeneralDisclosures = React.lazy(() => import('../pages/forms/Disclosures'));
const EditDisclosure = React.lazy(() => import('../pages/forms/Disclosures/Edit'));
const EditDisclosure_Exclusion = React.lazy(() => import('../pages/forms/Disclosures/exclusion'));
const EditDisclosure_Title = React.lazy(() => import('../pages/forms/Disclosures/sections/01_title'));
const EditDisclosure_Water = React.lazy(() => import('../pages/forms/Disclosures/sections/02_water'));
const EditDisclosure_Sewer = React.lazy(() => import('../pages/forms/Disclosures/sections/03_sewer'));
const EditDisclosure_DwellingStructure = React.lazy(() =>
    import('../pages/forms/Disclosures/sections/04_dwelling_structure')
);
const EditDisclosure_DwellingSystems = React.lazy(() =>
    import('../pages/forms/Disclosures/sections/05_dwelling_systems')
);
const EditDisclosure_DwellingInsulation = React.lazy(() =>
    import('../pages/forms/Disclosures/sections/06_dwelling_insulation')
);
const EditDisclosure_CommonInterest = React.lazy(() =>
    import('../pages/forms/Disclosures/sections/07_common_interest')
);
const EditDisclosure_Other = React.lazy(() => import('../pages/forms/Disclosures/sections/09_other_disclosures'));
const EditDisclosure_Land = React.lazy(() => import('../pages/forms/Disclosures/sections/08_land'));
const ReviewDisclosures = React.lazy(() => import('../pages/forms/Disclosures/sections/review'));
// Lead Paint disclosures
const EditDisclosure_LeadPaint = React.lazy(() => import('../pages/forms/Disclosures/sections/lead_paint'));
//const EditDisclosure_ReviewLeadPaint = React.lazy(() => import('../pages/forms/Disclosures/sections/review_leadpaint.js'));

// Termination
const Termination = React.lazy(() => import('../pages/forms/Termination'));
const TerminationReview = React.lazy(() => import('../pages/forms/Termination/sections/review'));
const TerminationSuccess = React.lazy(() => import('../pages/forms/Termination/sections/success'));

// Repair Addendum
const Repairs = React.lazy(() => import('../pages/forms/Repairs'));
const RepairReview = React.lazy(() => import('../pages/forms/Repairs/sections/review'));
const RepairsSuccess = React.lazy(() => import('../pages/forms/Repairs/sections/success'));

// Repair Addendum
const Addendums = React.lazy(() => import('../pages/forms/Addendum'));
const AddendumReview = React.lazy(() => import('../pages/forms/Addendum/sections/review'));
const AddendumsSuccess = React.lazy(() => import('../pages/forms/Addendum/sections/success'));

// Seller Rentback Agreement
const Rentback = React.lazy(() => import('../pages/forms/Rentback'));
const RentbackReview = React.lazy(() => import('../pages/forms/Rentback/sections/review'));
const RentbackSuccess = React.lazy(() => import('../pages/forms/Rentback/sections/success'));

// Closing Extension Agreement
const Extension = React.lazy(() => import('../pages/forms/Extension'));
const ExtensionReview = React.lazy(() => import('../pages/forms/Extension/sections/review'));
const ExtensionSuccess = React.lazy(() => import('../pages/forms/Extension/sections/success'));

// Inspection Agreement
const Inspection = React.lazy(() => import('../pages/forms/Inspection'));
const InspectionSuccess = React.lazy(() => import('../pages/forms/Inspection/sections/success'));

// - chat
const ChatApp = React.lazy(() => import('../pages/apps/Chat/'));

// pages
const ErrorPageNotFound = React.lazy(() => import('../pages/error/PageNotFoundAlt'));
const ErrorPageNotFoundAlt = React.lazy(() => import('../pages/error/PageNotFoundAlt'));
const ServerError = React.lazy(() => import('../pages/error/ServerError'));

// Legal
const TermsAndConditions = React.lazy(() => import('../pages/legal/TermsAndConditions'));
const Privacy = React.lazy(() => import('../pages/legal/Privacy'));

// - other
//const Invoice = React.lazy(() => import('../pages/other/Invoice'));
//const FAQ = React.lazy(() => import('../pages/other/FAQ'));
//const Pricing = React.lazy(() => import('../pages/other/Pricing'));
const Maintenance = React.lazy(() => import('../pages/other/Maintenance'));
const About = React.lazy(() => import('../pages/other/About'));
const Support = React.lazy(() => import('../pages/other/Support'));
//const Starter = React.lazy(() => import('../pages/other/Starter'));
//const PreLoader = React.lazy(() => import('../pages/other/PreLoader/'));
//const Timeline = React.lazy(() => import('../pages/other/Timeline'));

const Landing = React.lazy(() => import('../pages/landing/'));
const Offerings = React.lazy(() => import('../pages/other/Offerings/'));

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/home" />,
    route: Route,
    //route: PrivateRoute,
};

const dashboardRoutes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/dashboard/buying',
        name: 'BuyingDashboard',
        component: BuyingDashboard,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/dashboard/selling',
        name: 'SellingDashboard',
        component: SellingDashboard,
        route: PrivateRoute,
        exact: true,
    },
];

/**
 * Partner Select Routes
 */
const proServicesRoutes = [
    {
        path: '/services',
        name: 'Pro Services',
        route: Route,
        icon: 'uil-comments-alt',
        component: ProServices,
        exact: true,
    },
    {
        path: '/services/profile/:slug',
        name: 'Partner Profiles',
        route: Route,
        component: ProServices_Profile,
        exact: true,
    },
];

/**
 * Partner Select Routes
 */
const organizationRoutes = [
    {
        path: '/organizations/:slug',
        name: 'Manage Organization',
        route: Route,
        component: ManageOrganization,
        exact: true,
    },
    {
        path: '/organizations/:slug/profile',
        name: 'Organization Profile',
        route: Route,
        component: OrganizationProfile,
        exact: true,
    },
    {
        path: '/organizations/:slug/members',
        name: 'Organization Members',
        route: Route,
        component: OrganizationMembers,
        exact: true,
    },
    {
        path: '/organizations/:slug/documents',
        name: 'Organization Documents',
        route: Route,
        component: OrganizationDocuments,
        exact: true,
    },
    {
        path: '/organizations/:slug/billing',
        name: 'Organization Billing',
        route: Route,
        component: OrganizationBilling,
        exact: true,
    },
    {
        path: '/organizations/:slug/settings',
        name: 'Organization Settings',
        route: Route,
        component: OrganizationSettings,
        exact: true,
    },
    {
        path: '/organizations/:slug/forms',
        name: 'Custom Forms',
        route: Route,
        component: OrganizationForms,
        exact: true,
    },
];

/**
 * Zicarta Learn Routes
 */
const learningAppRoutes = [
    {
        path: '/learning/articles/:id',
        name: 'Learning Articles',
        component: Learning_Articles,
        route: Route,
        exact: true,
    },
    {
        path: '/learning',
        name: 'Zicarta Learning',
        route: Route,
        icon: 'uil-comments-alt',
        component: Learning,
        exact: true,
    },
];

/**
 * Real Estate Marketplace Routes
 */
const marketplaceAppRoutes = {
    path: '/listings',
    name: 'Listings',
    route: Route,
    //route: PrivateRoute,
    icon: 'uil-home',
    component: Listings,
    exact: true,
};

/**
 * Listing Routes
 */
const listingRoutes = [
    {
        path: '/listings/new',
        name: 'New Listing',
        component: NewListing,
        route: PrivateRoute,
        exact: true,
    },
    {
        // This needs to be last, or it will override any other route with :id
        path: '/listings/:listing',
        name: 'Listing',
        component: Listing,
        route: Route,
        //route: PrivateRoute,
        exact: true,
    },
    {
        path: '/listings/:listing/edit',
        name: 'Edit Listing',
        component: EditListing,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/listings/:listing/edit/owner-information',
        name: 'Edit Listing',
        component: EditListing_OwnerInfo,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/listings/:listing/edit/property-address',
        name: 'Edit Listing',
        component: EditListing_PropertyAddress,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/listings/:listing/edit/property-features',
        name: 'Edit Listing',
        component: EditListing_PropertyFeatures,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/listings/:listing/edit/lot-features',
        name: 'Edit Listing',
        component: EditListing_LotFeatures,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/listings/:listing/edit/property-images',
        name: 'Edit Listing',
        component: EditListing_PropertyImages,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/listings/:listing/edit/property-description',
        name: 'Edit Listing',
        component: EditListing_PropertyDescription,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/listings/:listing/edit/construction-features',
        name: 'Edit Listing',
        component: EditListing_ConstructionFeatures,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/listings/:listing/edit/property-updates',
        name: 'Edit Listing',
        component: EditListing_PropertyUpdates,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/listings/:listing/edit/tenant-rentback',
        name: 'Edit Listing',
        component: EditListing_TenantRentback,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/listings/:listing/edit/property-regime',
        name: 'Edit Listing',
        component: EditListing_PropertyRegime,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/listings/:listing/edit/property-utilities',
        name: 'Edit Listing',
        component: EditListing_PropertyUtilities,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/listings/:listing/edit/price-payment',
        name: 'Edit Listing',
        component: EditListing_PropertyPricing,
        route: PrivateRoute,
        exact: true,
    },
    /*
    {
        path: '/listings/:listing/repairs',
        name: 'Repair Addendum',
        component: Repairs,
        route: PrivateRoute,
        exact: true,
    },
    */
];

/**
 * Short Listing Routes
 */
const shortListingRoutes = [
    {
        path: '/listings/short/:listing',
        name: 'New Listing',
        component: ShortListing,
        route: PrivateRoute,
        exact: true,
    },
];

/**
 * Offer Routes
 */
const offerRoutes = [
    /* Offer routes */
    {
        path: '/offers/:offer/edit',
        name: 'Offer: Manage Offer',
        component: EditOffer,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/offers/:offer/edit/buyer-information',
        name: 'Offer: Buyer Information',
        component: EditOffer_BuyerInfo,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/offers/:offer/edit/offer-price',
        name: 'Offer: Pricing',
        component: EditOffer_OfferPrice,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/offers/:offer/edit/offer-price-oref',
        name: 'Offer: Pricing',
        component: EditOffer_OfferPriceOref,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/offers/:offer/edit/funding-financing',
        name: 'Offer: Funding & Financing',
        component: EditOffer_FundingFinancing,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/offers/:offer/edit/funding-financing-oref',
        name: 'Offer: Funding & Financing',
        component: EditOffer_FundingFinancingOref,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/offers/:offer/edit/property-inspections',
        name: 'Offer: Property Inspections',
        component: EditOffer_PropertyInspections,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/offers/:offer/edit/property-inspections-oref',
        name: 'Offer: Property Inspections',
        component: EditOffer_PropertyInspectionsOref,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/offers/:offer/edit/tenant-rentback',
        name: 'Offer: Tenant Rentback',
        component: EditOffer_TenantRentback,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/offers/:offer/edit/fixtures-property',
        name: 'Offer: Fixtures Exclusion & Private Property',
        component: EditOffer_FixturesProperty,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/offers/:offer/edit/closing',
        name: 'Offer: Closing',
        component: EditOffer_Closing,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/offers/:offer/edit/closing-oref',
        name: 'Offer: Closing',
        component: EditOffer_ClosingOref,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/listings/:listing/offers/new',
        name: 'New Offer',
        component: Offer,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/offers/:offer/review',
        name: 'Offer: Review',
        component: OfferReview,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/offers/:offer/success',
        name: 'Offer sent',
        component: OfferSuccess,
        route: PrivateRoute,
    },
];

/**
 * Offer Routes
 */
const agentOfferRoutes = [
    /* Offer routes */
    {
        path: '/listings/transaction/new',
        name: 'New Offer',
        component: AgentOffer,
        route: PrivateRoute,
        exact: true,
    },
];

/**
 * Disclosure Routes
 */
const disclosureRoutes = [
    {
        path: '/listings/:listing/disclosures',
        name: 'New Disclosures',
        component: Disclosures,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/listings/:listing/disclosures/revisit',
        name: 'Revisit Disclosures',
        component: DisclosuresRevisit,
        route: PrivateRoute,
        exact: true,
    },
    /**
    {
        path: '/listings/:listing/disclosures/general',
        name: 'New Disclosures',
        component: GeneralDisclosures,
        route: PrivateRoute,
        exact: true,
    },
    */
    {
        path: '/disclosures/:disclosure/edit',
        name: 'Disclosure: Manage Disclosure',
        component: EditDisclosure,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/disclosures/:disclosure/edit/exclusion',
        name: 'Disclosures: Property Exclusion',
        component: EditDisclosure_Exclusion,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/disclosures/:disclosure/edit/title',
        name: 'Disclosures: Property Title',
        component: EditDisclosure_Title,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/disclosures/:disclosure/edit/water',
        name: 'Disclosures: Water',
        component: EditDisclosure_Water,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/disclosures/:disclosure/edit/sewer',
        name: 'Disclosures: Sewer',
        component: EditDisclosure_Sewer,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/disclosures/:disclosure/edit/dwelling_structure',
        name: 'Disclosures: Dwelling Structure',
        component: EditDisclosure_DwellingStructure,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/disclosures/:disclosure/edit/dwelling_systems',
        name: 'Disclosures: Dwelling Systems',
        component: EditDisclosure_DwellingSystems,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/disclosures/:disclosure/edit/dwelling_insulation',
        name: 'Disclosures: Dwelling Insulation',
        component: EditDisclosure_DwellingInsulation,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/disclosures/:disclosure/edit/common_interest',
        name: 'Disclosures: Common Interest',
        component: EditDisclosure_CommonInterest,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/disclosures/:disclosure/edit/other_disclosures',
        name: 'Disclosures: Other',
        component: EditDisclosure_Other,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/disclosures/:disclosure/edit/land',
        name: 'Disclosures: Land',
        component: EditDisclosure_Land,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/disclosures/:disclosure/edit/leadpaint',
        name: 'Disclosures: Lead Paint',
        component: EditDisclosure_LeadPaint,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/disclosures/:disclosure/review',
        name: 'Disclosures: Review Document',
        component: ReviewDisclosures,
        route: PrivateRoute,
        exact: true,
    },
    /**
    {
        path: '/disclosures/:disclosure/edit/review_leadpaint',
        name: 'Disclosures: Review Document',
        component: EditDisclosure_ReviewLeadPaint,
        route: PrivateRoute,
        exact: true,
    },
    */
    {
        path: '/disclosures/:disclosure/success',
        name: 'Disclosures Submitted',
        component: NewDisclosuresSuccess,
        route: PrivateRoute,
    },
];

/**
 * Transaction Routes
 */

const transactionAppRoutes = [
    {
        path: '/transactions/:transaction',
        name: 'Transaction Details',
        component: TransactionDetail,
        route: PrivateRoute,
        exact: true,
    },
    /** Termination routes */
    {
        path: '/transactions/:transaction/termination',
        name: 'Termination: Manage Termination Agreement',
        component: Termination,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/termination/:id/review',
        name: 'Termination: Review',
        component: TerminationReview,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/transactions/:transaction/termination/success',
        name: 'Termination Agreement Sent',
        component: TerminationSuccess,
        route: PrivateRoute,
        exact: true,
    },
    /** Extension routes */
    {
        path: '/transactions/:transaction/extension',
        name: 'Closing Extension: Manage Extension Agreement',
        component: Extension,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/extension/:id/review',
        name: 'Closing Extension: Manage Extension Agreement',
        component: ExtensionReview,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/transactions/:transaction/extension/success',
        name: 'Closing Extension Agreement sent',
        component: ExtensionSuccess,
        route: PrivateRoute,
        exact: true,
    },
    /**
     * Inspection Routes
     */
    {
        path: '/transactions/:transaction/inspection',
        name: 'Inspection: Schedule an Inspection',
        component: Inspection,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/transactions/:transaction/inspection/success',
        name: 'Inspection schedule success',
        component: InspectionSuccess,
        route: PrivateRoute,
    },
    /*{
        path: '/transactions',
        name: 'Transactions',
        route: PrivateRoute,
        component: Transactions,
        exact: true,
    },*/
];

/**
 * Repairs Routes
 */
const repairRoutes = [
    {
        path: '/repairs/:id/review',
        name: 'Repair: Review',
        component: RepairReview,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/transactions/:transaction/repairs/success',
        name: 'Repair Addendum sent',
        component: RepairsSuccess,
        route: PrivateRoute,
    },
    {
        path: '/transactions/:transaction/repairs',
        name: 'Repairs: Manage Repair Addendum',
        component: Repairs,
        route: PrivateRoute,
        exact: true,
    },
];

/**
 * Addendum Routes
 */
const addendumRoutes = [
    /* Repairs routes */
    {
        path: '/addendum/:id/review',
        name: 'Addendum: Review',
        component: AddendumReview,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/transactions/:transaction/addendum',
        name: 'Addendums: Manage General Addendum',
        component: Addendums,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/transactions/:transaction/addendum/success',
        name: 'General Addendum sent',
        component: AddendumsSuccess,
        route: PrivateRoute,
    },
];

/**
 * Rentback Routes
 */
const rentbackRoutes = [
    {
        path: '/transactions/:transaction/rentback',
        name: 'Rentback: Manage Termination Agreement',
        component: Rentback,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/rentback/:id/review',
        name: 'Rentback: Review',
        component: RentbackReview,
        route: PrivateRoute,
        exact: true,
    },
    {
        path: '/transactions/:transaction/rentback/success',
        name: 'Rentback Agreement sent',
        component: RentbackSuccess,
        route: PrivateRoute,
    },
];

const chatAppRoutes = {
    path: '/apps/chat',
    name: 'Chat',
    route: PrivateRoute,
    //roles: ['Admin'],
    icon: 'uil-comments-alt',
    component: ChatApp,
};

const appRoutes = [
    chatAppRoutes,
    ...proServicesRoutes,
    ...learningAppRoutes,
    ...organizationRoutes,
    marketplaceAppRoutes,
];

const otherPublicRoutes = [
    {
        path: '/home',
        name: 'home',
        component: Landing,
        route: Route,
    },
    {
        path: '/offerings',
        name: 'Offerings',
        component: Offerings,
        route: Route,
    },
    {
        path: '/maintenance',
        name: 'Maintenance',
        component: Maintenance,
        route: Route,
    },
    {
        path: '/error-404',
        name: 'Error - 404',
        component: ErrorPageNotFoundAlt,
        route: Route,
    },
    {
        path: '/error-500',
        name: 'Error - 500',
        component: ServerError,
        route: Route,
    },
    {
        path: '/terms-and-conditions',
        name: 'Terms and Conditions',
        component: TermsAndConditions,
        route: Route,
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        route: Route,
    },
    {
        path: '/support',
        name: 'Support',
        component: Support,
        route: Route,
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: Privacy,
        route: Route,
    },
];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// auth
const authRoutes = [
    {
        path: '/account/login',
        name: 'Login',
        component: Login,
        route: Route,
    },
    {
        path: '/account/logout',
        name: 'Logout',
        component: Logout,
        route: Route,
    },
    {
        path: '/account/register',
        name: 'Register',
        component: Register,
        route: Route,
    },
    {
        path: '/account/forget-password',
        name: 'Forget Password',
        component: ForgetPassword,
        route: Route,
    },
    {
        path: '/account/reset-password',
        name: 'Reset Password',
        component: ResetPassword,
        route: Route,
    },
    {
        path: '/account/lock-screen',
        name: 'Lock Screen',
        component: LockScreen,
        route: Route,
    },
    {
        path: '/account/confirm',
        name: 'Confirm',
        component: Confirm,
        route: Route,
        exact: true,
    },
    {
        path: '/account/verify-email',
        name: 'Verify Email',
        component: VerifyEmail,
        route: Route,
        exact: true,
    },
];

const accountRoutes = [
    {
        path: '/account/profile',
        name: 'Profile',
        component: Profile,
        route: PrivateRoute,
    },
    {
        path: '/account/listings',
        name: 'My Listings',
        component: MyListings,
        route: PrivateRoute,
    },
    {
        path: '/account/saved-listings',
        name: 'Saved Listings',
        component: SavedListings,
        route: PrivateRoute,
    },
];

// All routes
const authProtectedRoutes = [
    rootRoute,
    ...accountRoutes,
    ...appRoutes,
    ...dashboardRoutes,
    ...listingRoutes,
    ...shortListingRoutes,
    ...offerRoutes,
    ...agentOfferRoutes,
    ...disclosureRoutes,
    ...transactionAppRoutes,
    ...repairRoutes,
    ...addendumRoutes,
    ...rentbackRoutes,
];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export { publicRoutes, authProtectedRoutes, authProtectedFlattenRoutes, publicProtectedFlattenRoutes };
