import { OfferActionTypes } from './constants';

const INIT_STATE = {
    data: null,
    loading: false,
    error: null,
    createSuccess: null,
    updateSuccess: null,
    submitSuccess: null,
    retractSuccess: null,
    rejectSuccess: null,
    acceptSuccess: null,
    counterSuccess: null,
};

const Offer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case OfferActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case OfferActionTypes.GET_OFFERS:
                    return { ...state, data: action.payload.data, loading: false };
                case OfferActionTypes.GET_OFFER_BY_ID:
                    return { ...state, data: action.payload.data, loading: false };
                case OfferActionTypes.GET_OFFERS_BY_USER:
                    return { ...state, data: action.payload.data, loading: false };
                case OfferActionTypes.GET_OFFERS_BY_LISTING:
                    return { ...state, data: action.payload.data, loading: false };
                case OfferActionTypes.CREATE_OFFER:
                    return { ...state, data: action.payload.data, loading: false, createSuccess: true };
                case OfferActionTypes.CREATE_COUNTER_OFFER:
                    return { ...state, counterData: action.payload.data, loading: false, createSuccess: true };
                case OfferActionTypes.UPDATE_OFFER:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                case OfferActionTypes.SUBMIT_OFFER:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        submitSuccess: true,
                    };
                case OfferActionTypes.RETRACT_OFFER:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        retractSuccess: true,
                    };
                case OfferActionTypes.ACCEPT_OFFER:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        acceptSuccess: true,
                    };
                case OfferActionTypes.REJECT_OFFER:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        rejectSuccess: true,
                    };
                case OfferActionTypes.COUNTER_OFFER:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        counterSuccess: true,
                    };
                default:
                    return { ...state };
            }
        case OfferActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case OfferActionTypes.GET_OFFERS:
                    return { ...state, error: action.payload, loading: false };
                case OfferActionTypes.GET_OFFER_BY_ID:
                    return { ...state, error: action.payload, loading: false };
                case OfferActionTypes.GET_OFFERS_BY_USER:
                    return { ...state, error: action.payload, loading: false };
                case OfferActionTypes.GET_OFFERS_BY_LISTING:
                    return { ...state, error: action.payload, loading: false };
                case OfferActionTypes.CREATE_OFFER:
                    return { ...state, error: action.payload, loading: false, createSuccess: false };
                case OfferActionTypes.CREATE_COUNTER_OFFER:
                    return { ...state, error: action.payload, loading: false, createSuccess: false };
                case OfferActionTypes.UPDATE_OFFER:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case OfferActionTypes.SUBMIT_OFFER:
                    return { ...state, error: action.payload, loading: false, submitSuccess: false };
                case OfferActionTypes.RETRACT_OFFER:
                    return { ...state, error: action.payload, loading: false, retractSuccess: false };
                case OfferActionTypes.REJECT_OFFER:
                    return { ...state, error: action.payload, loading: false, rejectSuccess: false };
                case OfferActionTypes.ACCEPT_OFFER:
                    return { ...state, error: action.payload, loading: false, acceptSuccess: false };
                case OfferActionTypes.COUNTER_OFFER:
                    return { ...state, error: action.payload, loading: false, counterSuccess: false };
                default:
                    return { ...state };
            }
        case OfferActionTypes.GET_OFFERS:
            return { ...state, loading: true };
        case OfferActionTypes.GET_OFFER_BY_ID:
            return { ...state, loading: true };
        case OfferActionTypes.GET_OFFERS_BY_USER:
            return { ...state, data: null, loading: true };
        case OfferActionTypes.GET_OFFERS_BY_LISTING:
            return { ...state, data: null, loading: true };
        case OfferActionTypes.CREATE_OFFER:
            return { ...state, data: null, loading: true };
        case OfferActionTypes.CREATE_COUNTER_OFFER:
            return { ...state, counterData: null, loading: true };
        case OfferActionTypes.UPDATE_OFFER:
            return { ...state, data: null, loading: true };
        case OfferActionTypes.SUBMIT_OFFER:
            return { ...state, data: null, loading: true };
        case OfferActionTypes.RETRACT_OFFER:
            return { ...state, data: null, loading: true };
        case OfferActionTypes.REJECT_OFFER:
            return { ...state, data: null, loading: true };
        case OfferActionTypes.ACCEPT_OFFER:
            return { ...state, data: null, loading: true };
        case OfferActionTypes.COUNTER_OFFER:
            return { ...state, data: null, loading: true };
        case OfferActionTypes.RESET_OFFERS:
            return INIT_STATE;
        default:
            return {
                ...state,
                createSuccess: null,
                updateSuccess: null,
                submitSuccess: null,
                retractSuccess: null,
                rejectSuccess: null,
                acceptSuccess: null,
                counterSuccess: null,
            };
    }
};

const OfferAccess = (state = INIT_STATE, action) => {
    switch (action.type) {
        case OfferActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case OfferActionTypes.GET_OFFER_ACCESS:
                    return { ...state, data: action.payload.data, loading: false };
                case OfferActionTypes.REVOKE_OFFER_ACCESS:
                    return { ...state, data: action.payload.data, loading: false };
                default:
                    return { ...state };
            }
        case OfferActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case OfferActionTypes.GET_OFFER_ACCESS:
                    return { ...state, error: action.payload, loading: false };
                case OfferActionTypes.REVOKE_OFFER_ACCESS:
                    return { ...state, error: action.payload, loading: false };
                default:
                    return { ...state };
            }
        case OfferActionTypes.GET_OFFER_ACCESS:
            return { ...state, loading: true };
        case OfferActionTypes.REVOKE_OFFER_ACCESS:
            return { ...state, loading: true };
        default:
            return { ...state };
    }
};

export { Offer, OfferAccess };
