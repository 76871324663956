// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

/**
 * Create Repair addendum
 * @param {*} transaction - The transaction ID to use
 * @param {*} data - The form data to send
 * @returns
 */
function submitRepair(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/transactions/${id}/repairs/submit`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Complete a repair
 * @param {*} transaction - The transaction ID to extend
 * @param {*} data - The form data to send
 * @returns
 */
function confirmRepair(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/repairs/${id}/confirm`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Create counter Repair addendum
 * @param {*} transaction - The transaction ID to use
 * @param {*} data - The form data to send
 * @returns
 */
function submitCounterRepair(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/repairs/${id}/counter/submit`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Retract a repair
 * @param {*} transaction - The transaction ID to extend
 * @param {*} data - The form data to send
 * @returns
 */
function retractRepair(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/repairs/${id}/retract`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Retract a repair
 * @param {*} transaction - The transaction ID to extend
 * @param {*} data - The form data to send
 * @returns
 */
function getRepair(params: any): any {
    const { id } = params;
    const baseUrl = `${config.server.api}/v1/repairs/${id}`;
    return api.get(`${baseUrl}`);
}

export { submitRepair, confirmRepair, retractRepair, getRepair, submitCounterRepair };
