// @flow
import { APICore } from './apiCore';
import config from '../../config';
import { saveAs } from 'file-saver';

const api = new APICore();

/**
 * Create a new file
 * @param {String} parent
 * @param {String} id
 * @param {Object} data
 * @returns
 */
function createFile(params: any): any {
    const { parent, id, data } = params;
    var baseUrl;
    if (parent && id) {
        baseUrl = `${config.server.api}/v1/${parent}/${id}/files`;
    } else {
        baseUrl = `${config.server.api}/v1/files`;
    }
    return api.create(`${baseUrl}`, data);
}

/**
 * Upload a file to the API
 * @param {Array<File>} params
 * @returns
 */
function createWithFile(params: any, handleProgress): any {
    const { parent, id, files, url } = params;
    var baseUrl;
    if (url) {
        baseUrl = `${config.server.api}${url}`;
    } else if (parent && id) {
        baseUrl = `${config.server.api}/v1/${parent}/${id}/files`;
    } else {
        baseUrl = `${config.server.api}/v1/files`;
    }
    return api.createWithFile(`${baseUrl}`, files, handleProgress);
}

/**
 * Update the details of a file
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function updateFile(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/files/${id}`;
    return api.updatePatch(`${baseUrl}`, data);
}

/**
 * Download a file from the API
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function downloadFile(params: any): any {
    const { id, filename } = params;
    const baseUrl = `${config.server.api}/v1/files/${id}`;
    return api.getFile(baseUrl).then((blob) => saveAs(blob.data, filename));
}

/**
 * Get a single file
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getFile(params: any): any {
    const { id } = params;
    const baseUrl = `${config.server.api}/v1/files/${id}`;
    return api.get(`${baseUrl}`);
}

/**
 * Get all files for a listing
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getFiles(params: any): any {
    const { parent, id } = params;
    var baseUrl;
    if (parent && id) {
        baseUrl = `${config.server.api}/v1/${parent}/${id}/files`;
    } else {
        baseUrl = `${config.server.api}/v1/files`;
    }
    return api.get(`${baseUrl}`);
}

/**
 * Get all files submitted by a given user
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getFilesByUser(params: any): any {
    const { user } = params;
    const baseUrl = `${config.server.api}/v1/users/${user}/files`;
    return api.get(`${baseUrl}`);
}

/**
 * Get all files for a listing
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getFilesByListing(params: any): any {
    const { listing } = params;
    const baseUrl = `${config.server.api}/v1/listings/${listing}/files`;
    return api.get(`${baseUrl}`);
}

/**
 * Get all files for a listing
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getFilesByOrganization(params: any): any {
    const { organization } = params;
    const baseUrl = `${config.server.api}/v1/organizations/${organization}/files`;
    return api.get(`${baseUrl}`);
}

/**
 * Get all files for a listing
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getFilesByTask(params: any): any {
    const { task } = params;
    const baseUrl = `${config.server.api}/v1/tasks/${task}/files`;
    return api.get(`${baseUrl}`);
}

/**
 * Get all files for a listing
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getFilesByTransaction(params: any): any {
    const { transaction } = params;
    const baseUrl = `${config.server.api}/v1/transactions/${transaction}/files`;
    return api.get(`${baseUrl}`);
}

export {
    createFile,
    updateFile,
    downloadFile,
    getFile,
    getFiles,
    getFilesByListing,
    getFilesByOrganization,
    getFilesByTask,
    getFilesByTransaction,
    getFilesByUser,
    createWithFile,
};
