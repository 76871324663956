import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { propertyTypeApiResponseSuccess, propertyTypeApiResponseError } from './actions';
import { PropertyTypeActionTypes } from './constants';
import { toggleLoading } from '../actions';
import {
    createPropertyType as createPropertyTypeApi,
    getPropertyType as getPropertyTypeApi,
    getPropertyTypes as getPropertyTypesApi,
    updatePropertyType as updatePropertyTypeApi,
} from '../../helpers';

/**
 * Get Property Types
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.page
 * @param {string} action.payload.limit
 * @param {string} action.payload.sort
 * @param {string} action.payload.order
 * @param {string} action.payload.search
 * @param {string} action.payload.category
 */
function* getPropertyTypes({ payload = {} }) {
    yield put(toggleLoading(true));
    try {
        const res = yield call(getPropertyTypesApi, payload);
        yield put(propertyTypeApiResponseSuccess(PropertyTypeActionTypes.GET_PROPERTY_TYPES, res.data));
    } catch (error) {
        console.log(error);
        yield put(propertyTypeApiResponseError(PropertyTypeActionTypes.GET_PROPERTY_TYPES, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get property by ID
 * @param {*} param0
 */
function* getPropertyType({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getPropertyTypeApi, { id });
        yield put(propertyTypeApiResponseSuccess(PropertyTypeActionTypes.GET_PROPERTY_TYPE_BY_ID, response.data));
    } catch (error) {
        console.log(error);
        yield put(propertyTypeApiResponseError(PropertyTypeActionTypes.GET_PROPERTY_TYPE_BY_ID, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get properties by user
 * @param {*} param0
 */
function* createPropertyType({ payload: { data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(createPropertyTypeApi, { data });
        yield put(propertyTypeApiResponseSuccess(PropertyTypeActionTypes.CREATE_PROPERTY_TYPE, response.data));
    } catch (error) {
        console.log(error);
        yield put(propertyTypeApiResponseError(PropertyTypeActionTypes.CREATE_PROPERTY_TYPE, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Update a property
 */
function* updatePropertyType({ payload: { id, data } }) {
    try {
        const response = yield call(updatePropertyTypeApi, { id, data });
        yield put(propertyTypeApiResponseSuccess(PropertyTypeActionTypes.UPDATE_PROPERTY_TYPE, response.data));
    } catch (error) {
        console.log(error);
        yield put(propertyTypeApiResponseError(PropertyTypeActionTypes.UPDATE_PROPERTY_TYPE, error));
    }
    yield put(toggleLoading(false));
}

export function* watchGetPropertyTypes() {
    yield takeEvery(PropertyTypeActionTypes.GET_PROPERTY_TYPES, getPropertyTypes);
}

export function* watchGetPropertyType() {
    yield takeEvery(PropertyTypeActionTypes.GET_PROPERTY_TYPE_BY_ID, getPropertyType);
}

export function* watchCreatePropertyType() {
    yield takeEvery(PropertyTypeActionTypes.CREATE_PROPERTY_TYPE, createPropertyType);
}

export function* watchUpdatePropertyType() {
    yield takeEvery(PropertyTypeActionTypes.UPDATE_PROPERTY_TYPE, updatePropertyType);
}

function* propertyTypeSaga() {
    yield all([
        fork(watchGetPropertyTypes),
        fork(watchGetPropertyType),
        fork(watchCreatePropertyType),
        fork(watchUpdatePropertyType),
    ]);
}

export default propertyTypeSaga;
