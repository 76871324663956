export const OfferActionTypes = {
    API_RESPONSE_SUCCESS: '@@offer/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@offer/API_RESPONSE_ERROR',

    GET_OFFERS: '@@offer/GET_OFFERS',
    GET_OFFER_BY_ID: '@@offer/GET_OFFER_BY_ID',
    GET_OFFERS_BY_USER: '@@offer/GET_OFFERS_BY_USER',
    GET_OFFERS_BY_LISTING: '@@offer/GET_OFFERS_BY_LISTING',
    GET_OFFER_ACCESS: '@@offer/GET_OFFER_ACCESS',
    REVOKE_OFFER_ACCESS: '@@offer/REVOKE_OFFER_ACCESS',
    CREATE_OFFER: '@@offer/CREATE_OFFER',
    CREATE_COUNTER_OFFER: '@@offer/CREATE_COUNTER_OFFER',
    UPDATE_OFFER: '@@offer/UPDATE_OFFER',
    DELETE_OFFER: '@@offer/DELETE_OFFER',
    ARCHIVE_OFFER: '@@offer/ARCHIVE_OFFER',
    SIGN_OFFER: '@@offer/SIGN_OFFER',
    SUBMIT_OFFER: '@@offer/SUBMIT_OFFER',
    RETRACT_OFFER: '@@offer/RETRACT_OFFER',
    ACCEPT_OFFER: '@@offer/ACCEPT_OFFER',
    REJECT_OFFER: '@@offer/REJECT_OFFER',
    COUNTER_OFFER: '@@offer/COUNTER_OFFER',
    RESET_OFFERS: '@@offer/RESET_OFFERS',
};
