import { RentbackActionTypes } from './constants';

type RentbackAction = {
    type: string,
    payload: {} | string,
};

// common success
export const rentbackApiResponseSuccess = (actionType: string, data: any): RentbackAction => ({
    type: RentbackActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const rentbackApiResponseError = (actionType: string, error: string): RentbackAction => ({
    type: RentbackActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const submitRentback = (id: string, data: object): RentbackAction => ({
    type: RentbackActionTypes.SUBMIT_RENTBACK,
    payload: { id, data },
});

export const confirmRentback = (id: string, data: object): RentbackAction => ({
    type: RentbackActionTypes.CONFIRM_RENTBACK,
    payload: { id, data },
});

export const retractRentback = (id: string, data: object): RentbackAction => ({
    type: RentbackActionTypes.RETRACT_RENTBACK,
    payload: { id, data },
});

export const getRentback = (id: string): RentbackAction => ({
    type: RentbackActionTypes.GET_RENTBACK,
    payload: { id },
});

export const resetRentback = (): RentbackAction => ({
    type: RentbackActionTypes.RESET_RENTBACK,
});
