import { AddressActionTypes } from './constants';

type AddressAction = { type: string, payload: {} | string };

// common success
export const addressApiResponseSuccess = (actionType: string, data: any): AddressAction => ({
    type: AddressActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const addressApiResponseError = (actionType: string, error: string): AddressAction => ({
    type: AddressActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const createAddress = (data: object): AddressAction => ({
    type: AddressActionTypes.CREATE_ADDRESS,
    payload: { data },
});

export const updateAddress = (id: string, data: object): AddressAction => ({
    type: AddressActionTypes.UPDATE_ADDRESS,
    payload: { id, data },
});

export const deleteeAddress = (id: string): AddressAction => ({
    type: AddressActionTypes.DELETE_ADDRESS,
    payload: { id },
});

export const getAddresses = (options): AddressAction => ({
    type: AddressActionTypes.GET_ADDRESSES,
    payload: options,
});

export const getAddress = (id: string): AddressAction => ({
    type: AddressActionTypes.GET_ADDRESS_BY_ID,
    payload: { id },
});

export const resetAddress = (): AddressAction => ({
    type: AddressActionTypes.RESET_ADDRESS,
});
