import { FavoritesActionTypes } from './constants';

const INIT_STATE = {
    data: null,
    loading: false,
    error: null,
    updateSuccess: null,
    createSuccess: null,
};

const Favorite = (state = INIT_STATE, action) => {
    switch (action.type) {
        case FavoritesActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case FavoritesActionTypes.GET_FAVORITE_BY_ID:
                    return { ...state, data: action.payload.data, loading: false };
                case FavoritesActionTypes.CREATE_FAVORITE:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        createSuccess: true,
                    };
                case FavoritesActionTypes.ADD_FAVORITE_TO_USER:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        createSuccess: true,
                    };
                case FavoritesActionTypes.REMOVE_FAVORITE_TO_USER:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                case FavoritesActionTypes.UPDATE_FAVORITE:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };

                case FavoritesActionTypes.DELETE_FAVORITE:
                    return { ...state, data: action.payload.data, loading: false };
                default:
                    return { ...state };
            }
        case FavoritesActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case FavoritesActionTypes.GET_FAVORITE_BY_ID:
                    return { ...state, error: action.payload, loading: false };
                case FavoritesActionTypes.CREATE_FAVORITE:
                    return { ...state, error: action.payload, loading: false, createSuccess: false };
                case FavoritesActionTypes.ADD_FAVORITE_TO_USER:
                    return { ...state, error: action.payload, loading: false, createSuccess: false };
                case FavoritesActionTypes.REMOVE_FAVORITE_TO_USER:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case FavoritesActionTypes.UPDATE_FAVORITE:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case FavoritesActionTypes.DELETE_FAVORITE:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                default:
                    return { ...state };
            }
        // Get a single favorite
        case FavoritesActionTypes.GET_FAVORITE_BY_ID:
            return { ...state, data: null, loading: true };
        // Update a favorite
        case FavoritesActionTypes.UPDATE_FAVORITE:
            return { ...state, id: action.payload.id, data: action.payload.data, loading: true };
        case FavoritesActionTypes.ADD_FAVORITE_TO_USER:
            return {
                ...state,
                id: action.payload.id,
                data: action.payload.data,
                loading: true,
                updateSuccess: false,
                createSuccess: false,
            };
        case FavoritesActionTypes.REMOVE_FAVORITE_TO_USER:
            return {
                ...state,
                id: action.payload.id,
                data: action.payload.data,
                loading: true,
                updateSuccess: false,
                createSuccess: false,
            };

        case FavoritesActionTypes.RESET_FAVORITE:
            return INIT_STATE;
        default:
            return { ...state };
    }
};

const Favorites = (state = INIT_STATE, action) => {
    switch (action.type) {
        case FavoritesActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case FavoritesActionTypes.GET_FAVORITES:
                    return { ...state, data: action.payload.data, loading: false };
                default:
                    return { ...state };
            }
        case FavoritesActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case FavoritesActionTypes.GET_FAVORITES:
                    return { ...state, error: action.payload, loading: false };
                default:
                    return { ...state };
            }
        // Get Favorites
        case FavoritesActionTypes.GET_FAVORITES:
            return { ...state, data: null, loading: true };
        case FavoritesActionTypes.RESET_FAVORITES:
            return INIT_STATE;
        default:
            return { ...state };
    }
};

export { Favorite, Favorites };
