import { CommentsActionTypes } from './constants';

type CommentsAction = { type: string, payload: {} | string };

// common success
export const commentApiResponseSuccess = (actionType: string, data: any): CommentsAction => ({
    type: CommentsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const commentApiResponseError = (actionType: string, error: string): CommentsAction => ({
    type: CommentsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getComments = (parent: String, id: string): CommentsAction => ({
    type: CommentsActionTypes.GET_COMMENTS,
    payload: { parent, id },
});

export const getCommentById = (id: string): CommentsAction => ({
    type: CommentsActionTypes.GET_COMMENT_BY_ID,
    payload: { id },
});

export const getCommentsByUser = (user: string): CommentsAction => ({
    type: CommentsActionTypes.GET_COMMENTS_BY_USER,
    payload: { user },
});

export const getCommentsByTask = (task: string): CommentsAction => ({
    type: CommentsActionTypes.GET_COMMENTS_BY_TASK,
    payload: { task },
});

export const createComment = (parent: string, id: string, data: object): CommentsAction => ({
    type: CommentsActionTypes.CREATE_COMMENT,
    payload: { parent, id, data },
});

export const updateComment = (id: string, data: object): CommentsAction => ({
    type: CommentsActionTypes.UPDATE_COMMENT,
    payload: { id, data },
});

export const deleteComment = (id: string): CommentsAction => ({
    type: CommentsActionTypes.DELETE_COMMENT,
    payload: { id },
});

export const archiveComment = (id: string): CommentsAction => ({
    type: CommentsActionTypes.ARCHIVE_COMMENT,
    payload: { id },
});

export const createCommentAttachment = (id: string, data: object): CommentsAction => ({
    type: CommentsActionTypes.CREATE_ATTACHMENT,
    payload: { id, data },
});

export const resetComments = (): CommentsAction => ({
    type: CommentsActionTypes.RESET_COMMENTS,
});
