// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

// account
function login(params: any): any {
    const baseUrl = `${config.server.api}/v1/auth/login`;
    return api.create(`${baseUrl}`, params);
}

function logout(): any {
    const baseUrl = `${config.server.api}/v1/auth/logout`;
    const user = api.getLoggedInUser();
    return api.create(`${baseUrl}`, { refreshToken: user ? user.tokens.refresh.token : '' });
}

function refreshTokens(): any {
    const baseUrl = `${config.server.api}/v1/auth/refresh-tokens`;
    const user = api.getLoggedInUser();
    return api.create(`${baseUrl}`, { refreshToken: user ? user.tokens.refresh.token : '' });
}

function signup(params: any): any {
    const baseUrl = `${config.server.api}/v1/auth/register${
        params.invitation ? `?invitation=${params.invitation}` : ''
    }`;
    return api.create(`${baseUrl}`, params.data);
}

function forgotPassword(params: any): any {
    const baseUrl = `${config.server.api}/v1/auth/forgot-password`;
    return api.create(`${baseUrl}`, params);
}

function resetPassword(params: any): any {
    const baseUrl = `${config.server.api}/v1/auth/reset-password?token=${params.token}`;
    return api.create(`${baseUrl}`, { password: params.password });
}

function changePassword(params: any): any {
    const user = api.getLoggedInUser();
    const baseUrl = `${config.server.api}/v1/users/${user?.user?.id}/change-password`;

    return api.updatePatch(`${baseUrl}`, params);
}

function sendVerificationEmail(): any {
    const baseUrl = `${config.server.api}/v1/auth/send-verification-email`;
    return api.create(`${baseUrl}`);
}

export { login, logout, signup, forgotPassword, resetPassword, changePassword, refreshTokens, sendVerificationEmail };
