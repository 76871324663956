export const RepairActionTypes = {
    API_RESPONSE_SUCCESS: '@@repairs/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@repairs/API_RESPONSE_ERROR',
    GET_REPAIR: '@@repairs/GET_REPAIR',
    SUBMIT_REPAIR: '@@repairs/SUBMIT_REPAIR',
    CONFIRM_REPAIR: '@@repairs/CONFIRM_REPAIR',
    SUBMIT_COUNTER_REPAIR: '@@repairs/SUBMIT_COUNTER_REPAIR',
    CONFIRM_COUNTER_REPAIR: '@@repairs/CONFIRM_COUNTER_REPAIR',
    RETRACT_REPAIR: '@@repairs/RETRACT_REPAIR',
    RESET_REPAIRS: '@@repairs/RESET_REPAIRS',
};
