// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

/**
 * Create a new Timeline
 * @param {*} params
 * @returns
 */
function createTimeline(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/transactions/${id}/timelines`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Update the details of a Timeline
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function updateTimeline(params: any): any {
    const baseUrl = `${config.server.api}/v1/timelines/${params.id}`;
    return api.updatePatch(`${baseUrl}`, params.data);
}

/**
 * Get a single task
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getTimeline(params: any): any {
    const baseUrl = `${config.server.api}/v1/timelines/${params.id}`;
    return api.get(`${baseUrl}`);
}

/**
 * Get tasks
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getTimelines(params: any): any {
    const baseUrl = `${config.server.api}/v1/timelines`;
    return api.get(`${baseUrl}`);
}

/**
 * Get tasks for a user
 * @param {*} params
 * @returns
 */
function getUserTimelines(params: any): any {
    const baseUrl = `${config.server.api}/v1/users/${params.user}/timelines`;
    return api.get(`${baseUrl}`);
}

/**
 * Get tasks for a transaction
 * @param {*} params
 * @returns
 */
function getTimelinesByTransaction(params: any): any {
    const baseUrl = `${config.server.api}/v1/transactions/${params.id}/timelines`;
    return api.get(`${baseUrl}`);
}

export { createTimeline, updateTimeline, getTimeline, getTimelines, getUserTimelines, getTimelinesByTransaction };
