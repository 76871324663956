import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { addressApiResponseSuccess, addressApiResponseError } from './actions';
import { AddressActionTypes } from './constants';
import { toggleLoading } from '../actions';
import {
    createAddress as createAddressApi,
    getAddresses as getAddressesApi,
    getAddress as getAddressApi,
    updateAddress as updateAddressApi,
    deleteAddress as deleteAddressApi,
} from '../../helpers';

/**
 * Create a new Address
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.data
 */
function* createAddress({ payload: { data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(createAddressApi, { data });
        yield put(addressApiResponseSuccess(AddressActionTypes.CREATE_ADDRESS, response.data));
    } catch (error) {
        console.log(error);
        yield put(addressApiResponseError(AddressActionTypes.CREATE_ADDRESS, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get addresses
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.parent
 * @param {string} action.payload.id
 */
function* getAddresses({ payload: { parent, id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getAddressesApi, { parent, id });
        yield put(addressApiResponseSuccess(AddressActionTypes.GET_ADDRESSES, response.data));
    } catch (error) {
        console.log(error);
        yield put(addressApiResponseError(AddressActionTypes.GET_ADDRESSES, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get an address by ID
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 */
function* getAddress({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getAddressApi, { id });
        yield put(addressApiResponseSuccess(AddressActionTypes.GET_ADDRESS_BY_ID, response.data));
    } catch (error) {
        console.log(error);
        yield put(addressApiResponseError(AddressActionTypes.GET_ADDRESS_BY_ID, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Update an address
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* updateAddress({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(updateAddressApi, { id, data });
        yield put(addressApiResponseSuccess(AddressActionTypes.UPDATE_ADDRESS, response.data));
    } catch (error) {
        console.log(error);
        yield put(addressApiResponseError(AddressActionTypes.UPDATE_ADDRESS, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get an address by ID
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 */
function* deleteAddress({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(deleteAddressApi, { id });
        yield put(addressApiResponseSuccess(AddressActionTypes.DELETE_ADDRESS, response.data));
    } catch (error) {
        console.log(error);
        yield put(addressApiResponseError(AddressActionTypes.DELETE_ADDRESS, error));
    }
    yield put(toggleLoading(false));
}

export function* watchCreateAddress() {
    yield takeEvery(AddressActionTypes.CREATE_ADDRESS, createAddress);
}

export function* watchGetAddresses() {
    yield takeEvery(AddressActionTypes.GET_ADDRESSES, getAddresses);
}

export function* watchGetAddress() {
    yield takeEvery(AddressActionTypes.GET_ADDRESS_BY_ID, getAddress);
}

export function* watchUpdateAddress() {
    yield takeEvery(AddressActionTypes.UPDATE_ADDRESS, updateAddress);
}

export function* watchDeleteAddress() {
    yield takeEvery(AddressActionTypes.DELETE_ADDRESS, deleteAddress);
}

function* addressSaga() {
    yield all([
        fork(watchCreateAddress),
        fork(watchGetAddresses),
        fork(watchGetAddress),
        fork(watchUpdateAddress),
        fork(watchDeleteAddress),
    ]);
}

export default addressSaga;
