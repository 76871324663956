export const PropertyTypeActionTypes = {
    API_RESPONSE_SUCCESS: '@@propertytype/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@propertytype/API_RESPONSE_ERROR',

    GET_PROPERTY_TYPES: '@@propertytype/GET_PROPERTY_TYPES',
    GET_PROPERTY_TYPE_BY_ID: '@@propertytype/GET_PROPERTY_TYPE_BY_ID',
    CREATE_PROPERTY_TYPE: '@@propertytype/CREATE_PROPERTY_TYPE',
    UPDATE_PROPERTY_TYPE: '@@propertytype/UPDATE_PROPERTY_TYPE',
    DELETE_PROPERTY_TYPE: '@@propertytype/DELETE_PROPERTY_TYPE',
};
