export const UserActionTypes = {
    API_RESPONSE_SUCCESS: '@@user/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@user/API_RESPONSE_ERROR',

    GET_USERS: '@@user/GET_USERS',
    GET_USER_BY_ID: '@@user/GET_USER_BY_ID',
    UPDATE_USER: '@@user/UPDATE_USER',
    CREATE_ATTACHMENT: '@@user/CREATE_ATTACHMENT',
    RESET_USERS: '@@user/RESET_USERS',
};
