// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import {
    login as loginApi,
    logout as logoutApi,
    refreshTokens as refreshTokensApi,
    signup as signupApi,
    forgotPassword as forgotPasswordApi,
    resetPassword as resetPasswordApi,
    changePassword as changePasswordApi,
    sendVerificationEmail as sendVerificationEmailApi,
} from '../../helpers/';

import { APICore, setAuthorization } from '../../helpers/api/apiCore';
import { authApiResponseSuccess, authApiResponseError } from './actions';
import { AuthActionTypes } from './constants';

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - email and password
 */
function* login({ payload: { email, password } }) {
    try {
        const response = yield call(loginApi, { email, password });
        const user = response.data;
        api.setLoggedInUser(user);
        setAuthorization(user.tokens.access['token']);
        yield all([put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user))]);
    } catch (error) {
        console.log(error);
        yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

/**
 * Logout the user
 */
function* logout() {
    try {
        yield call(logoutApi);
        api.setLoggedInUser(null);
        setAuthorization(null);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
    } catch (error) {
        api.setLoggedInUser(null);
        setAuthorization(null);
        yield all([
            put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {})),
            put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error)),
        ]);
    }
}

function* refreshTokens() {
    try {
        const response = yield call(refreshTokensApi);
        const tokens = response.data;
        const user = api.getLoggedInUser();
        api.setLoggedInUser({
            user: user.user,
            tokens,
        });
        setAuthorization(tokens.access['token']);
        yield put(authApiResponseSuccess(AuthActionTypes.REFRESH_TOKENS, user));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.REFRESH_TOKENS, error));
    }
}

function* signup({ payload: { invitation, data } }) {
    try {
        const response = yield call(signupApi, { invitation, data });
        const user = response.data;
        yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

function* forgotPassword({ payload: { email } }) {
    try {
        const response = yield call(forgotPasswordApi, { email });
        yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
    }
}

function* resetPassword({ payload: { password, token } }) {
    try {
        const response = yield call(resetPasswordApi, { password, token });
        yield put(authApiResponseSuccess(AuthActionTypes.RESET_PASSWORD, response.data));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.RESET_PASSWORD, error));
    }
}

function* changePassword({ payload: { old_password, new_password } }) {
    try {
        const response = yield call(changePasswordApi, { old_password, new_password });
        yield put(authApiResponseSuccess(AuthActionTypes.CHANGE_PASSWORD, response.data));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.CHANGE_PASSWORD, error));
    }
}

/**
 * send verification email
 */
function* sendVerificationEmail({ payload: { token } }) {
    try {
        setAuthorization(token);
        yield call(sendVerificationEmailApi);
        yield put(authApiResponseSuccess(AuthActionTypes.SEND_VERIFICATION_EMAIL, {}));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.SEND_VERIFICATION_EMAIL, error));
    }
}

export function* watchLoginUser(): any {
    yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout(): any {
    yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchRefreshTokens(): any {
    yield takeEvery(AuthActionTypes.REFRESH_TOKENS, refreshTokens);
}

export function* watchSignup(): any {
    yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword(): any {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* watchResetPassword(): any {
    yield takeEvery(AuthActionTypes.RESET_PASSWORD, resetPassword);
}

export function* watchChangePassword(): any {
    yield takeEvery(AuthActionTypes.CHANGE_PASSWORD, changePassword);
}
export function* watchSendVerificationEmail(): any {
    yield takeEvery(AuthActionTypes.SEND_VERIFICATION_EMAIL, sendVerificationEmail);
}

function* authSaga(): any {
    yield all([
        fork(watchLoginUser),
        fork(watchLogout),
        fork(watchRefreshTokens),
        fork(watchSignup),
        fork(watchForgotPassword),
        fork(watchResetPassword),
        fork(watchChangePassword),
        fork(watchSendVerificationEmail),
    ]);
}

export default authSaga;
