export const TasksActionTypes = {
    API_RESPONSE_SUCCESS: '@@tasks/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@tasks/API_RESPONSE_ERROR',
    // Tasks
    GET_TASKS: '@@tasks/GET_TASKS',
    GET_TASK_BY_ID: '@@tasks/GET_TASK_BY_ID',
    GET_TASKS_BY_USER: '@@tasks/GET_TASKS_BY_USER',
    GET_TASKS_BY_TRANSACTION: '@@tasks/GET_TASKS_BY_TRANSACTION',
    CREATE_TASK: '@@tasks/CREATE_TASK',
    UPDATE_TASK: '@@tasks/UPDATE_TASK',
    DELETE_TASK: '@@tasks/DELETE_TASK',
    ARCHIVE_TASK: '@@tasks/ARCHIVE_TASK',
    CREATE_COMMENT: '@@tasks/CREATE_COMMENT',
    CREATE_ATTACHMENT: '@@tasks/CREATE_ATTACHMENT',
    RESET_TASKS: '@@tasks/RESET_TASKS',
    RESET_TASK: '@@tasks/RESET_TASK',
};
