import { AddressActionTypes } from './constants';

const INIT_STATE = {
    data: null,
    loading: false,
    error: null,
    updateSuccess: null,
    createSuccess: null,
    deleteSuccess: null,
};

const Address = (state = INIT_STATE, action) => {
    switch (action.type) {
        case AddressActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AddressActionTypes.GET_ADDRESSES:
                    return { ...state, data: action.payload.data, loading: false };
                case AddressActionTypes.GET_ADDRESS_BY_ID:
                    return { ...state, data: action.payload.data, loading: false };
                case AddressActionTypes.CREATE_ADDRESS:
                    return {
                        ...state,
                        parent: action.payload.parent,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        createSuccess: true,
                    };
                case AddressActionTypes.UPDATE_ADDRESS:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                case AddressActionTypes.DELETE_ADDRESS:
                    return {
                        ...state,
                        id: action.payload.id,
                        loading: false,
                        deleteSuccess: true,
                    };
                default:
                    return { ...state };
            }
        case AddressActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AddressActionTypes.GET_ADDRESSES:
                    return { ...state, error: action.payload, loading: false };
                case AddressActionTypes.GET_ADDRESS_BY_ID:
                    return { ...state, error: action.payload, loading: false };
                case AddressActionTypes.CREATE_ADDRESS:
                    return { ...state, error: action.payload, loading: false, createSuccess: false };
                case AddressActionTypes.UPDATE_ADDRESS:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case AddressActionTypes.DELETE_ADDRESS:
                    return { ...state, error: action.payload, loading: false, deleteSuccess: false };
                default:
                    return { ...state };
            }
        case AddressActionTypes.GET_ADDRESSES:
            return { ...state, loading: true };
        case AddressActionTypes.GET_ADDRESS_BY_ID:
            return { ...state, data: null, loading: true };
        case AddressActionTypes.CREATE_ADDRESS:
            return { ...state, data: null, loading: true };
        case AddressActionTypes.UPDATE_ADDRESS:
            return { ...state, data: null, loading: true };
        case AddressActionTypes.DELETE_ADDRESS:
            return { ...state, data: null, loading: true };
        case AddressActionTypes.RESET_ADDRESS:
            return INIT_STATE;
        default:
            return { ...state, updateSuccess: null, createSuccess: null, deleteSuccess: null };
    }
};

export default Address;
