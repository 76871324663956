export const InvitationsActionTypes = {
    API_RESPONSE_SUCCESS: '@@invitations/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@invitations/API_RESPONSE_ERROR',
    // INVITATIONs
    GET_INVITATIONS: '@@invitations/GET_INVITATIONS',
    GET_INVITATION_BY_ID: '@@invitations/GET_INVITATION_BY_ID',
    GET_INVITATIONS_BY_OFFER: '@@invitations/GET_INVITATIONS_BY_OFFER',
    GET_INVITATIONS_BY_TRANSACTION: '@@invitations/GET_INVITATIONS_BY_TRANSACTION',
    GET_INVITATIONS_BY_LISTING: '@@invitations/GET_INVITATIONS_BY_LISTING',
    CREATE_INVITATION: '@@invitations/CREATE_INVITATION',
    SEND_INVITATION_BY_OFFER: '@@invitations/SEND_INVITATION_BY_OFFER',
    SEND_INVITATION_BY_LISTING: '@@invitations/SEND_INVITATION_BY_LISTING',
    REVOKE_INVITATION_BY_OFFER: '@@invitations/REVOKE_INVITATION_BY_OFFER',
    REVOKE_INVITATION_BY_LISTING: '@@invitations/REVOKE_INVITATION_BY_LISTING',
    UPDATE_INVITATION: '@@invitations/UPDATE_INVITATION',
    DELETE_INVITATION: '@@invitations/DELETE_INVITATION',
    CANCEL_INVITATION: '@@invitations/CANCEL_INVITATION',
    ACCEPT_INVITATION: '@@invitations/ACCEPT_INVITATION',
    RESEND_INVITATION: '@@invitations/RESEND_INVITATION',
    RESET_INVITATIONS: '@@invitations/RESET_INVITATIONS',
    RESET_INVITATION: '@@invitations/RESET_INVITATION',
};
