// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

/**
 * Create a new Favorite
 * @param {*} params
 * @returns
 */
function createFavorite(params: any): any {
    const { data } = params;
    const baseUrl = `${config.server.api}/v1/favorites`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Update the details of a Favorite
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function updateFavorite(params: any): any {
    const baseUrl = `${config.server.api}/v1/favorites/${params.id}`;
    return api.updatePatch(`${baseUrl}`, params.data);
}

/**
 * Get a single favorite
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getFavorite(params: any): any {
    const baseUrl = `${config.server.api}/v1/favorites/${params.id}`;
    return api.get(`${baseUrl}`);
}

/**
 * Get favorites
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getFavorites(): any {
    const baseUrl = `${config.server.api}/v1/favorites`;
    return api.get(`${baseUrl}`);
}

/**
 * Add favorite to User
 * @param {*} params
 * @returns
 */
function addFavoriteToUser(params: any): any {
    const { data, id } = params;
    const baseUrl = `${config.server.api}/v1/users/${id}/favorites/add`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Add favorite to User
 * @param {*} params
 * @returns
 */
function removeFavoriteToUser(params: any): any {
    const { data, id } = params;
    const baseUrl = `${config.server.api}/v1/users/${id}/favorites/remove`;
    return api.create(`${baseUrl}`, data);
}
export { createFavorite, updateFavorite, getFavorite, getFavorites, addFavoriteToUser, removeFavoriteToUser };
