import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { messageApiResponseSuccess, messageApiResponseError } from './actions';
import { MessagesActionTypes } from './constants';
import { toggleLoading } from '../actions';
import {
    createMessage as createMessageApi,
    createMessageAttachment as createMessageAttachmentApi,
    getMessage as getMessageApi,
    getMessages as getMessagesApi,
    getMessagesByUser as getMessagesByUserApi,
    getMessagesByTransaction as getMessagesByTransactionApi,
    updateMessage as updateMessageApi,
} from '../../helpers';

/**
 * Get Messages
 * @param {object} action
 * @param {string} action.payload
 */
function* getMessages({ payload: {} }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getMessagesApi);
        yield put(messageApiResponseSuccess(MessagesActionTypes.GET_MESSAGES, response.data));
    } catch (error) {
        console.log(error);
        yield put(messageApiResponseError(MessagesActionTypes.GET_MESSAGES, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Messages by ID
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 */
function* getMessage({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getMessageApi, { id });
        yield put(messageApiResponseSuccess(MessagesActionTypes.GET_MESSAGE_BY_ID, response.data));
    } catch (error) {
        console.log(error);
        yield put(messageApiResponseError(MessagesActionTypes.GET_MESSAGE_BY_ID, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Messages by User
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.user
 */
function* getMessagesByUser({ payload: { user } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getMessagesByUserApi, { user });
        yield put(messageApiResponseSuccess(MessagesActionTypes.GET_MESSAGES_BY_USER, response.data));
    } catch (error) {
        console.log(error);
        yield put(messageApiResponseError(MessagesActionTypes.GET_MESSAGES_BY_USER, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Messages by User
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.user
 */
function* getMessagesByTransaction({ payload: { transaction } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getMessagesByTransactionApi, { transaction });
        yield put(messageApiResponseSuccess(MessagesActionTypes.GET_MESSAGES_BY_TRANSACTION, response.data));
    } catch (error) {
        console.log(error);
        yield put(messageApiResponseError(MessagesActionTypes.GET_MESSAGES_BY_TRANSACTION, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Create a new message
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.parent
 * @param {string} action.payload.id
 * @param {string} action.payload.data
 */
function* createMessage({ payload: { parent, id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(createMessageApi, { parent, id, data });
        const file = response.data;
        yield put(messageApiResponseSuccess(MessagesActionTypes.CREATE_MESSAGE, file));
    } catch (error) {
        console.log(error);
        yield put(messageApiResponseError(MessagesActionTypes.CREATE_MESSAGE, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Update a Message
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* updateMessage({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(updateMessageApi, { id, data });
        yield put(messageApiResponseSuccess(MessagesActionTypes.UPDATE_MESSAGE, response.data));
    } catch (error) {
        console.log(error);
        yield put(messageApiResponseError(MessagesActionTypes.UPDATE_MESSAGE, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Comment on a message
 * @param {object} action
 * @param {string} action.payload
 * @param {object} action.payload.data
 */
function* createMessageAttachment({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(createMessageAttachmentApi, { id, data });
        yield put(messageApiResponseSuccess(MessagesActionTypes.CREATE_ATTACHMENT, response.data));
    } catch (error) {
        console.log(error);
        yield put(messageApiResponseError(MessagesActionTypes.CREATE_ATTACHMENT, error));
    }
    yield put(toggleLoading(false));
}

export function* watchGetMessages() {
    yield takeEvery(MessagesActionTypes.GET_MESSAGES, getMessages);
}

export function* watchGetMessage() {
    yield takeEvery(MessagesActionTypes.GET_MESSAGE_BY_ID, getMessage);
}

export function* watchGetMessagesByUser() {
    yield takeEvery(MessagesActionTypes.GET_MESSAGES_BY_USER, getMessagesByUser);
}

export function* watchGetMessagesByTransaction() {
    yield takeEvery(MessagesActionTypes.GET_MESSAGES_BY_TRANSACTION, getMessagesByTransaction);
}

export function* watchCreateMessage() {
    yield takeEvery(MessagesActionTypes.CREATE_MESSAGE, createMessage);
}

export function* watchUpdateMessage() {
    yield takeEvery(MessagesActionTypes.UPDATE_MESSAGE, updateMessage);
}

export function* watchCreateMessageAttachment() {
    yield takeEvery(MessagesActionTypes.CREATE_ATTACHMENT, createMessageAttachment);
}

function* messageSaga() {
    yield all([
        fork(watchGetMessages),
        fork(watchGetMessage),
        fork(watchGetMessagesByUser),
        fork(watchGetMessagesByTransaction),
        fork(watchUpdateMessage),
        fork(watchCreateMessage),
        fork(watchCreateMessageAttachment),
    ]);
}

export default messageSaga;
