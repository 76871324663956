// @flow
import { AuthActionTypes } from './constants';

type AuthAction = { type: string, payload: {} | string };

// common success
export const authApiResponseSuccess = (actionType: string, data: any): AuthAction => ({
    type: AuthActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const authApiResponseError = (actionType: string, error: string): AuthAction => ({
    type: AuthActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const loginUser = (email: string, password: string): AuthAction => ({
    type: AuthActionTypes.LOGIN_USER,
    payload: { email, password },
});

export const logoutUser = (): AuthAction => ({
    type: AuthActionTypes.LOGOUT_USER,
    payload: {},
});

export const refreshTokens = (tokens): AuthAction => ({
    type: AuthActionTypes.REFRESH_TOKENS,
    payload: { tokens },
});

export const signupUser = (invitation, data): AuthAction => ({
    type: AuthActionTypes.SIGNUP_USER,
    payload: { invitation, data },
});

export const forgotPassword = (email: string): AuthAction => ({
    type: AuthActionTypes.FORGOT_PASSWORD,
    payload: { email },
});

export const resetPassword = (password: string, token: String): AuthAction => ({
    type: AuthActionTypes.RESET_PASSWORD,
    payload: { password, token },
});

export const changePassword = (body): AuthAction => ({
    type: AuthActionTypes.CHANGE_PASSWORD,
    payload: body,
});

export const sendVerificationEmail = (token): AuthAction => ({
    type: AuthActionTypes.SEND_VERIFICATION_EMAIL,
    payload: { token },
});

export const resetAuth = (): AuthAction => ({
    type: AuthActionTypes.RESET,
    payload: {},
});
