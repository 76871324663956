import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { organizationApiResponseSuccess, organizationApiResponseError } from './actions';
import { OrganizationsActionTypes } from './constants';
import { toggleLoading } from '../actions';
import {
    createOrganization as createOrganizationApi,
    getOrganization as getOrganizationApi,
    getOrganizations as getOrganizationsApi,
    getOrganizationsByUser as getOrganizationsByUserApi,
    updateOrganization as updateOrganizationApi,
    addOrganizationMember as addOrganizationMemberApi,
    removeOrganizationMember as removeOrganizationMemberApi,
    updateOrganizationMember as updateOrganizationMemberApi,
    getOrganizationMembers as getOrganizationMembersApi,
    updateOrganizationSettings as updateOrganizationSettingsApi,
    getOrganizationSettings as getOrganizationSettingsApi,
} from '../../helpers';

/**
 * Get Organizations
 * @param {object} action
 * @param {string} action.payload
 */
function* getOrganizations() {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getOrganizationsApi);
        yield put(organizationApiResponseSuccess(OrganizationsActionTypes.GET_ORGANIZATIONS, response.data));
    } catch (error) {
        console.log(error);
        yield put(organizationApiResponseError(OrganizationsActionTypes.GET_ORGANIZATIONS, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Organizations by User
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.user
 */
function* getOrganizationsByUser({ payload: { user } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getOrganizationsByUserApi, { user });
        yield put(organizationApiResponseSuccess(OrganizationsActionTypes.GET_ORGANIZATIONS_BY_USER, response.data));
    } catch (error) {
        console.log(error);
        yield put(organizationApiResponseError(OrganizationsActionTypes.GET_ORGANIZATIONS_BY_USER, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Organization by ID
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.slug
 */
function* getOrganization({ payload: { slug } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getOrganizationApi, { slug });
        yield put(organizationApiResponseSuccess(OrganizationsActionTypes.GET_ORGANIZATION_BY_SLUG, response.data));
    } catch (error) {
        console.log(error);
        yield put(organizationApiResponseError(OrganizationsActionTypes.GET_ORGANIZATION_BY_SLUG, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Create a Organization
 * @param {object} action
 * @param {string} action.payload
 * @param {object} action.payload.data
 */
function* createOrganization({ payload: { slug, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(createOrganizationApi, { slug, data });
        yield put(organizationApiResponseSuccess(OrganizationsActionTypes.CREATE_ORGANIZATION, response.data));
    } catch (error) {
        console.log(error);
        yield put(organizationApiResponseError(OrganizationsActionTypes.CREATE_ORGANIZATION, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Organization members by ID
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.slug
 */
function* getOrganizationMembers({ payload: { slug } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getOrganizationMembersApi, { slug });
        yield put(
            organizationApiResponseSuccess(OrganizationsActionTypes.GET_ORGANIZATION_MEMBERS_BY_SLUG, response.data)
        );
    } catch (error) {
        console.log(error);
        yield put(organizationApiResponseError(OrganizationsActionTypes.GET_ORGANIZATION_MEMBERS_BY_SLUG, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Create a Organization member
 * @param {object} action
 * @param {string} action.payload
 * @param {object} action.payload.data
 */
function* createOrganizationMember({ payload: { slug, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(addOrganizationMemberApi, { slug, data });
        yield put(organizationApiResponseSuccess(OrganizationsActionTypes.CREATE_ORGANIZATION_MEMBER, response.data));
    } catch (error) {
        console.log(error);
        yield put(organizationApiResponseError(OrganizationsActionTypes.CREATE_ORGANIZATION_MEMBER, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Update a Organization
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.slug
 * @param {object} action.payload.data
 */
function* updateOrganization({ payload: { slug, data } }) {
    try {
        const response = yield call(updateOrganizationApi, { slug, data });
        yield put(organizationApiResponseSuccess(OrganizationsActionTypes.UPDATE_ORGANIZATION, response.data));
    } catch (error) {
        console.log(error);
        yield put(organizationApiResponseError(OrganizationsActionTypes.UPDATE_ORGANIZATION, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Update a Organization
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.slug
 * @param {object} action.payload.data
 */
function* updateOrganizationSettings({ payload: { slug, data } }) {
    try {
        const response = yield call(updateOrganizationSettingsApi, { slug, data });
        yield put(organizationApiResponseSuccess(OrganizationsActionTypes.UPDATE_ORGANIZATION_SETTINGS, response.data));
    } catch (error) {
        console.log(error);
        yield put(organizationApiResponseError(OrganizationsActionTypes.UPDATE_ORGANIZATION_SETTINGS, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Organization settings by slug
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.slug
 * @param {object} action.payload.data
 */
function* getOrganizationSettings({ payload: { slug } }) {
    try {
        const response = yield call(getOrganizationSettingsApi, { slug });
        yield put(organizationApiResponseSuccess(OrganizationsActionTypes.GET_ORGANIZATION_SETTINGS, response.data));
    } catch (error) {
        console.log(error);
        yield put(organizationApiResponseError(OrganizationsActionTypes.GET_ORGANIZATION_SETTINGS, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Update a Organization member
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.slug
 * @param {string} action.payload.member
 * @param {object} action.payload.data
 */
function* updateOrganizationMember({ payload: { slug, member, data } }) {
    try {
        const response = yield call(updateOrganizationMemberApi, { slug, member, data });
        yield put(organizationApiResponseSuccess(OrganizationsActionTypes.UPDATE_ORGANIZATION_MEMBER, response.data));
    } catch (error) {
        console.log(error);
        yield put(organizationApiResponseError(OrganizationsActionTypes.UPDATE_ORGANIZATION_MEMBER, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Delete a Organization member
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.slug
 * @param {object} action.payload.data
 */
function* removeOrganizationMember({ payload: { slug, member } }) {
    try {
        const response = yield call(removeOrganizationMemberApi, { slug, member });
        yield put(organizationApiResponseSuccess(OrganizationsActionTypes.REMOVE_ORGANIZATION_MEMBER, response.data));
    } catch (error) {
        console.log(error);
        yield put(organizationApiResponseError(OrganizationsActionTypes.REMOVE_ORGANIZATION_MEMBER, error));
    }
    yield put(toggleLoading(false));
}

export function* watchGetOrganizations() {
    yield takeEvery(OrganizationsActionTypes.GET_ORGANIZATIONS, getOrganizations);
}

export function* watchGetOrganizationsByUser() {
    yield takeEvery(OrganizationsActionTypes.GET_ORGANIZATIONS_BY_USER, getOrganizationsByUser);
}

export function* watchGetOrganization() {
    yield takeEvery(OrganizationsActionTypes.GET_ORGANIZATION_BY_SLUG, getOrganization);
}

export function* watchCreateOrganization() {
    yield takeEvery(OrganizationsActionTypes.CREATE_ORGANIZATION, createOrganization);
}

export function* watchUpdateOrganization() {
    yield takeEvery(OrganizationsActionTypes.UPDATE_ORGANIZATION, updateOrganization);
}

export function* watchGetOrganizationMembers() {
    yield takeEvery(OrganizationsActionTypes.GET_ORGANIZATION_MEMBERS_BY_SLUG, getOrganizationMembers);
}

export function* watchCreateOrganizationMember() {
    yield takeEvery(OrganizationsActionTypes.CREATE_ORGANIZATION_MEMBER, createOrganizationMember);
}

export function* watchUpdateOrganizationMember() {
    yield takeEvery(OrganizationsActionTypes.UPDATE_ORGANIZATION_MEMBER, updateOrganizationMember);
}

export function* watchUpdateOrganizationSettings() {
    yield takeEvery(OrganizationsActionTypes.UPDATE_ORGANIZATION_SETTINGS, updateOrganizationSettings);
}

export function* watchGetOrganizationSettings() {
    yield takeEvery(OrganizationsActionTypes.GET_ORGANIZATION_SETTINGS, getOrganizationSettings);
}

export function* watchDeleteOrganizationMember() {
    yield takeEvery(OrganizationsActionTypes.REMOVE_ORGANIZATION_MEMBER, removeOrganizationMember);
}

function* organizationSaga() {
    yield all([
        fork(watchGetOrganizations),
        fork(watchGetOrganizationsByUser),
        fork(watchGetOrganization),
        fork(watchUpdateOrganization),
        fork(watchCreateOrganization),
        fork(watchGetOrganizationMembers),
        fork(watchCreateOrganizationMember),
        fork(watchUpdateOrganizationMember),
        fork(watchDeleteOrganizationMember),
        fork(watchUpdateOrganizationSettings),
        fork(watchGetOrganizationSettings),
    ]);
}

export default organizationSaga;
