export const NotificationsActionTypes = {
    API_RESPONSE_SUCCESS: '@@notifications/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@notifications/API_RESPONSE_ERROR',
    // Notifications
    GET_NOTIFICATIONS: '@@notifications/GET_NOTIFICATIONS',
    GET_NOTIFICATION_BY_ID: '@@notifications/GET_NOTIFICATION_BY_ID',
    GET_NOTIFICATIONS_BY_USER: '@@notifications/GET_NOTIFICATIONS_BY_USER',
    GET_NOTIFICATIONS_BY_TRANSACTION: '@@notifications/GET_NOTIFICATIONS_BY_TRANSACTION',
    CREATE_NOTIFICATION: '@@notifications/CREATE_NOTIFICATION',
    UPDATE_NOTIFICATION: '@@notifications/UPDATE_NOTIFICATION',
    DISMISS_NOTIFICATION: '@@notifications/DISMISS_NOTIFICATION',
    ACKNOWLEDGE_NOTIFICATION: '@@notifications/ACKNOWLEDGE_NOTIFICATION',
    DELETE_NOTIFICATION: '@@notifications/DELETE_NOTIFICATION',
    RESET_NOTIFICATIONS: '@@notifications/RESET_NOTIFICATIONS',
    RESET_NOTIFICATION: '@@notifications/RESET_NOTIFICATION',
};
