import { MessagesActionTypes } from './constants';

const INIT_STATE = {
    data: null,
    loading: false,
    error: null,
    updateSuccess: null,
};

const Messages = (state = INIT_STATE, action) => {
    switch (action.type) {
        case MessagesActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case MessagesActionTypes.GET_MESSAGES:
                    return { ...state, data: action.payload.data, loading: false };
                case MessagesActionTypes.GET_MESSAGE_BY_ID:
                    return { ...state, data: action.payload.data, loading: false };
                case MessagesActionTypes.GET_MESSAGES_BY_USER:
                    return { ...state, data: action.payload.data, loading: false };
                case MessagesActionTypes.GET_MESSAGES_BY_TRANSACTION:
                    return { ...state, data: action.payload.data, loading: false };
                case MessagesActionTypes.CREATE_MESSAGE:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                case MessagesActionTypes.UPDATE_MESSAGE:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                case MessagesActionTypes.DELETE_MESSAGE:
                    return { ...state, data: action.payload.data, loading: false };
                case MessagesActionTypes.ARCHIVE_MESSAGE:
                    return { ...state, data: action.payload.data, loading: false };
                case MessagesActionTypes.CREATE_ATTACHMENT:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                default:
                    return { ...state };
            }
        case MessagesActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case MessagesActionTypes.GET_MESSAGES:
                    return { ...state, error: action.payload, loading: false };
                case MessagesActionTypes.GET_MESSAGE_BY_ID:
                    return { ...state, error: action.payload, loading: false };
                case MessagesActionTypes.GET_MESSAGES_BY_USER:
                    return { ...state, error: action.payload, loading: false };
                case MessagesActionTypes.GET_MESSAGES_BY_TRANSACTION:
                    return { ...state, error: action.payload, loading: false };
                case MessagesActionTypes.CREATE_MESSAGE:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case MessagesActionTypes.UPDATE_MESSAGE:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case MessagesActionTypes.DELETE_MESSAGE:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case MessagesActionTypes.ARCHIVE_MESSAGE:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case MessagesActionTypes.CREATE_ATTACHMENT:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                default:
                    return { ...state };
            }
        // Get Messages
        case MessagesActionTypes.GET_MESSAGES:
            return { ...state, loading: true };
        // Get a single message
        case MessagesActionTypes.GET_MESSAGE_BY_ID:
            return { ...state, data: null, loading: true };
        // Get an offer by ID
        case MessagesActionTypes.GET_MESSAGES_BY_USER:
            return { ...state, data: null, loading: true };
        case MessagesActionTypes.GET_MESSAGES_BY_TRANSACTION:
            return { ...state, data: null, loading: true };
        // Submit a new offer
        case MessagesActionTypes.UPDATE_MESSAGE: {
            return { ...state, id: action.payload.id, data: action.payload.data, loading: false };
        }
        case MessagesActionTypes.CREATE_MESSAGE:
            return { ...state, data: null, loading: true, updateSuccess: false };
        case MessagesActionTypes.RESET_MESSAGES:
            return INIT_STATE;
        default:
            return { ...state };
    }
};

export default Messages;
