/**
 * Capitalize the first character of a string
 * @param {*} string
 * @returns
 */
export function ucFirst(string) {
    try {
        return string.charAt(0).toUpperCase() + string.slice(1);
    } catch (e) {
        return string;
    }
}

/**
 * Humanize
 * @param {*} str
 * @returns
 */
export function humanize(str) {
    return str
        .replace(/^[\s_]+|[\s_]+$/g, '')
        .replace(/[_\s]+/g, ' ')
        .replace(/^[a-z]/, function (m) {
            return m.toUpperCase();
        });
}

/**
 * Scroll to the top of the page
 */
export function scrollTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}

/**
 * Calculate the percentage down
 * @param {*} input
 * @param {*} price
 * @returns
 */
export function percentDown(input, price) {
    let inputInt;
    let priceInt;
    try {
        inputInt = parseInt(input.replace(/,/g, ''));
    } catch (e) {
        inputInt = parseInt(input);
    }
    try {
        priceInt = parseInt(price.replace(/,/g, ''));
    } catch (e) {
        priceInt = parseInt(price);
    }
    var percent = ((inputInt / priceInt) * 100).toPrecision(4);
    var percentString = percent.toString();
    return percentString;
}

/**
 * Add days
 * @param {*} string
 * @returns
 */
export function addDays(string) {
    let date = new Date();
    let days = parseInt(string);
    date.setDate(date.getDate() + days);
    var stringed = date.toLocaleString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: '2-digit',
    });
    var stringDate = stringed.concat(', 5:00 PM');
    return stringDate;
}

/**
 * Create a timeline
 * @param {*} start
 * @param {*} length
 * @returns
 */
export function addDaysFromDate(start, length) {
    let date = new Date(start);
    let days = parseInt(length);
    date.setDate(date.getDate() + days);
    let stringed = date.toLocaleString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: '2-digit',
    });
    let stringDate = stringed.concat(', 5:00 PM');
    return stringDate;
}

/**
 * Add hours
 * @param {*} string
 * @returns
 */
export function addHours(string) {
    let date = new Date();
    let hours = parseInt(string);
    date.setHours(date.getHours() + hours);
    var stringDate = date.toLocaleString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    });
    return stringDate;
}

/**
 * Return a color for a given status
 * @param {*} status
 * @returns
 */
export function getColor(status) {
    switch (status) {
        case 'active':
        case 'completed':
            return 'success';
        case 'inactive':
        case 'countered':
        case 'delayed':
            return 'warning';
        case 'pending':
        case 'in-progress':
        case 'submitted':
        case 'rescheduled':
            return 'primary';
        case 'rejected':
        case 'past-due':
        case 'blocked':
            return 'danger';
        default:
            return 'secondary';
    }
}

/**
 * Calculate the number of remaining days for a given task
 * @param {*} start
 * @param {*} length
 * @returns
 */
export function daysRemaining(start, length = 0) {
    //set today
    let today = new Date();
    let start_date;
    let diff;
    let daysLeft = 'N/A';
    if (start) {
        //start date
        start_date = new Date(start);
        //calc positive difference, milliseconds
        diff = parseInt(today - start_date);
        //calculate days left, rounded to days
        daysLeft = Math.round(length - diff / (1000 * 60 * 60 * 24));
    }
    return daysLeft;
}

/**
 * Calculate the number of remaining days for a given task
 * @param {*} due_date
 * @returns
 */
export function daysTilDue(due_ate) {
    const today = new Date();
    const dueDate = new Date(due_ate);
    //calc positive difference, milliseconds
    const diff = parseInt(dueDate - today);
    //calculate days left, rounded to days
    const daysLeft = Math.round(diff / (1000 * 60 * 60 * 24));
    return daysLeft;
}

export function timeSince(date) {
    const seconds = Math.floor((new Date() - date) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
        return `${Math.floor(interval)} years`;
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return `${Math.floor(interval)} months`;
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return `${Math.floor(interval)} days`;
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return `${Math.floor(interval)} hours`;
    }
    interval = seconds / 60;
    if (interval > 1) {
        return `${Math.floor(interval)} minutes`;
    }
    return `${Math.floor(seconds)} seconds`;
}

export const gerUserPersonaFromTransaction = (sellers, buyers, user) => {
    let userPersona, counterPersona;
    if (sellers?.findIndex((seller) => seller.id === user?.id) !== -1) {
        userPersona = 'seller';
        counterPersona = 'buyer';
    } else if (buyers?.findIndex((buyer) => buyer.id === user?.id) !== -1) {
        userPersona = 'buyer';
        counterPersona = 'seller';
    } else {
        userPersona = 'buyer';
        counterPersona = 'seller';
    }
    return { userPersona, counterPersona };
};
