import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';

//actions
import { refreshTokens, logoutUser } from '../redux/actions';

const SessionTimeout = () => {
    const { user, refreshToken, userLogout } = useSelector((state) => ({
        user: state.Auth.user,
        refreshToken: state.Auth.refreshToken,
        userLogout: state.Auth.userLogout,
    }));
    const dispatch = useDispatch();
    const history = useHistory();

    // Set idle timeout values
    const idleTimeout = 1000 * 60 * 10;

    // Modal open state
    const [openModal, setOpenModal] = useState(false);

    const onIdle = () => {
        setOpenModal(true);
    };

    const { isIdle, activate } = useIdleTimer({
        idleTimeout,
        promptTimeout: 0,
        onIdle,
        startOnMount: true,
        startManually: false,
        stopOnIdle: false,
    });

    const expireTime = useCallback(
        (currentTime = new Date()) => {
            const expireTime = new Date(user?.tokens?.access?.expires);
            const minutes = Math.ceil((expireTime - currentTime) / (1000 * 60));

            return Math.round(minutes);
        },
        [user]
    );

    useEffect(() => {
        const intervalForAutomaticRefreshTokens = setInterval(() => {
            if (!isIdle()) {
                if (expireTime() < 10) {
                    dispatch(refreshTokens());
                }
            }
        }, 3000);

        return () => {
            clearInterval(intervalForAutomaticRefreshTokens);
        };
    }, [isIdle, dispatch, expireTime]);

    // Log out the user if they have been idle
    useEffect(() => {
        const intervalForAutomaticLogout = setInterval(() => {
            if (!openModal) return;
            dispatch(logoutUser());
            activate();
        }, 1000 * 60 * 5);

        return () => {
            clearInterval(intervalForAutomaticLogout);
        };
    }, [isIdle, dispatch, openModal, activate]);

    // Redirect logged out users to `/account/login`
    React.useEffect(() => {
        if (!userLogout) return;
        history.push('/account/login');
    }, [userLogout, history]);

    // Show the idle modal
    useEffect(() => {
        if (refreshToken) {
            setOpenModal(false);
        }
    }, [refreshToken]);

    // Refresh tokens to continue the session
    const handleContinueSession = () => {
        dispatch(refreshTokens());
        activate();
    };

    // Handle the logout
    const handleLogout = () => {
        dispatch(logoutUser());
        activate();
    };

    return (
        <Modal show={openModal} backdrop="static" keyboard={false} dialogClassName="danger">
            <Modal.Header>
                <Modal.Title>
                    {expireTime() <= 10
                        ? 'Your session will soon be expired'
                        : 'Your session will soon expire due to inactivity'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {expireTime() <= 10
                    ? `Your session will be expired in ${expireTime()} minutes.`
                    : `Would you like to continue your session?`}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleLogout}>
                    Logout
                </Button>
                {expireTime() <= 10 ? (
                    <Button variant="primary" onClick={handleContinueSession}>
                        Continue session
                    </Button>
                ) : (
                    <Button
                        variant="primary"
                        onClick={() => {
                            setOpenModal(false);
                            activate();
                        }}
                    >
                        Continue session
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default SessionTimeout;
