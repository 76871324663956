import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { anvilApiResponseSuccess2, anvilApiResponseError2 } from './actions';
import { AnvilActionTypes } from './constants';
import { toggleLoading } from '../actions';
import { signEtchPacket as signEtchPacketApi } from '../../helpers';

/**
 * Sign an Anvil eSignature packet
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* signEtchPacket2({ payload: { parent, id, document, data, formId } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(signEtchPacketApi, { parent, id, document, data });
        yield put(anvilApiResponseSuccess2(AnvilActionTypes.SIGN_ETCH_PACKET2, response.data, formId));
    } catch (error) {
        console.log(error);
        yield put(anvilApiResponseError2(AnvilActionTypes.SIGN_ETCH_PACKET2, error, formId));
    }
    yield put(toggleLoading(false));
}

export function* watchSignEtchPacket2() {
    yield takeEvery(AnvilActionTypes.SIGN_ETCH_PACKET2, signEtchPacket2);
}

function* anvilSaga() {
    yield all([fork(watchSignEtchPacket2)]);
}

export default anvilSaga;
