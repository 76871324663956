import { AddendumActionTypes } from './constants';

type AddendumAction = {
    type: string,
    payload: {} | string,
};

// common success
export const addendumApiResponseSuccess = (actionType: string, data: any): AddendumAction => ({
    type: AddendumActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const addendumApiResponseError = (actionType: string, error: string): AddendumAction => ({
    type: AddendumActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const submitAddendum = (id: string, data: object): AddendumAction => ({
    type: AddendumActionTypes.SUBMIT_ADDENDUM,
    payload: { id, data },
});

export const confirmAddendum = (id: string, data: object): AddendumAction => ({
    type: AddendumActionTypes.CONFIRM_ADDENDUM,
    payload: { id, data },
});

export const retractAddendum = (id: string, data: object): AddendumAction => ({
    type: AddendumActionTypes.RETRACT_ADDENDUM,
    payload: { id, data },
});

export const getAddendum = (id: string): AddendumAction => ({
    type: AddendumActionTypes.GET_ADDENDUM,
    payload: { id },
});

export const submitCounterAddendum = (id: string, data: object): AddendumAction => ({
    type: AddendumActionTypes.SUBMIT_COUNTER_ADDENDUM,
    payload: { id, data },
});

export const resetAddendum = (): AddendumAction => ({
    type: AddendumActionTypes.RESET_ADDENDUM,
});
