import { MessagesActionTypes } from './constants';

type MessagesAction = { type: string, payload: {} | string };

// common success
export const messageApiResponseSuccess = (actionType: string, data: any): MessagesAction => ({
    type: MessagesActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const messageApiResponseError = (actionType: string, error: string): MessagesAction => ({
    type: MessagesActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getMessages = (): MessagesAction => ({
    type: MessagesActionTypes.GET_MESSAGES,
});

export const getMessageById = (id: string): MessagesAction => ({
    type: MessagesActionTypes.GET_MESSAGE_BY_ID,
    payload: { id },
});

export const getMessagesByUser = (user: string): MessagesAction => ({
    type: MessagesActionTypes.GET_MESSAGES_BY_USER,
    payload: { user },
});

export const getMessagesByTransaction = (transaction: string): MessagesAction => ({
    type: MessagesActionTypes.GET_MESSAGES_BY_TRANSACTION,
    payload: { transaction },
});

export const createMessage = (parent: string, id: string, data: object): MessagesAction => ({
    type: MessagesActionTypes.CREATE_MESSAGE,
    payload: { parent, id, data },
});

export const updateMessage = (id: string, data: object): MessagesAction => ({
    type: MessagesActionTypes.UPDATE_MESSAGE,
    payload: { id, data },
});

export const deleteMessage = (id: string): MessagesAction => ({
    type: MessagesActionTypes.DELETE_MESSAGE,
    payload: { id },
});

export const archiveMessage = (id: string): MessagesAction => ({
    type: MessagesActionTypes.ARCHIVE_MESSAGE,
    payload: { id },
});

export const createMessageAttachment = (id: string, data: object): MessagesAction => ({
    type: MessagesActionTypes.CREATE_ATTACHMENT,
    payload: { id, data },
});

export const resetMessages = (): MessagesAction => ({
    type: MessagesActionTypes.RESET_MESSAGES,
});
