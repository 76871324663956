// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

/**
 * Extend a rentback
 * @param {*} transaction - The transaction ID to extend
 * @param {*} data - The form data to send
 * @returns
 */
function submitRentback(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/transactions/${id}/rentback/submit`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Complete a rentback
 * @param {*} transaction - The transaction ID to extend
 * @param {*} data - The form data to send
 * @returns
 */
function confirmRentback(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/rentback/${id}/confirm`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Retract a rentback
 * @param {*} transaction - The transaction ID to extend
 * @param {*} data - The form data to send
 * @returns
 */
function retractRentback(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/rentback/${id}/retract`;
    return api.create(`${baseUrl}`, data);
}

/**
 * get a rentback
 * @param {*} transaction - The transaction ID to extend
 * @param {*} data - The form data to send
 * @returns
 */
function getRentback(params: any): any {
    const { id } = params;
    const baseUrl = `${config.server.api}/v1/rentback/${id}`;
    return api.get(`${baseUrl}`);
}

export { submitRentback, confirmRentback, retractRentback, getRentback };
