import { RepairActionTypes } from './constants';

const INIT_STATE = {
    data: null,
    loading: false,
    error: null,
    updateSuccess: null,
    submitSuccess: null,
    retractSuccess: null,
    getSuccess: null,
};

const Repair = (state = INIT_STATE, action) => {
    switch (action.type) {
        case RepairActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case RepairActionTypes.GET_REPAIR:
                    return { ...state, data: action.payload.data, loading: false, getSuccess: true };
                case RepairActionTypes.SUBMIT_REPAIR:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        submitSuccess: true,
                    };
                case RepairActionTypes.CONFIRM_REPAIR:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        submitSuccess: true,
                    };

                case RepairActionTypes.SUBMIT_COUNTER_REPAIR:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        submitCounterSuccess: true,
                    };
                case RepairActionTypes.CONFIRM_COUNTER_REPAIR:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        confirmCounterSuccess: true,
                    };

                case RepairActionTypes.RETRACT_REPAIR:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        retractSuccess: true,
                    };
                default:
                    return { ...state };
            }
        case RepairActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case RepairActionTypes.GET_REPAIR:
                    return { ...state, error: action.payload, loading: false, getSuccess: false };
                case RepairActionTypes.SUBMIT_REPAIR:
                    return { ...state, error: action.payload, loading: false, submitSuccess: false };
                case RepairActionTypes.CONFIRM_REPAIR:
                    return { ...state, error: action.payload, loading: false, submitSuccess: false };
                case RepairActionTypes.SUBMIT_COUNTER_REPAIR:
                    return { ...state, error: action.payload, loading: false, submitCounterSuccess: false };
                case RepairActionTypes.CONFIRM_COUNTER_REPAIR:
                    return { ...state, error: action.payload, loading: false, confirmCounterSuccess: false };
                case RepairActionTypes.RETRACT_REPAIR:
                    return { ...state, error: action.payload, loading: false, retractSuccess: false };

                default:
                    return { ...state };
            }
        case RepairActionTypes.GET_REPAIR:
            return { ...state, data: null, loading: false };
        case RepairActionTypes.SUBMIT_REPAIR:
            return { ...state, data: null, loading: false };
        case RepairActionTypes.CONFIRM_REPAIR:
            return { ...state, data: null, loading: false };
        case RepairActionTypes.SUBMIT_COUNTER_REPAIR:
            return { ...state, data: null, loading: false };
        case RepairActionTypes.CONFIRM_COUNTER_REPAIR:
            return { ...state, data: null, loading: false };
        case RepairActionTypes.RETRACT_REPAIR:
            return { ...state, data: null, loading: false };
        case RepairActionTypes.RESET_REPAIRS:
            return INIT_STATE;
        default:
            return { ...state };
    }
};

export default Repair;
