// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

/**
 * Get a single user
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getUser(params: any): any {
    const baseUrl = `${config.server.api}/v1/users/${params.id}`;
    return api.get(`${baseUrl}`);
}

/**
 * Get all users
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getUsers(params: any): any {
    const baseUrl = `${config.server.api}/v1/users`;
    return api.get(`${baseUrl}`);
}

/**
 * Get an user by email
 * @param {*} email
 * @returns
 */
function getUserByEmail(email: any): any {
    const baseUrl = `${config.server.api}/v1/users/email/${email}`;
    return api.get(`${baseUrl}`);
}

/**
 * Update a user
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function updateUser(params: any): any {
    const baseUrl = `${config.server.api}/v1/users/${params.id}`;
    return api.updatePatch(`${baseUrl}`, params.data);
}

/**
 * Create a avatar on user
 * @param {*} params
 * @returns
 */
function createUserAttachment(params: any): any {
    const { id, files } = params;
    const baseUrl = `${config.server.api}/v1/users/${id}/files`;
    return api.createWithFile(`${baseUrl}`, files);
}
export { getUser, getUsers, updateUser, createUserAttachment, getUserByEmail };
