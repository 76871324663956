import { FilesActionTypes } from './constants';

type FilesAction = { type: string, payload: {} | string };

// common success
export const fileApiResponseSuccess = (actionType: string, data: any): FilesAction => ({
    type: FilesActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const fileApiResponseError = (actionType: string, error: string): FilesAction => ({
    type: FilesActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const createFile = (parent: string, id: string, data: object): FilesAction => ({
    type: FilesActionTypes.CREATE_FILE,
    payload: { parent, id, data },
});

export const getFiles = (options): FilesAction => ({
    type: FilesActionTypes.GET_FILES,
    payload: options,
});

export const getFile = (id: string): FilesAction => ({
    type: FilesActionTypes.GET_FILE_BY_ID,
    payload: { id },
});

export const getFilesByUser = (user: string): FilesAction => ({
    type: FilesActionTypes.GET_FILES_BY_USER,
    payload: { user },
});

export const getFilesByListing = (listing: string): FilesAction => ({
    type: FilesActionTypes.GET_FILES_BY_LISTING,
    payload: { listing },
});

export const getFilesByOrganization = (organization: string): FilesAction => ({
    type: FilesActionTypes.GET_FILES_BY_ORGANIZATION,
    payload: { organization },
});

export const getFilesByTask = (task: string): FilesAction => ({
    type: FilesActionTypes.GET_FILES_BY_TASK,
    payload: { task },
});

export const getFilesByTransaction = (transaction: string): FilesAction => ({
    type: FilesActionTypes.GET_FILES_BY_TRANSACTION,
    payload: { transaction },
});

export const updateFile = (id: string, data: object): FilesAction => ({
    type: FilesActionTypes.UPDATE_FILE,
    payload: { id, data },
});

export const resetFiles = (): FilesAction => ({
    type: FilesActionTypes.RESET_FILES,
});
