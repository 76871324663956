import { NotificationsActionTypes } from './constants';

const INIT_STATE = {
    data: null,
    loading: false,
    error: null,
    updateSuccess: null,
};

const Notification = (state = INIT_STATE, action) => {
    switch (action.type) {
        case NotificationsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case NotificationsActionTypes.GET_NOTIFICATION_BY_ID:
                    return { ...state, data: action.payload.data, loading: false };
                case NotificationsActionTypes.CREATE_NOTIFICATION:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                case NotificationsActionTypes.UPDATE_NOTIFICATION:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                case NotificationsActionTypes.DISMISS_NOTIFICATION:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        dismissSuccess: true,
                    };
                case NotificationsActionTypes.ACKNOWLEDGE_NOTIFICATION:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        acknowledgeSuccess: true,
                    };
                case NotificationsActionTypes.DELETE_NOTIFICATION:
                    return { ...state, data: action.payload.data, loading: false };
                default:
                    return { ...state };
            }
        case NotificationsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case NotificationsActionTypes.GET_NOTIFICATION_BY_ID:
                    return { ...state, error: action.payload, loading: false };
                case NotificationsActionTypes.CREATE_NOTIFICATION:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case NotificationsActionTypes.UPDATE_NOTIFICATION:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case NotificationsActionTypes.DISMISS_NOTIFICATION:
                    return { ...state, error: action.payload, loading: false, dismissSuccess: false };
                case NotificationsActionTypes.ACKNOWLEDGE_NOTIFICATION:
                    return { ...state, error: action.payload, loading: false, acknowledgeSuccess: false };
                case NotificationsActionTypes.DELETE_NOTIFICATION:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                default:
                    return { ...state };
            }
        // Get a single task
        case NotificationsActionTypes.GET_NOTIFICATION_BY_ID:
            return { ...state, data: null, loading: true };
        // Update a task
        case NotificationsActionTypes.UPDATE_NOTIFICATION:
            return { ...state, id: action.payload.id, data: action.payload.data, loading: true };
        case NotificationsActionTypes.DISMISS_NOTIFICATION:
            return { ...state, id: action.payload.id, data: action.payload.data, loading: true };
        case NotificationsActionTypes.ACKNOWLEDGE_NOTIFICATION:
            return { ...state, id: action.payload.id, data: action.payload.data, loading: true };
        case NotificationsActionTypes.RESET_NOTIFICATION:
            return INIT_STATE;
        default:
            return { ...state };
    }
};

const Notifications = (state = INIT_STATE, action) => {
    switch (action.type) {
        case NotificationsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case NotificationsActionTypes.GET_NOTIFICATIONS:
                    return { ...state, data: action.payload.data, loading: false };
                case NotificationsActionTypes.GET_NOTIFICATIONS_BY_USER:
                    return { ...state, data: action.payload.data, loading: false };
                case NotificationsActionTypes.GET_NOTIFICATIONS_BY_TRANSACTION:
                    return { ...state, data: action.payload.data, loading: false };
                default:
                    return { ...state };
            }
        case NotificationsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case NotificationsActionTypes.GET_NOTIFICATIONS:
                    return { ...state, error: action.payload, loading: false };
                case NotificationsActionTypes.GET_NOTIFICATIONS_BY_USER:
                    return { ...state, error: action.payload, loading: false };
                case NotificationsActionTypes.GET_NOTIFICATIONS_BY_TRANSACTION:
                    return { ...state, error: action.payload, loading: false };
                default:
                    return { ...state };
            }
        // Get Tasks
        case NotificationsActionTypes.GET_NOTIFICATIONS:
            return { ...state, loading: true };
        case NotificationsActionTypes.GET_NOTIFICATIONS_BY_USER:
            return { ...state, data: null, loading: true };
        case NotificationsActionTypes.GET_NOTIFICATIONS_BY_TRANSACTION:
            return { ...state, data: null, loading: true };
        case NotificationsActionTypes.RESET_NOTIFICATIONS:
            return INIT_STATE;
        default:
            return { ...state };
    }
};

export { Notification, Notifications };
