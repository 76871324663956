export const FilesActionTypes = {
    API_RESPONSE_SUCCESS: '@@files/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@files/API_RESPONSE_ERROR',

    GET_FILES: '@@files/GET_FILES',
    GET_FILE_BY_ID: '@@files/GET_FILE_BY_ID',
    GET_FILES_BY_USER: '@@files/GET_FILES_BY_USER',
    GET_FILES_BY_LISTING: '@@files/GET_FILES_BY_LISTING',
    GET_FILES_BY_ORGANIZATION: '@@files/GET_FILES_BY_ORGANIZATION',
    GET_FILES_BY_TASK: '@@files/GET_FILES_BY_TASK',
    GET_FILES_BY_TRANSACTION: '@@files/GET_FILES_BY_TRANSACTION',
    CREATE_FILE: '@@files/CREATE_FILE',
    UPDATE_FILE: '@@files/UPDATE_FILE',
    DELETE_FILE: '@@files/DELETE_FILE',
    RESET_FILES: '@@files/RESET_FILES',
};
