import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { commentApiResponseSuccess, commentApiResponseError } from './actions';
import { CommentsActionTypes } from './constants';
import { toggleLoading } from '../actions';
import {
    createComment as createCommentApi,
    createCommentAttachment as createCommentAttachmentApi,
    getComment as getCommentApi,
    getComments as getCommentsApi,
    getCommentsByUser as getCommentsByUserApi,
    getCommentsByTask as getCommentsByTaskApi,
    updateComment as updateCommentApi,
} from '../../helpers';

/**
 * Get Comments
 * @param {object} action
 * @param {string} action.payload
 */
function* getComments({ payload: { parent, id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getCommentsApi, { parent, id });
        yield put(commentApiResponseSuccess(CommentsActionTypes.GET_COMMENTS, response.data));
    } catch (error) {
        console.log(error);
        yield put(commentApiResponseError(CommentsActionTypes.GET_COMMENTS, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Comments by ID
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 */
function* getComment({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getCommentApi, { id });
        yield put(commentApiResponseSuccess(CommentsActionTypes.GET_COMMENT_BY_ID, response.data));
    } catch (error) {
        console.log(error);
        yield put(commentApiResponseError(CommentsActionTypes.GET_COMMENT_BY_ID, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Comments by User
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.user
 */
function* getCommentsByUser({ payload: { user } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getCommentsByUserApi, { user });
        yield put(commentApiResponseSuccess(CommentsActionTypes.GET_COMMENTS_BY_USER, response.data));
    } catch (error) {
        console.log(error);
        yield put(commentApiResponseError(CommentsActionTypes.GET_COMMENTS_BY_USER, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Comments by User
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.user
 */
function* getCommentsByTask({ payload: { task } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getCommentsByTaskApi, { task });
        yield put(commentApiResponseSuccess(CommentsActionTypes.GET_COMMENTS_BY_TASK, response.data));
    } catch (error) {
        console.log(error);
        yield put(commentApiResponseError(CommentsActionTypes.GET_COMMENTS_BY_TASK, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Create a new comment
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.parent
 * @param {string} action.payload.id
 * @param {string} action.payload.data
 */
function* createComment({ payload: { parent, id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(createCommentApi, { parent, id, data });
        const file = response.data;
        yield put(commentApiResponseSuccess(CommentsActionTypes.CREATE_COMMENT, file));
    } catch (error) {
        console.log(error);
        yield put(commentApiResponseError(CommentsActionTypes.CREATE_COMMENT, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Update a Comment
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* updateComment({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(updateCommentApi, { id, data });
        yield put(commentApiResponseSuccess(CommentsActionTypes.UPDATE_COMMENT, response.data));
    } catch (error) {
        console.log(error);
        yield put(commentApiResponseError(CommentsActionTypes.UPDATE_COMMENT, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Comment on a comment
 * @param {object} action
 * @param {string} action.payload
 * @param {object} action.payload.data
 */
function* createCommentAttachment({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(createCommentAttachmentApi, { id, data });
        yield put(commentApiResponseSuccess(CommentsActionTypes.CREATE_ATTACHMENT, response.data));
    } catch (error) {
        console.log(error);
        yield put(commentApiResponseError(CommentsActionTypes.CREATE_ATTACHMENT, error));
    }
    yield put(toggleLoading(false));
}

export function* watchGetComments() {
    yield takeEvery(CommentsActionTypes.GET_COMMENTS, getComments);
}

export function* watchGetComment() {
    yield takeEvery(CommentsActionTypes.GET_COMMENT_BY_ID, getComment);
}

export function* watchGetCommentsByUser() {
    yield takeEvery(CommentsActionTypes.GET_COMMENTS_BY_USER, getCommentsByUser);
}

export function* watchGetCommentsByTask() {
    yield takeEvery(CommentsActionTypes.GET_COMMENTS_BY_TASK, getCommentsByTask);
}

export function* watchCreateComment() {
    yield takeEvery(CommentsActionTypes.CREATE_COMMENT, createComment);
}

export function* watchUpdateComment() {
    yield takeEvery(CommentsActionTypes.UPDATE_COMMENT, updateComment);
}

export function* watchCreateCommentAttachment() {
    yield takeEvery(CommentsActionTypes.CREATE_ATTACHMENT, createCommentAttachment);
}

function* commentSaga() {
    yield all([
        fork(watchGetComments),
        fork(watchGetComment),
        fork(watchGetCommentsByUser),
        fork(watchGetCommentsByTask),
        fork(watchUpdateComment),
        fork(watchCreateComment),
        fork(watchCreateCommentAttachment),
    ]);
}

export default commentSaga;
