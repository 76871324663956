import { OrganizationsActionTypes } from './constants';

const INIT_STATE = {
    data: null,
    loading: false,
    error: null,
    updateSuccess: null,
    createSuccess: null,
    deleteSuccess: null,
    createMemberSuccess: null,
    updateMemberSuccess: null,
    removeMemberSuccess: null,
};

const Organization = (state = INIT_STATE, action) => {
    switch (action.type) {
        case OrganizationsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case OrganizationsActionTypes.GET_ORGANIZATION_BY_SLUG:
                    return { ...state, data: action.payload.data, loading: false };
                case OrganizationsActionTypes.CREATE_ORGANIZATION:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        createSuccess: true,
                    };
                case OrganizationsActionTypes.UPDATE_ORGANIZATION:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                case OrganizationsActionTypes.DELETE_ORGANIZATION:
                    return { ...state, data: action.payload.data, loading: false, deleteSuccess: true };

                case OrganizationsActionTypes.GET_ORGANIZATION_MEMBERS_BY_SLUG:
                    return {
                        ...state,
                        data: {
                            ...state.data,
                            members: action.payload.data,
                        },
                        loading: false,
                    };

                case OrganizationsActionTypes.CREATE_ORGANIZATION_MEMBER:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        createMemberSuccess: true,
                    };
                case OrganizationsActionTypes.UPDATE_ORGANIZATION_MEMBER:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateMemberSuccess: true,
                    };
                case OrganizationsActionTypes.REMOVE_ORGANIZATION_MEMBER:
                    return { ...state, data: action.payload.data, loading: false, removeMemberSuccess: true };

                default:
                    return { ...state };
            }
        case OrganizationsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case OrganizationsActionTypes.GET_ORGANIZATION_BY_SLUG:
                    return { ...state, error: action.payload, loading: false };
                case OrganizationsActionTypes.CREATE_ORGANIZATION:
                    return { ...state, error: action.payload, loading: false, createSuccess: false };
                case OrganizationsActionTypes.UPDATE_ORGANIZATION:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case OrganizationsActionTypes.DELETE_ORGANIZATION:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case OrganizationsActionTypes.GET_ORGANIZATION_MEMBERS_BY_SLUG:
                    return { ...state, error: action.payload, loading: false };
                case OrganizationsActionTypes.CREATE_ORGANIZATION_MEMBER:
                    return {
                        ...state,
                        error: action.payload,
                        loading: false,
                        createMemberSuccess: false,
                    };
                case OrganizationsActionTypes.UPDATE_ORGANIZATION_MEMBER:
                    return {
                        ...state,
                        error: action.payload,
                        loading: false,
                        updateMemberSuccess: false,
                    };
                case OrganizationsActionTypes.REMOVE_ORGANIZATION_MEMBER:
                    return { ...state, error: action.payload, loading: false, removeMemberSuccess: false };

                default:
                    return { ...state };
            }
        // Get a single organization
        case OrganizationsActionTypes.GET_ORGANIZATION_BY_SLUG:
            return { ...state, data: null, loading: true };
        case OrganizationsActionTypes.GET_ORGANIZATIONS_BY_USER:
            return { ...state, data: null, loading: true };
        // Update a organization
        case OrganizationsActionTypes.UPDATE_ORGANIZATION:
            return { ...state, id: action.payload.id, data: action.payload.data, loading: true };
        case OrganizationsActionTypes.UPDATE_ORGANIZATION_SETTINGS:
            return { ...state, id: action.payload.id, data: action.payload.data, loading: true };
        case OrganizationsActionTypes.GET_ORGANIZATION_MEMBERS_BY_SLUG:
            return { ...state, data: null, loading: true };
        case OrganizationsActionTypes.UPDATE_ORGANIZATION_MEMBER:
            return { ...state, id: action.payload.id, data: action.payload.data, loading: true };
        case OrganizationsActionTypes.RESET_ORGANIZATION:
            return INIT_STATE;
        default:
            return { ...state };
    }
};

const OrganizationSettings = (state = INIT_STATE, action) => {
    switch (action.type) {
        case OrganizationsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case OrganizationsActionTypes.GET_ORGANIZATION_SETTINGS:
                    return { ...state, data: action.payload.data, loading: false };
                case OrganizationsActionTypes.UPDATE_ORGANIZATION_SETTINGS:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                default:
                    return { ...state };
            }
        case OrganizationsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case OrganizationsActionTypes.GET_ORGANIZATION_SETTINGS:
                    return { ...state, error: action.payload, loading: false };
                case OrganizationsActionTypes.UPDATE_ORGANIZATION_SETTINGS:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                default:
                    return { ...state };
            }
        case OrganizationsActionTypes.GET_ORGANIZATION_SETTINGS:
            return { ...state, loading: true };
        case OrganizationsActionTypes.RESET_ORGANIZATION_SETTINGS:
            return INIT_STATE;
        default:
            return { ...state };
    }
};

const Organizations = (state = INIT_STATE, action) => {
    switch (action.type) {
        case OrganizationsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case OrganizationsActionTypes.GET_ORGANIZATIONS:
                    return { ...state, data: action.payload.data, loading: false };
                case OrganizationsActionTypes.GET_ORGANIZATIONS_BY_USER:
                    return { ...state, data: action.payload.data, loading: false };
                default:
                    return { ...state };
            }
        case OrganizationsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case OrganizationsActionTypes.GET_ORGANIZATIONS:
                    return { ...state, error: action.payload, loading: false };
                case OrganizationsActionTypes.GET_ORGANIZATIONS_BY_USER:
                    return { ...state, error: action.payload, loading: false };
                default:
                    return { ...state };
            }
        // Get Organizations
        case OrganizationsActionTypes.GET_ORGANIZATIONS:
            return { ...state, loading: true };
        case OrganizationsActionTypes.GET_ORGANIZATIONS_BY_USER:
            return { ...state, loading: true };
        case OrganizationsActionTypes.RESET_ORGANIZATIONS:
            return INIT_STATE;
        default:
            return { ...state };
    }
};

export { Organization, Organizations, OrganizationSettings };
