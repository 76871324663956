// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

/**
 * Create a new listing
 * @param {*} params
 * @returns
 */
function createListing(params: any): any {
    const baseUrl = `${config.server.api}/v1/listings`;
    return api.create(`${baseUrl}`, params.data);
}

/**
 * Update the details of a listing
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function updateListing(params: any): any {
    const baseUrl = `${config.server.api}/v1/listings/${params.id}`;
    return api.updatePatch(`${baseUrl}`, params.data);
}

/**
 * Get a single listing
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getListing(params: any): any {
    const baseUrl = `${config.server.api}/v1/listings/${params.id}`;
    return api.get(`${baseUrl}`);
}

/**
 * Get listings for a user
 * @param {*} params
 * @returns
 */
function getUserListings(params: any): any {
    const baseUrl = `${config.server.api}/v1/users/${params.user}/listings`;
    return api.get(`${baseUrl}`);
}

/**
 * Get access list for a single offer
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getAccessByListing(params: any): any {
    const baseUrl = `${config.server.api}/v1/listings/${params.id}/access`;
    return api.get(`${baseUrl}`);
}

/**
 * Get access list for a single offer
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function revokeAccessByListing(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/listings/${id}/access`;
    return api.create(`${baseUrl}`, data);
}

export { createListing, updateListing, getListing, getUserListings, getAccessByListing, revokeAccessByListing };
