// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

/**
 * Create a new disclosure
 * @param {*} params
 * @returns
 */
function createDisclosure(params: any): any {
    const baseUrl = `${config.server.api}/v1/listings/${params.listing}/disclosures`;
    return api.create(`${baseUrl}`, params.data);
}

/**
 * Update the details of a disclosure
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function updateDisclosure(params: any): any {
    const baseUrl = `${config.server.api}/v1/disclosures/${params.id}`;
    return api.updatePatch(`${baseUrl}`, params.data);
}

/**
 * Get a single disclosure
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getDisclosure(params: any): any {
    const baseUrl = `${config.server.api}/v1/disclosures/${params.id}`;
    return api.get(`${baseUrl}`);
}

/**
 * Delete a single disclosure
 * @param {*} id
 * @param {*} params
 * @returns
 */
function deleteDisclosure(params: any): any {
    const baseUrl = `${config.server.api}/v1/disclosures/${params.id}`;
    return api.delete(`${baseUrl}`);
}

/**
 * Get all disclosures for a listing
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getDisclosures(params: any): any {
    const baseUrl = `${config.server.api}/v1/disclosures`;
    return api.get(`${baseUrl}`);
}

/**
 * Get all disclosures submitted by a given user
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getDisclosuresByUser(params: any): any {
    const baseUrl = `${config.server.api}/v1/users/${params.user}/disclosures`;
    return api.get(`${baseUrl}`);
}

/**
 * Get all disclosures for a listing
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getDisclosuresByListing(params: any): any {
    const baseUrl = `${config.server.api}/v1/listings/${params.listing}/disclosures`;
    return api.get(`${baseUrl}`);
}

/**
 * Submit the disclosures after the first signer
 * @param {*} transaction - The transaction ID to use
 * @param {*} data - The form data to send
 * @returns
 */
function submitDisclosures(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/disclosures/${id}/submit`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Complete a disclosures packet
 * @param {*} transaction - The transaction ID to extend
 * @param {*} data - The form data to send
 * @returns
 */
function confirmDisclosures(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/disclosures/${id}/confirm`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Reject disclosures
 * @param {*} transaction - The transaction ID to extend
 * @param {*} data - The form data to send
 * @returns
 */
function rejectDisclosures(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/disclosures/${id}/reject`;
    return api.create(`${baseUrl}`, data);
}

export {
    createDisclosure,
    updateDisclosure,
    getDisclosure,
    getDisclosures,
    getDisclosuresByListing,
    getDisclosuresByUser,
    submitDisclosures,
    confirmDisclosures,
    rejectDisclosures,
    deleteDisclosure,
};
