// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { toggleLoading } from '../actions';
import {
    getUsers as getUsersApi,
    getUser as getUserApi,
    updateUser as updateUserApi,
    createUserAttachment as createUserAttachmentApi,
} from '../../helpers/';
import { userApiResponseSuccess, userApiResponseError } from './actions';
import { UserActionTypes } from './constants';

/**
 *
 * @param {*} param0
 */
function* getUsers() {
    yield put(toggleLoading(true));
    try {
        const res = yield call(getUsersApi);
        yield put(userApiResponseSuccess(UserActionTypes.GET_USERS, res.data));
    } catch (error) {
        console.log(error);
        yield put(userApiResponseError(UserActionTypes.GET_USERS, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get User by ID
 * @param {*} id
 */
function* getUser({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getUserApi, { id });
        yield put(userApiResponseSuccess(UserActionTypes.GET_USER_BY_ID, response.data));
    } catch (error) {
        console.log(error);
        yield put(userApiResponseError(UserActionTypes.GET_USER_BY_ID, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Avatar on a user
 * @param {object} action
 * @param {string} action.payload
 * @param {object} action.payload.data
 */
function* createUserAttachment({ payload: { id, files } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(createUserAttachmentApi, { id, files });
        yield put(userApiResponseSuccess(UserActionTypes.CREATE_ATTACHMENT, response.data));
    } catch (error) {
        console.log(error);
        yield put(userApiResponseError(UserActionTypes.CREATE_ATTACHMENT, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Update user details
 * @param {*} id
 * @param {*} data
 */
function* updateUser({ payload: { id, data } }) {
    try {
        const response = yield call(updateUserApi, { id, data });
        yield put(userApiResponseSuccess(UserActionTypes.UPDATE_USER, response.data));
    } catch (error) {
        yield put(userApiResponseError(UserActionTypes.UPDATE_USER, error));
    }
}

export function* watchGetUsers() {
    yield takeEvery(UserActionTypes.GET_USERS, getUsers);
}
export function* watchGetUser() {
    yield takeEvery(UserActionTypes.GET_USER_BY_ID, getUser);
}
export function* watchUpdateUser(): any {
    yield takeEvery(UserActionTypes.UPDATE_USER, updateUser);
}
export function* watchCreateUserAttachment(): any {
    yield takeEvery(UserActionTypes.CREATE_ATTACHMENT, createUserAttachment);
}

function* userSaga(): any {
    yield all([fork(watchGetUsers), fork(watchGetUser), fork(watchUpdateUser), fork(watchCreateUserAttachment)]);
}

export default userSaga;
