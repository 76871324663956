import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { fileApiResponseSuccess, fileApiResponseError } from './actions';
import { FilesActionTypes } from './constants';
import { toggleLoading } from '../actions';
import {
    createFile as createFileApi,
    getFile as getFileApi,
    getFiles as getFilesApi,
    getFilesByUser as getFilesByUserApi,
    getFilesByListing as getFilesByListingApi,
    getFilesByOrganization as getFilesByOrganizationApi,
    getFilesByTask as getFilesByTaskApi,
    getFilesByTransaction as getFilesByTransactionApi,
    updateFile as updateFileApi,
} from '../../helpers';

/**
 * Create a new file
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.parent
 * @param {string} action.payload.id
 * @param {string} action.payload.data
 */
function* createFile({ payload: { parent, id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(createFileApi, { parent, id, data });
        const file = response.data;
        yield put(fileApiResponseSuccess(FilesActionTypes.CREATE_FILE, file));
    } catch (error) {
        console.log(error);
        yield put(fileApiResponseError(FilesActionTypes.CREATE_FILE, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Files
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.parent
 * @param {string} action.payload.id
 */
function* getFiles({ payload: { parent, id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getFilesApi, { parent, id });
        yield put(fileApiResponseSuccess(FilesActionTypes.GET_FILES, response.data));
    } catch (error) {
        console.log(error);
        yield put(fileApiResponseError(FilesActionTypes.GET_FILES, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get files submitted by a given user
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.user
 */
function* getFilesByUser({ payload: { user } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getFilesByUserApi, { user });
        yield put(fileApiResponseSuccess(FilesActionTypes.GET_FILES_BY_USER, response.data));
    } catch (error) {
        console.log(error);
        yield put(fileApiResponseError(FilesActionTypes.GET_FILES_BY_USER, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get files submitted for a given listing
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.listing
 */
function* getFilesByListing({ payload: { listing } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getFilesByListingApi, { listing });
        yield put(fileApiResponseSuccess(FilesActionTypes.GET_FILES_BY_LISTING, response.data));
    } catch (error) {
        console.log(error);
        yield put(fileApiResponseError(FilesActionTypes.GET_FILES_BY_LISTING, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get files submitted for a given listing
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.listing
 */
function* getFilesByOrganization({ payload: { organization } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getFilesByOrganizationApi, { organization });
        yield put(fileApiResponseSuccess(FilesActionTypes.GET_FILES_BY_ORGANIZATION, response.data));
    } catch (error) {
        console.log(error);
        yield put(fileApiResponseError(FilesActionTypes.GET_FILES_BY_ORGANIZATION, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get files submitted for a given task
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.task
 */
function* getFilesByTask({ payload: { task } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getFilesByTaskApi, { task });
        yield put(fileApiResponseSuccess(FilesActionTypes.GET_FILES_BY_TASK, response.data));
    } catch (error) {
        console.log(error);
        yield put(fileApiResponseError(FilesActionTypes.GET_FILES_BY_TASK, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get files submitted for a given transaction
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.transaction
 */
function* getFilesByTransaction({ payload: { transaction } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getFilesByTransactionApi, { transaction });
        yield put(fileApiResponseSuccess(FilesActionTypes.GET_FILES_BY_TRANSACTION, response.data));
    } catch (error) {
        console.log(error);
        yield put(fileApiResponseError(FilesActionTypes.GET_FILES_BY_TRANSACTION, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get file by ID
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 */
function* getFile({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getFileApi, { id });
        yield put(fileApiResponseSuccess(FilesActionTypes.GET_FILE_BY_ID, response.data));
    } catch (error) {
        console.log(error);
        yield put(fileApiResponseError(FilesActionTypes.GET_FILE_BY_ID, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Update a file
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* updateFile({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(updateFileApi, { id, data });
        yield put(fileApiResponseSuccess(FilesActionTypes.UPDATE_FILE, response.data));
    } catch (error) {
        console.log(error);
        yield put(fileApiResponseError(FilesActionTypes.UPDATE_FILE, error));
    }
    yield put(toggleLoading(false));
}

export function* watchCreateFile() {
    yield takeEvery(FilesActionTypes.CREATE_FILE, createFile);
}

export function* watchGetFiles() {
    yield takeEvery(FilesActionTypes.GET_FILES, getFiles);
}

export function* watchGetFilesByUser() {
    yield takeEvery(FilesActionTypes.GET_FILES_BY_USER, getFilesByUser);
}

export function* watchGetFilesByListing() {
    yield takeEvery(FilesActionTypes.GET_FILES_BY_LISTING, getFilesByListing);
}

export function* watchGetFilesByOrganization() {
    yield takeEvery(FilesActionTypes.GET_FILES_BY_ORGANIZATION, getFilesByOrganization);
}

export function* watchGetFilesByTask() {
    yield takeEvery(FilesActionTypes.GET_FILES_BY_TASK, getFilesByTask);
}

export function* watchGetFilesByTransaction() {
    yield takeEvery(FilesActionTypes.GET_FILES_BY_TRANSACTION, getFilesByTransaction);
}

export function* watchGetFile() {
    yield takeEvery(FilesActionTypes.GET_FILE_BY_ID, getFile);
}

export function* watchUpdateFile() {
    yield takeEvery(FilesActionTypes.UPDATE_FILE, updateFile);
}

function* fileSaga() {
    yield all([
        fork(watchCreateFile),
        fork(watchGetFiles),
        fork(watchGetFilesByUser),
        fork(watchGetFilesByListing),
        fork(watchGetFilesByOrganization),
        fork(watchGetFilesByTask),
        fork(watchGetFilesByTransaction),
        fork(watchGetFile),
        fork(watchUpdateFile),
    ]);
}

export default fileSaga;
