// @flow
import { all } from 'redux-saga/effects';

import addressSaga from './address/saga';
import addendumSaga from './addendum/saga';
import anvilSaga from './anvil/saga';
import newAnvilSaga from './new_anvil/saga';
import authSaga from './auth/saga';
import commentSaga from './comments/saga';
import layoutSaga from './layout/saga';
import transactionSaga from './transactions/saga';
import listingSaga from './listings/saga';
import propertyTypeSaga from './property_types/saga';
import propertyStyleSaga from './property_styles/saga';
import offerSaga from './offer/saga';
import disclosureSaga from './disclosures/saga';
import taskSaga from './tasks/saga';
import timelinesSaga from './timelines/saga';
import userSaga from './users/saga';
import fileSaga from './files/saga';
import messageSaga from './messages/saga';
import repairsSaga from './repairs/saga';
import extensionSaga from './extension/saga';
import rentbackSaga from './rentback/saga';
import terminationSaga from './termination/saga';
import notificationSaga from './notifications/saga';
import organizationsSaga from './organizations/saga';
import invitationsSaga from './invitations/saga';
import favoritesSaga from './favorites/saga';
import billingSaga from './billing/saga';

export default function* rootSaga(): any {
    yield all([
        addendumSaga(),
        addressSaga(),
        authSaga(),
        anvilSaga(),
        newAnvilSaga(),
        commentSaga(),
        layoutSaga(),
        transactionSaga(),
        listingSaga(),
        propertyTypeSaga(),
        propertyStyleSaga(),
        offerSaga(),
        disclosureSaga(),
        taskSaga(),
        timelinesSaga(),
        userSaga(),
        fileSaga(),
        messageSaga(),
        repairsSaga(),
        extensionSaga(),
        rentbackSaga(),
        terminationSaga(),
        notificationSaga(),
        organizationsSaga(),
        invitationsSaga(),
        favoritesSaga(),
        billingSaga(),
    ]);
}
