export const AddressActionTypes = {
    API_RESPONSE_SUCCESS: '@@address/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@address/API_RESPONSE_ERROR',

    GET_ADDRESSES: '@@address/GET_ADDRESS',
    GET_ADDRESS_BY_ID: '@@address/GET_ADDRESS_BY_ID',
    CREATE_ADDRESS: '@@address/CREATE_ADDRESS',
    UPDATE_ADDRESS: '@@address/UPDATE_ADDRESS',
    DELETE_ADDRESS: '@@address/DELETE_ADDRESS',
    RESET_ADDRESS: '@@address/RESET_ADDRESS',
};
