// @flow
import { UserActionTypes } from './constants';

import { APICore } from '../../helpers/api/apiCore';

const api = new APICore();
const user = api.getLoggedInUser();

const INIT_STATE = {
    data: user?.user,
    loading: false,
    updateSuccess: null,
    uploadSuccess: null,
};

type UserAction = { type: string, payload: { actionType?: string, data?: any, error?: string } };
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const User = (state: State = INIT_STATE, action: UserAction): any => {
    switch (action.type) {
        case UserActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case UserActionTypes.GET_USERS:
                    return {
                        ...state,
                        data: action.payload.data,
                        loading: false,
                    };
                case UserActionTypes.GET_USER_BY_ID:
                    return {
                        ...state,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: false,
                    };
                case UserActionTypes.UPDATE_USER:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                case UserActionTypes.CREATE_ATTACHMENT:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        uploadSuccess: true,
                    };
                default:
                    return { ...state };
            }

        case UserActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case UserActionTypes.GET_USERS:
                    return {
                        ...state,
                        error: action.payload.error,
                        data: null,
                        loading: false,
                    };
                case UserActionTypes.GET_USER_BY_ID:
                    return {
                        ...state,
                        error: action.payload.error,
                        data: null,
                        loading: false,
                    };
                case UserActionTypes.UPDATE_USER:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        updateSuccess: false,
                    };
                case UserActionTypes.CREATE_ATTACHMENT:
                    return { ...state, error: action.payload, loading: false, uploadSuccess: false };
                default:
                    return { ...state };
            }
        case UserActionTypes.GET_USERS:
            return { ...state, loading: true };
        case UserActionTypes.GET_USER_BY_ID:
            return { ...state, data: null, loading: true };
        case UserActionTypes.UPDATE_USER:
            return { ...state, loading: true, updateSuccess: false };
        case UserActionTypes.CREATE_ATTACHMENT:
            return { ...state, loading: true, uploadSuccess: false };
        case UserActionTypes.RESET_USERS:
            return INIT_STATE;
        default:
            return { ...state };
    }
};

export default User;
