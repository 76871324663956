// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

/**
 * Create a new offer
 * @param {*} params
 * @returns
 */
function createOffer(params: any): any {
    const baseUrl = `${config.server.api}/v1/listings/${params.listing}/offers`;
    return api.create(`${baseUrl}`, params.data);
}

/**
 * Update the details of a offer
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function updateOffer(params: any): any {
    const baseUrl = `${config.server.api}/v1/offers/${params.id}`;
    return api.updatePatch(`${baseUrl}`, params.data);
}

/**
 * Get a single offer
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getOffer(params: any): any {
    const baseUrl = `${config.server.api}/v1/offers/${params.id}`;
    return api.get(`${baseUrl}`);
}

/**
 * Get all offers for a listing
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getOffers(params: any): any {
    const baseUrl = `${config.server.api}/v1/offers`;
    return api.get(`${baseUrl}`);
}

/**
 * Submit a completed offer for a listing
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function submitOffer(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/offers/${id}/submit`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Retract a submitted offer
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function retractOffer(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/offers/${id}/retract`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Reject an offer
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function rejectOffer(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/offers/${id}/reject`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Accept an offer
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function acceptOffer(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/offers/${id}/accept`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Submit a counter-offer
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function submitCounterOffer(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/offers/${id}/counter/submit`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Get all offers submitted by a given user
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getOffersByUser(params: any): any {
    const baseUrl = `${config.server.api}/v1/users/${params.user}/offers?isBuying=${params.isBuying}&isSelling=${params.isSelling}`;
    return api.get(`${baseUrl}`);
}

/**
 * Get all offers for a listing
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getOffersByListing(params: any): any {
    const baseUrl = `${config.server.api}/v1/listings/${params.listing}/offers`;
    return api.get(`${baseUrl}`);
}

/**
 * Get access list for a single offer
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getAccessByOffer(params: any): any {
    const baseUrl = `${config.server.api}/v1/offers/${params.id}/access`;
    return api.get(`${baseUrl}`);
}

/**
 * Get access list for a single offer
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function revokeAccessByOffer(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/offers/${id}/access`;
    return api.create(`${baseUrl}`, data);
}

export {
    createOffer,
    updateOffer,
    getOffer,
    getOffers,
    getOffersByListing,
    getOffersByUser,
    retractOffer,
    submitOffer,
    acceptOffer,
    rejectOffer,
    submitCounterOffer,
    getAccessByOffer,
    revokeAccessByOffer,
};
