export const AuthActionTypes = {
    API_RESPONSE_SUCCESS: '@@auth/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@auth/API_RESPONSE_ERROR',

    LOGIN_USER: '@@auth/LOGIN_USER',
    LOGOUT_USER: '@@auth/LOGOUT_USER',
    REFRESH_TOKENS: '@@auth/REFRESH_TOKENS',
    SIGNUP_USER: '@@auth/SIGNUP_USER',
    UPDATE_USER: '@@auth/UPDATE_USER',
    FORGOT_PASSWORD: '@@auth/FORGOT_PASSWORD',
    RESET_PASSWORD: '@@auth/RESET_PASSWORD',
    CHANGE_PASSWORD: '@@auth/CHANGE_PASSWORD',
    SEND_VERIFICATION_EMAIL: '@@auth/SEND_VERIFICATION_EMAIL',
    UPDATE_PROFILE: '@@auth/UPDATE_PROFILE',
    RESET: '@@auth/RESET',
};
