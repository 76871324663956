import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { billingApiResponseSuccess, billingApiResponseError } from './actions';
import { BillingActionTypes } from './constants';
import { toggleLoading } from '../actions';
import {
    createBilling as createBillingApi,
    getBilling as getBillingApi,
    getBillingById as getBillingByIdApi,
    getUserBilling as getUserBillingApi,
    updateBilling as updateBillingApi,
    getBillingByListings as getBillingByListingApi,
    getBillingByOrganization as getBillingByOrganizationApi,
} from '../../helpers';

/**
 * Get Billing
 * @param {object} action
 * @param {string} action.payload
 */
function* getBilling({ payload: {} }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getBillingApi);
        yield put(billingApiResponseSuccess(BillingActionTypes.GET_BILLING, response.data));
    } catch (error) {
        console.log(error);
        yield put(billingApiResponseError(BillingActionTypes.GET_BILLING, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Billing by ID
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 */
function* getBillingById({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getBillingByIdApi, { id });
        yield put(billingApiResponseSuccess(BillingActionTypes.GET_BILLING_BY_ID, response.data));
    } catch (error) {
        console.log(error);
        yield put(billingApiResponseError(BillingActionTypes.GET_BILLING_BY_ID, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Billing by User
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.user
 */
function* getUserBilling({ payload: { user } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getUserBillingApi, { user });
        yield put(billingApiResponseSuccess(BillingActionTypes.GET_BILLING_BY_USER, response.data));
    } catch (error) {
        console.log(error);
        yield put(billingApiResponseError(BillingActionTypes.GET_BILLING_BY_USER, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Billing by Listing
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.user
 */
function* getListingBilling({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getBillingByListingApi, { id });
        yield put(billingApiResponseSuccess(BillingActionTypes.GET_BILLING_BY_LISTING, response.data));
    } catch (error) {
        console.log(error);
        yield put(billingApiResponseError(BillingActionTypes.GET_BILLING_BY_LISTING, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Billing by Organization
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.user
 */
function* getOrganizationBilling({ payload: { slug } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getBillingByOrganizationApi, { slug });
        yield put(billingApiResponseSuccess(BillingActionTypes.GET_BILLING_BY_ORGANIZATION, response.data));
    } catch (error) {
        console.log(error);
        yield put(billingApiResponseError(BillingActionTypes.GET_BILLING_BY_ORGANIZATION, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Update a Billing
 * @param {object} action
 * @param {string} action.payload
 * @param {object} action.payload.data
 */
function* createBilling({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(createBillingApi, { id, data });
        yield put(billingApiResponseSuccess(BillingActionTypes.CREATE_BILLING, response.data));
    } catch (error) {
        console.log(error);
        yield put(billingApiResponseError(BillingActionTypes.CREATE_BILLING, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Update a Billing
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* updateBilling({ payload: { id, data } }) {
    try {
        const response = yield call(updateBillingApi, { id, data });
        yield put(billingApiResponseSuccess(BillingActionTypes.UPDATE_BILLING, response.data));
    } catch (error) {
        console.log(error);
        yield put(billingApiResponseError(BillingActionTypes.UPDATE_BILLING, error));
    }
    yield put(toggleLoading(false));
}

export function* watchGetBilling() {
    yield takeEvery(BillingActionTypes.GET_BILLING, getBilling);
}

export function* watchGetBillingById() {
    yield takeEvery(BillingActionTypes.GET_BILLING_BY_ID, getBillingById);
}

export function* watchGetUserBilling() {
    yield takeEvery(BillingActionTypes.GET_BILLING_BY_USER, getUserBilling);
}

export function* watchGetListingBilling() {
    yield takeEvery(BillingActionTypes.GET_BILLING_BY_LISTING, getListingBilling);
}

export function* watchGetOrganizationBilling() {
    yield takeEvery(BillingActionTypes.GET_BILLING_BY_ORGANIZATION, getOrganizationBilling);
}

export function* watchCreateBilling() {
    yield takeEvery(BillingActionTypes.CREATE_BILLING, createBilling);
}

export function* watchUpdateBilling() {
    yield takeEvery(BillingActionTypes.UPDATE_BILLING, updateBilling);
}

function* billingSaga() {
    yield all([
        fork(watchGetBilling),
        fork(watchGetBillingById),
        fork(watchGetUserBilling),
        fork(watchGetListingBilling),
        fork(watchUpdateBilling),
        fork(watchCreateBilling),
        fork(watchGetOrganizationBilling),
    ]);
}

export default billingSaga;
