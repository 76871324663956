import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { addendumApiResponseSuccess, addendumApiResponseError } from './actions';
import { AddendumActionTypes } from './constants';
import { toggleLoading } from '../actions';
import {
    confirmAddendum as confirmAddendumApi,
    retractAddendum as retractAddendumApi,
    submitAddendum as submitAddendumApi,
    getAddendum as getAddendumApi,
    submitCounterAddendum as submitCounterAddendumApi,
} from '../../helpers';

/**
 * Get Addendum by ID
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 */
function* getAddendum({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getAddendumApi, { id });
        yield put(addendumApiResponseSuccess(AddendumActionTypes.GET_ADDENDUM, response.data));
    } catch (error) {
        console.log(error);
        yield put(addendumApiResponseError(AddendumActionTypes.GET_ADDENDUM, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Extend a Addendum
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* submitAddendum({ payload: { id, data } }) {
    try {
        const response = yield call(submitAddendumApi, { id, data });
        yield put(addendumApiResponseSuccess(AddendumActionTypes.SUBMIT_ADDENDUM, response.data));
    } catch (error) {
        console.log(error);
        yield put(addendumApiResponseError(AddendumActionTypes.SUBMIT_ADDENDUM, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Confirm a Addendum
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* confirmAddendum({ payload: { id, data } }) {
    try {
        const response = yield call(confirmAddendumApi, { id, data });
        yield put(addendumApiResponseSuccess(AddendumActionTypes.CONFIRM_ADDENDUM, response.data));
    } catch (error) {
        console.log(error);
        yield put(addendumApiResponseError(AddendumActionTypes.CONFIRM_ADDENDUM, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Retract a Addendum
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* retractAddendum({ payload: { id, data } }) {
    try {
        const response = yield call(retractAddendumApi, { id, data });
        yield put(addendumApiResponseSuccess(AddendumActionTypes.RETRACT_ADDENDUM, response.data));
    } catch (error) {
        console.log(error);
        yield put(addendumApiResponseError(AddendumActionTypes.RETRACT_ADDENDUM, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Submit a Counter Addendum
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* submitCounterAddendum({ payload: { id, data } }) {
    try {
        const response = yield call(submitCounterAddendumApi, { id, data });
        yield put(addendumApiResponseSuccess(AddendumActionTypes.SUBMIT_COUNTER_ADDENDUM, response.data));
    } catch (error) {
        console.log(error);
        yield put(addendumApiResponseError(AddendumActionTypes.SUBMIT_COUNTER_ADDENDUM, error));
    }
    yield put(toggleLoading(false));
}

export function* watchGetAddendum() {
    yield takeEvery(AddendumActionTypes.GET_ADDENDUM, getAddendum);
}
export function* watchSubmitAddendum() {
    yield takeEvery(AddendumActionTypes.SUBMIT_ADDENDUM, submitAddendum);
}
export function* watchConfirmAddendum() {
    yield takeEvery(AddendumActionTypes.CONFIRM_ADDENDUM, confirmAddendum);
}
export function* watchRetractAddendum() {
    yield takeEvery(AddendumActionTypes.RETRACT_ADDENDUM, retractAddendum);
}

export function* watchSubmitCounterAddendum() {
    yield takeEvery(AddendumActionTypes.SUBMIT_COUNTER_ADDENDUM, submitCounterAddendum);
}

function* addendumSaga() {
    yield all([
        fork(watchGetAddendum),
        fork(watchSubmitAddendum),
        fork(watchConfirmAddendum),
        fork(watchRetractAddendum),
        fork(watchSubmitCounterAddendum),
    ]);
}

export default addendumSaga;
