import { DisclosuresActionTypes } from './constants';

const INIT_STATE = {
    data: null,
    loading: false,
    error: null,
    updateSuccess: null,
    submitSuccess: null,
    deleteSuccess: null,
};

const Disclosures = (state = INIT_STATE, action) => {
    switch (action.type) {
        case DisclosuresActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case DisclosuresActionTypes.GET_DISCLOSURES:
                    return { ...state, data: action.payload.data, loading: false };
                case DisclosuresActionTypes.GET_DISCLOSURE_BY_ID:
                    return { ...state, data: action.payload.data, loading: false };
                case DisclosuresActionTypes.GET_DISCLOSURES_BY_USER:
                    return { ...state, data: action.payload.data, loading: false };
                case DisclosuresActionTypes.GET_DISCLOSURES_BY_LISTING:
                    return { ...state, data: action.payload.data, loading: false };
                case DisclosuresActionTypes.CREATE_DISCLOSURE:
                    return {
                        ...state,
                        listing: action.payload.listing,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                case DisclosuresActionTypes.UPDATE_DISCLOSURE:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                case DisclosuresActionTypes.DELETE_DISCLOSURE:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        deleteSuccess: true,
                    };
                case DisclosuresActionTypes.SUBMIT_DISCLOSURES:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        submitSuccess: true,
                    };
                case DisclosuresActionTypes.CONFIRM_DISCLOSURES:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        submitSuccess: true,
                    };
                case DisclosuresActionTypes.REJECT_DISCLOSURES:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        rejectSuccess: true,
                    };
                default:
                    return { ...state };
            }
        case DisclosuresActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case DisclosuresActionTypes.GET_DISCLOSURES:
                    return { ...state, error: action.payload, loading: false };
                case DisclosuresActionTypes.GET_DISCLOSURE_BY_ID:
                    return { ...state, error: action.payload, loading: false };
                case DisclosuresActionTypes.GET_DISCLOSURES_BY_USER:
                    return { ...state, error: action.payload, loading: false };
                case DisclosuresActionTypes.GET_DISCLOSURES_BY_LISTING:
                    return { ...state, error: action.payload, loading: false };
                case DisclosuresActionTypes.CREATE_DISCLOSURE:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case DisclosuresActionTypes.UPDATE_DISCLOSURE:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case DisclosuresActionTypes.DELETE_DISCLOSURE:
                    return { ...state, error: action.payload, loading: false, deleteeSuccess: false };
                case DisclosuresActionTypes.SUBMIT_DISCLOSURES:
                    return { ...state, error: action.payload, loading: false, submitSuccess: false };
                case DisclosuresActionTypes.CONFIRM_DISCLOSURES:
                    return { ...state, error: action.payload, loading: false, submitSuccess: false };
                case DisclosuresActionTypes.REJECT_DISCLOSURES:
                    return { ...state, error: action.payload, loading: false, rejectSuccess: false };
                default:
                    return { ...state };
            }
        case DisclosuresActionTypes.GET_DISCLOSURES:
            return { ...state, loading: true };
        case DisclosuresActionTypes.GET_DISCLOSURE_BY_ID:
            return { ...state, data: null, loading: true };
        case DisclosuresActionTypes.GET_DISCLOSURES_BY_USER:
            return { ...state, data: null, loading: true };
        case DisclosuresActionTypes.GET_DISCLOSURES_BY_LISTING:
            return { ...state, data: null, loading: true };
        case DisclosuresActionTypes.CREATE_DISCLOSURE:
            return { ...state, data: null, loading: true };
        case DisclosuresActionTypes.UPDATE_DISCLOSURE:
            return { ...state, data: null, loading: true };
        case DisclosuresActionTypes.DELETE_DISCLOSURE:
            return { ...state, data: null, loading: true };
        case DisclosuresActionTypes.SUBMIT_DISCLOSURES:
            return { ...state, id: action.payload.id, data: action.payload.data, loading: true };
        case DisclosuresActionTypes.CONFIRM_DISCLOSURES:
            return { ...state, id: action.payload.id, data: action.payload.data, loading: true };
        case DisclosuresActionTypes.REJECT_DISCLOSURES:
            return { ...state, id: action.payload.id, data: action.payload.data, loading: true };
        case DisclosuresActionTypes.RESET_DISCLOSURES:
            return INIT_STATE;
        default:
            return { ...state, updateSuccess: null, submitSuccess: null, deleteSuccess: null };
    }
};

export default Disclosures;
