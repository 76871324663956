export const DisclosuresActionTypes = {
    API_RESPONSE_SUCCESS: '@@disclosures/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@disclosures/API_RESPONSE_ERROR',

    GET_DISCLOSURES: '@@disclosures/GET_DISCLOSURES',
    GET_DISCLOSURE_BY_ID: '@@disclosures/GET_DISCLOSURE_BY_ID',
    GET_DISCLOSURES_BY_USER: '@@disclosures/GET_DISCLOSURES_BY_USER',
    GET_DISCLOSURES_BY_LISTING: '@@disclosures/GET_DISCLOSURES_BY_LISTING',
    CREATE_DISCLOSURE: '@@disclosures/CREATE_DISCLOSURE',
    UPDATE_DISCLOSURE: '@@disclosures/UPDATE_DISCLOSURE',
    SUBMIT_DISCLOSURES: '@@disclosures/SUBMIT_DISCLOSURES',
    CONFIRM_DISCLOSURES: '@@disclosures/CONFIRM_DISCLOSURES',
    DELETE_DISCLOSURE: '@@disclosures/DELETE_DISCLOSURE',
    RESET_DISCLOSURES: '@@disclosures/RESET_DISCLOSURES',
    REJECT_DISCLOSURES: '@@disclosures/REJECT_DISCLOSURES',
};
