// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

/**
 * Create Termination Addendum
 * @param {*} transaction - The transaction ID to use
 * @param {*} data - The form data to send
 * @returns
 */
function submitTermination(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/transactions/${id}/termination/submit`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Complete a termination
 * @param {*} transaction - The transaction ID to extend
 * @param {*} data - The form data to send
 * @returns
 */
function confirmTermination(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/termination/${id}/confirm`;
    return api.create(`${baseUrl}`, data);
}

/**
 * Retract a termination
 * @param {*} transaction - The transaction ID to extend
 * @param {*} data - The form data to send
 * @returns
 */
function retractTermination(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/termination/${id}/retract`;
    return api.create(`${baseUrl}`, data);
}

/**
 * get a termination
 * @param {*} transaction - The transaction ID to extend
 * @param {*} data - The form data to send
 * @returns
 */
function getTermination(params: any): any {
    const { id } = params;
    const baseUrl = `${config.server.api}/v1/termination/${id}`;
    return api.get(`${baseUrl}`);
}

export { submitTermination, confirmTermination, retractTermination, getTermination };
