export const FavoritesActionTypes = {
    API_RESPONSE_SUCCESS: '@@favorites/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@favorites/API_RESPONSE_ERROR',
    // Favorites
    GET_FAVORITES: '@@favorites/GET_FAVORITES',
    GET_FAVORITE_BY_ID: '@@favorites/GET_FAVORITE_BY_ID',
    CREATE_FAVORITE: '@@favorites/CREATE_FAVORITE',
    UPDATE_FAVORITE: '@@favorites/UPDATE_FAVORITE',
    DELETE_FAVORITE: '@@favorites/DELETE_FAVORITE',
    ADD_FAVORITE_TO_USER: '@@favorites/ADD_FAVORITE_TO_USER',
    REMOVE_FAVORITE_TO_USER: '@@favorites/REMOVE_FAVORITE_TO_USER',
    RESET_FAVORITES: '@@favorites/RESET_FAVORITES',
    RESET_FAVORITE: '@@favorites/RESET_FAVORITE',
};
