import { ModalActionTypes } from './constants';

const INIT_STATE = {
    id: null,
};

const Modal = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ModalActionTypes.ACTIVE_MODAL: {
            return { ...state, id: action.payload.id };
        }
        case ModalActionTypes.RESET_MODAL:
            return INIT_STATE;
        default:
            return { ...state };
    }
};

export default Modal;
