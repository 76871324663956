import { PropertyTypeActionTypes } from './constants';

const INIT_STATE = {
    data: null,
    loading: false,
    error: null,
    updateSuccess: null,
};

const PropertyType = (state = INIT_STATE, action) => {
    switch (action.type) {
        case PropertyTypeActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case PropertyTypeActionTypes.GET_PROPERTY_TYPES: {
                    return { ...state, data: action.payload.data.results, loading: false };
                }
                case PropertyTypeActionTypes.GET_PROPERTY_TYPE_BY_ID: {
                    return { ...state, data: action.payload.data, loading: false };
                }
                case PropertyTypeActionTypes.CREATE_PROPERTY_TYPE: {
                    return { ...state, data: action.payload.data, loading: false, updateSuccess: true };
                }
                case PropertyTypeActionTypes.UPDATE_PROPERTY_TYPE: {
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                }
                default:
                    return { ...state };
            }
        case PropertyTypeActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case PropertyTypeActionTypes.GET_PROPERTY_TYPES: {
                    return { ...state, error: action.payload, loading: false };
                }
                case PropertyTypeActionTypes.GET_PROPERTY_TYPE_BY_ID: {
                    return { ...state, error: action.payload, loading: false };
                }
                case PropertyTypeActionTypes.CREATE_PROPERTY_TYPE: {
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                }
                case PropertyTypeActionTypes.UPDATE_PROPERTY_TYPE: {
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                }
                default:
                    return { ...state };
            }
        case PropertyTypeActionTypes.GET_PROPERTY_TYPES:
            return { ...state, loading: true };
        case PropertyTypeActionTypes.GET_PROPERTY_TYPE_BY_ID:
            return { ...state, data: null, loading: true };
        case PropertyTypeActionTypes.CREATE_PROPERTY_TYPE:
            return {
                ...state,
                data: action.payload.data,
                loading: true,
            };
        case PropertyTypeActionTypes.UPDATE_PROPERTY_TYPE: {
            return {
                ...state,
                id: action.payload.id,
                data: action.payload.data,
                loading: false,
            };
        }
        default:
            return { ...state, updateSuccess: null };
    }
};

export default PropertyType;
