// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

/**
 * Create a new message
 * @param {*} params
 * @returns
 */
function createMessage(params: any): any {
    const { parent, id, data } = params;
    var baseUrl;
    if (parent && id) {
        baseUrl = `${config.server.api}/v1/${parent}/${id}/messages`;
    } else {
        baseUrl = `${config.server.api}/v1/messages`;
    }
    return api.create(`${baseUrl}`, data);
}

/**
 * Update the details of a message
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function updateMessage(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/messages/${id}`;
    return api.updatePatch(`${baseUrl}`, data);
}

/**
 * Get a single message
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getMessage(params: any): any {
    const { id } = params;
    const baseUrl = `${config.server.api}/v1/messages/${id}`;
    return api.get(`${baseUrl}`);
}

/**
 * Get all messages for a listing
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getMessages(params: any): any {
    const { parent, id } = params;
    var baseUrl;
    if (parent && id) {
        baseUrl = `${config.server.api}/v1/${parent}/${id}/messages`;
    } else {
        baseUrl = `${config.server.api}/v1/messages`;
    }
    return api.get(`${baseUrl}`);
}

/**
 * Get all messages submitted by a given user
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getMessagesByUser(params: any): any {
    const { user } = params;
    const baseUrl = `${config.server.api}/v1/users/${user}/messages`;
    return api.get(`${baseUrl}`);
}

/**
 * Get all messages for a listing
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getMessagesByListing(params: any): any {
    const { listing } = params;
    const baseUrl = `${config.server.api}/v1/listings/${listing}/messages`;
    return api.get(`${baseUrl}`);
}

/**
 * Get all messages for a listing
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getMessagesByTask(params: any): any {
    const { task } = params;
    const baseUrl = `${config.server.api}/v1/tasks/${task}/messages`;
    return api.get(`${baseUrl}`);
}

/**
 * Get all messages for a listing
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getMessagesByTransaction(params: any): any {
    const { transaction } = params;
    const baseUrl = `${config.server.api}/v1/transactions/${transaction}/messages`;
    return api.get(`${baseUrl}`);
}

/**
 * Create a new comment on a message
 * @param {*} params
 * @returns
 */
function createMessageAttachment(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/messages/${id}/attachments`;
    return api.create(`${baseUrl}`, data);
}

export {
    createMessage,
    updateMessage,
    getMessage,
    getMessages,
    getMessagesByListing,
    getMessagesByTask,
    getMessagesByTransaction,
    getMessagesByUser,
    createMessageAttachment,
};
