import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { timelineApiResponseSuccess, timelineApiResponseError } from './actions';
import { TimelinesActionTypes } from './constants';
import { toggleLoading } from '../actions';
import {
    createTimeline as createTimelineApi,
    getTimeline as getTimelineApi,
    getTimelines as getTimelinesApi,
    getUserTimelines as getUserTimelinesApi,
    getTimelinesByTransaction as getTimelinesByTransactionApi,
    updateTimeline as updateTimelineApi,
} from '../../helpers';

/**
 * Get Timelines
 * @param {object} action
 * @param {string} action.payload
 */
function* getTimelines({ payload: {} }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getTimelinesApi);
        yield put(timelineApiResponseSuccess(TimelinesActionTypes.GET_TIMELINES, response.data));
    } catch (error) {
        console.log(error);
        yield put(timelineApiResponseError(TimelinesActionTypes.GET_TIMELINES, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Timeline by ID
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 */
function* getTimeline({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getTimelineApi, { id });
        yield put(timelineApiResponseSuccess(TimelinesActionTypes.GET_TIMELINE_BY_ID, response.data));
    } catch (error) {
        console.log(error);
        yield put(timelineApiResponseError(TimelinesActionTypes.GET_TIMELINE_BY_ID, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Timelines by User
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.user
 */
function* getUserTimelines({ payload: { user } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getUserTimelinesApi, { user });
        yield put(timelineApiResponseSuccess(TimelinesActionTypes.GET_TIMELINES_BY_USER, response.data));
    } catch (error) {
        console.log(error);
        yield put(timelineApiResponseError(TimelinesActionTypes.GET_TIMELINES_BY_USER, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Timelines by Transaction
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.user
 */
function* getTimelinesByTransaction({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getTimelinesByTransactionApi, { id });
        yield put(timelineApiResponseSuccess(TimelinesActionTypes.GET_TIMELINES_BY_TRANSACTION, response.data));
    } catch (error) {
        console.log(error);
        yield put(timelineApiResponseError(TimelinesActionTypes.GET_TIMELINES_BY_TRANSACTION, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Update a Timeline
 * @param {object} action
 * @param {string} action.payload
 * @param {object} action.payload.data
 */
function* createTimeline({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(createTimelineApi, { id, data });
        yield put(timelineApiResponseSuccess(TimelinesActionTypes.CREATE_TIMELINE, response.data));
    } catch (error) {
        console.log(error);
        yield put(timelineApiResponseError(TimelinesActionTypes.CREATE_TIMELINE, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Update a Timeline
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* updateTimeline({ payload: { id, data } }) {
    try {
        const response = yield call(updateTimelineApi, { id, data });
        yield put(timelineApiResponseSuccess(TimelinesActionTypes.UPDATE_TIMELINE, response.data));
    } catch (error) {
        console.log(error);
        yield put(timelineApiResponseError(TimelinesActionTypes.UPDATE_TIMELINE, error));
    }
    yield put(toggleLoading(false));
}

export function* watchGetTimelines() {
    yield takeEvery(TimelinesActionTypes.GET_TIMELINES, getTimelines);
}

export function* watchGetTimeline() {
    yield takeEvery(TimelinesActionTypes.GET_TIMELINE_BY_ID, getTimeline);
}

export function* watchGetUserTimelines() {
    yield takeEvery(TimelinesActionTypes.GET_TIMELINES_BY_USER, getUserTimelines);
}

export function* watchGetTimelinesByTransaction() {
    yield takeEvery(TimelinesActionTypes.GET_TIMELINES_BY_TRANSACTION, getTimelinesByTransaction);
}

export function* watchCreateTimeline() {
    yield takeEvery(TimelinesActionTypes.CREATE_TIMELINE, createTimeline);
}

export function* watchUpdateTimeline() {
    yield takeEvery(TimelinesActionTypes.UPDATE_TIMELINE, updateTimeline);
}

function* timelineSaga() {
    yield all([
        fork(watchGetTimelines),
        fork(watchGetTimeline),
        fork(watchGetUserTimelines),
        fork(watchGetTimelinesByTransaction),
        fork(watchUpdateTimeline),
        fork(watchCreateTimeline),
    ]);
}

export default timelineSaga;
