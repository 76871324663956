export const PropertyStyleActionTypes = {
    API_RESPONSE_SUCCESS: '@@propertystyle/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@propertystyle/API_RESPONSE_ERROR',

    GET_PROPERTY_STYLES: '@@propertystyle/GET_PROPERTY_STYLES',
    GET_PROPERTY_STYLE_BY_ID: '@@propertystyle/GET_PROPERTY_STYLE_BY_ID',
    CREATE_PROPERTY_STYLE: '@@propertystyle/CREATE_PROPERTY_STYLE',
    UPDATE_PROPERTY_STYLE: '@@propertystyle/UPDATE_PROPERTY_STYLE',
    DELETE_PROPERTY_STYLE: '@@propertystyle/DELETE_PROPERTY_STYLE',
};
