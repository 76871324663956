// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

/**
 * Create a new address
 * @param {*} params
 * @returns
 */
function createAddress(params: any): any {
    const baseUrl = `${config.server.api}/v1/address`;
    return api.create(`${baseUrl}`, params.data);
}

/**
 * Update the details of a address
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function updateAddress(params: any): any {
    const baseUrl = `${config.server.api}/v1/address/${params.id}`;
    return api.updatePatch(`${baseUrl}`, params.data);
}

/**
 * Get a single address
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getAddress(params: any): any {
    const baseUrl = `${config.server.api}/v1/address/${params.id}`;
    return api.get(`${baseUrl}`);
}

/**
 * Get all addresses
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getAddresses(params: any): any {
    const baseUrl = `${config.server.api}/v1/address/`;
    return api.get(`${baseUrl}`);
}

/**
 * Get address for a user
 * @param {*} params
 * @returns
 */
function deleteAddress(params: any): any {
    const baseUrl = `${config.server.api}/v1/address/${params.id}`;
    return api.get(`${baseUrl}`);
}

export { createAddress, updateAddress, getAddress, getAddresses, deleteAddress };
