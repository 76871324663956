import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { rentbackApiResponseSuccess, rentbackApiResponseError } from './actions';
import { RentbackActionTypes } from './constants';
import { toggleLoading } from '../actions';
import {
    confirmRentback as confirmRentbackApi,
    retractRentback as retractRentbackApi,
    submitRentback as submitRentbackApi,
    getRentback as getRentbackApi,
} from '../../helpers';

/**
 * Get Rentback by ID
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 */
function* getRentback({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getRentbackApi, { id });
        yield put(rentbackApiResponseSuccess(RentbackActionTypes.GET_RENTBACK, response.data));
    } catch (error) {
        console.log(error);
        yield put(rentbackApiResponseError(RentbackActionTypes.GET_RENTBACK, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Extend a Rentback
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* submitRentback({ payload: { id, data } }) {
    try {
        const response = yield call(submitRentbackApi, { id, data });
        yield put(rentbackApiResponseSuccess(RentbackActionTypes.SUBMIT_RENTBACK, response.data));
    } catch (error) {
        console.log(error);
        yield put(rentbackApiResponseError(RentbackActionTypes.SUBMIT_RENTBACK, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Confirm a Rentback
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* confirmRentback({ payload: { id, data } }) {
    try {
        const response = yield call(confirmRentbackApi, { id, data });
        yield put(rentbackApiResponseSuccess(RentbackActionTypes.CONFIRM_RENTBACK, response.data));
    } catch (error) {
        console.log(error);
        yield put(rentbackApiResponseError(RentbackActionTypes.CONFIRM_RENTBACK, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Retract a Rentback
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* retractRentback({ payload: { id, data } }) {
    try {
        const response = yield call(retractRentbackApi, { id, data });
        yield put(rentbackApiResponseSuccess(RentbackActionTypes.RETRACT_RENTBACK, response.data));
    } catch (error) {
        console.log(error);
        yield put(rentbackApiResponseError(RentbackActionTypes.RETRACT_RENTBACK, error));
    }
    yield put(toggleLoading(false));
}

export function* watchGetRentback() {
    yield takeEvery(RentbackActionTypes.GET_RENTBACK, getRentback);
}
export function* watchSubmitRentback() {
    yield takeEvery(RentbackActionTypes.SUBMIT_RENTBACK, submitRentback);
}
export function* watchConfirmRentback() {
    yield takeEvery(RentbackActionTypes.CONFIRM_RENTBACK, confirmRentback);
}
export function* watchRetractRentback() {
    yield takeEvery(RentbackActionTypes.RETRACT_RENTBACK, retractRentback);
}

function* rentbackSaga() {
    yield all([
        fork(watchGetRentback),
        fork(watchSubmitRentback),
        fork(watchConfirmRentback),
        fork(watchRetractRentback),
    ]);
}

export default rentbackSaga;
