import { TimelinesActionTypes } from './constants';

type TimelinesAction = { type: string, payload: {} | string };

// common success
export const timelineApiResponseSuccess = (actionType: string, data: any): TimelinesAction => ({
    type: TimelinesActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const timelineApiResponseError = (actionType: string, error: string): TimelinesAction => ({
    type: TimelinesActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getTimelines = (): TimelinesAction => ({
    type: TimelinesActionTypes.GET_TIMELINES,
});

export const getTimelineById = (id: string): TimelinesAction => ({
    type: TimelinesActionTypes.GET_TIMELINE_BY_ID,
    payload: { id },
});

export const getTimelinesByUser = (user: string): TimelinesAction => ({
    type: TimelinesActionTypes.GET_TIMELINES_BY_USER,
    payload: { user },
});

export const getTimelinesByTransaction = (id: string): TimelinesAction => ({
    type: TimelinesActionTypes.GET_TIMELINES_BY_TRANSACTION,
    payload: { id },
});

export const createTimeline = (id: string, data: object): TimelinesAction => ({
    type: TimelinesActionTypes.CREATE_TIMELINE,
    payload: { id, data },
});

export const updateTimeline = (id: string, data: object): TimelinesAction => ({
    type: TimelinesActionTypes.UPDATE_TIMELINE,
    payload: { id, data },
});

export const deleteTimeline = (id: string): TimelinesAction => ({
    type: TimelinesActionTypes.DELETE_TIMELINE,
    payload: { id },
});

export const archiveTimeline = (id: string): TimelinesAction => ({
    type: TimelinesActionTypes.ARCHIVE_TIMELINE,
    payload: { id },
});

export const resetTimelines = (): TimelinesAction => ({
    type: TimelinesActionTypes.RESET_TIMELINES,
});

export const resetTimeline = (): TimelinesAction => ({
    type: TimelinesActionTypes.RESET_TIMELINE,
});
