import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { anvilApiResponseSuccess, anvilApiResponseError } from './actions';
import { AnvilActionTypes } from './constants';
import { toggleLoading } from '../actions';
import { reviewPDF as reviewPDFApi, signEtchPacket as signEtchPacketApi } from '../../helpers';

/**
 * Review an Anvil PDF
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* reviewPDF({ payload: { parent, id, document, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(reviewPDFApi, { parent, id, document, data });
        yield put(anvilApiResponseSuccess(AnvilActionTypes.REVIEW_PDF, response.data));
    } catch (error) {
        console.log(error);
        yield put(anvilApiResponseError(AnvilActionTypes.REVIEW_PDF, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Sign an Anvil eSignature packet
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* signEtchPacket({ payload: { parent, id, document, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(signEtchPacketApi, { parent, id, document, data });
        yield put(anvilApiResponseSuccess(AnvilActionTypes.SIGN_ETCH_PACKET, response.data));
    } catch (error) {
        console.log(error);
        yield put(anvilApiResponseError(AnvilActionTypes.SIGN_ETCH_PACKET, error));
    }
    yield put(toggleLoading(false));
}

export function* watchReviewPDF() {
    yield takeEvery(AnvilActionTypes.REVIEW_PDF, reviewPDF);
}

export function* watchSignEtchPacket() {
    yield takeEvery(AnvilActionTypes.SIGN_ETCH_PACKET, signEtchPacket);
}

function* anvilSaga() {
    yield all([fork(watchReviewPDF), fork(watchSignEtchPacket)]);
}

export default anvilSaga;
