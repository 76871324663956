import { BillingActionTypes } from './constants';

const INIT_STATE = {
    data: null,
    loading: false,
    error: null,
    updateSuccess: null,
};

const Billing = (state = INIT_STATE, action) => {
    switch (action.type) {
        case BillingActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case BillingActionTypes.GET_BILLING:
                    return { ...state, data: action.payload.data, loading: false };
                case BillingActionTypes.GET_BILLING_BY_ID:
                    return { ...state, data: action.payload.data, loading: false };
                case BillingActionTypes.GET_BILLING_BY_USER:
                    return { ...state, data: action.payload.data, loading: false };
                case BillingActionTypes.GET_BILLING_BY_LISTING:
                    return { ...state, data: action.payload.data, loading: false };
                case BillingActionTypes.GET_BILLING_BY_ORGANIZATION:
                    return { ...state, data: action.payload.data, loading: false };
                case BillingActionTypes.CREATE_BILLING:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                case BillingActionTypes.UPDATE_BILLING:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                case BillingActionTypes.DELETE_BILLING:
                    return { ...state, data: action.payload.data, loading: false };
                default:
                    return { ...state };
            }
        case BillingActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case BillingActionTypes.GET_BILLING:
                    return { ...state, error: action.payload, loading: false };
                case BillingActionTypes.GET_BILLING_BY_ID:
                    return { ...state, error: action.payload, loading: false };
                case BillingActionTypes.GET_BILLING_BY_USER:
                    return { ...state, error: action.payload, loading: false };
                case BillingActionTypes.GET_BILLING_BY_LISTING:
                    return { ...state, error: action.payload, loading: false };
                case BillingActionTypes.GET_BILLING_BY_ORGANIZATION:
                    return { ...state, error: action.payload, loading: false };
                case BillingActionTypes.CREATE_BILLING:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case BillingActionTypes.UPDATE_BILLING:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case BillingActionTypes.DELETE_BILLING:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                default:
                    return { ...state };
            }
        // Get a single billing object
        case BillingActionTypes.GET_BILLING:
            return { ...state, data: null, loading: true };
        case BillingActionTypes.GET_BILLING_BY_ID:
            return { ...state, data: null, loading: true };
        case BillingActionTypes.GET_BILLING_BY_USER:
            return { ...state, data: null, loading: true };
        case BillingActionTypes.GET_BILLING_BY_LISTING:
            return { ...state, data: null, loading: true };
        case BillingActionTypes.GET_BILLING_BY_ORGANIZATION:
            return { ...state, data: null, loading: true };
        // Update a billing object
        case BillingActionTypes.UPDATE_BILLING:
            return { ...state, id: action.payload.id, data: action.payload.data, loading: true };
        case BillingActionTypes.RESET_BILLING:
            return INIT_STATE;
        default:
            return { ...state };
    }
};

export default Billing;
