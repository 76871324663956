export const OrganizationsActionTypes = {
    API_RESPONSE_SUCCESS: '@@organizations/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@organizations/API_RESPONSE_ERROR',
    // Organizations
    GET_ORGANIZATIONS: '@@organizations/GET_ORGANIZATIONS',
    GET_ORGANIZATION_BY_SLUG: '@@organizations/GET_ORGANIZATION_BY_SLUG',
    GET_ORGANIZATIONS_BY_USER: '@@organizations/GET_ORGANIZATIONS_BY_USER',
    CREATE_ORGANIZATION: '@@organizations/CREATE_ORGANIZATION',
    UPDATE_ORGANIZATION: '@@organizations/UPDATE_ORGANIZATION',
    DELETE_ORGANIZATION: '@@organizations/DELETE_ORGANIZATION',
    // Org Members
    GET_ORGANIZATION_MEMBERS_BY_SLUG: '@@organizations/GET_ORGANIZATION_MEMBERS_BY_SLUG',
    CREATE_ORGANIZATION_MEMBER: '@@organizations/CREATE_ORGANIZATION_MEMBER',
    UPDATE_ORGANIZATION_MEMBER: '@@organizations/UPDATE_ORGANIZATION_MEMBER',
    REMOVE_ORGANIZATION_MEMBER: '@@organizations/REMOVE_ORGANIZATION_MEMBER',
    // Org Settings
    GET_ORGANIZATION_SETTINGS: '@@organizations/GET_ORGANIZATION_SETTINGS',
    UPDATE_ORGANIZATION_SETTINGS: '@@organizations/UPDATE_ORGANIZATION_SETTINGS',

    // Reset
    RESET_ORGANIZATIONS: '@@organizations/RESET_ORGANIZATIONS',
    RESET_ORGANIZATION: '@@organizations/RESET_ORGANIZATION',
    RESET_ORGANIZATION_SETTINGS: '@@organizations/RESET_ORGANIZATION_SETTINGS',
};
