// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

/**
 * Create a new property
 * @param {*} params
 * @returns
 */
function createPropertyStyle(params: any): any {
    const baseUrl = `${config.server.api}/v1/property/style`;
    return api.create(`${baseUrl}`, params);
}

/**
 * Update the details of a property
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function updatePropertyStyle(params: any): any {
    const baseUrl = `${config.server.api}/v1/property/style/${params.id}`;
    return api.updatePatch(`${baseUrl}`, params.data);
}

/**
 * Get a single property
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getPropertyStyle(params: any): any {
    const baseUrl = `${config.server.api}/v1/property/style/${params.id}`;
    return api.get(`${baseUrl}`);
}

/**
 * Get propertys for a user
 * @param {*} params
 * @returns
 */
function getPropertyStyles(params: any): any {
    const baseUrl = `${config.server.api}/v1/property/style`;
    return api.get(`${baseUrl}`);
}

export { createPropertyStyle, updatePropertyStyle, getPropertyStyle, getPropertyStyles };
