import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { notificationApiResponseSuccess, notificationApiResponseError } from './actions';
import { NotificationsActionTypes } from './constants';
import { toggleLoading } from '../actions';
import {
    createNotification as createNotificationApi,
    getNotification as getNotificationApi,
    getNotifications as getNotificationsApi,
    getUserNotifications as getUserNotificationsApi,
    updateNotification as updateNotificationApi,
    dismissNotification as dismissNotificationApi,
    acknowledgeNotification as acknowledgeNotificationApi,
    getNotificationsByTransaction as getNotificationsByTransactionApi,
} from '../../helpers';

/**
 * Get Notifications
 * @param {object} action
 * @param {string} action.payload
 */
function* getNotifications({ payload: {} }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getNotificationsApi);
        yield put(notificationApiResponseSuccess(NotificationsActionTypes.GET_NOTIFICATIONS, response.data));
    } catch (error) {
        console.log(error);
        yield put(notificationApiResponseError(NotificationsActionTypes.GET_NOTIFICATIONS, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Notification by ID
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 */
function* getNotification({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getNotificationApi, { id });
        yield put(notificationApiResponseSuccess(NotificationsActionTypes.GET_NOTIFICATION_BY_ID, response.data));
    } catch (error) {
        console.log(error);
        yield put(notificationApiResponseError(NotificationsActionTypes.GET_NOTIFICATION_BY_ID, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Notifications by User
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.user
 */
function* getUserNotifications({ payload: { user } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getUserNotificationsApi, { user });
        yield put(notificationApiResponseSuccess(NotificationsActionTypes.GET_NOTIFICATIONS_BY_USER, response.data));
    } catch (error) {
        console.log(error);
        yield put(notificationApiResponseError(NotificationsActionTypes.GET_NOTIFICATIONS_BY_USER, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Notifications by Transaction
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.user
 */
function* getTransactionNotifications({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getNotificationsByTransactionApi, { id });
        yield put(
            notificationApiResponseSuccess(NotificationsActionTypes.GET_NOTIFICATIONS_BY_TRANSACTION, response.data)
        );
    } catch (error) {
        console.log(error);
        yield put(notificationApiResponseError(NotificationsActionTypes.GET_NOTIFICATIONS_BY_TRANSACTION, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Update a Notification
 * @param {object} action
 * @param {string} action.payload
 * @param {object} action.payload.data
 */
function* createNotification({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(createNotificationApi, { id, data });
        yield put(notificationApiResponseSuccess(NotificationsActionTypes.CREATE_NOTIFICATION, response.data));
    } catch (error) {
        console.log(error);
        yield put(notificationApiResponseError(NotificationsActionTypes.CREATE_NOTIFICATION, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Update a Notification
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* updateNotification({ payload: { id, data } }) {
    try {
        const response = yield call(updateNotificationApi, { id, data });
        yield put(notificationApiResponseSuccess(NotificationsActionTypes.UPDATE_NOTIFICATION, response.data));
    } catch (error) {
        console.log(error);
        yield put(notificationApiResponseError(NotificationsActionTypes.UPDATE_NOTIFICATION, error));
    }
    yield put(toggleLoading(false));
}

/**
 * dismiss a Notification
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* dismissNotification({ payload: { id } }) {
    try {
        const response = yield call(dismissNotificationApi, { id });
        yield put(notificationApiResponseSuccess(NotificationsActionTypes.DISMISS_NOTIFICATION, response.data));
    } catch (error) {
        console.log(error);
        yield put(notificationApiResponseError(NotificationsActionTypes.DISMISS_NOTIFICATION, error));
    }
    yield put(toggleLoading(false));
}

/**
 * acknowledge a Notification
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* acknowledgeNotification({ payload: { id } }) {
    try {
        const response = yield call(acknowledgeNotificationApi, { id });
        yield put(notificationApiResponseSuccess(NotificationsActionTypes.ACKNOWLEDGE_NOTIFICATION, response.data));
    } catch (error) {
        console.log(error);
        yield put(notificationApiResponseError(NotificationsActionTypes.ACKNOWLEDGE_NOTIFICATION, error));
    }
    yield put(toggleLoading(false));
}

export function* watchGetNotifications() {
    yield takeEvery(NotificationsActionTypes.GET_NOTIFICATIONS, getNotifications);
}

export function* watchGetNotification() {
    yield takeEvery(NotificationsActionTypes.GET_NOTIFICATION_BY_ID, getNotification);
}

export function* watchGetUserNotifications() {
    yield takeEvery(NotificationsActionTypes.GET_NOTIFICATIONS_BY_USER, getUserNotifications);
}

export function* watchGetTransactionNotifications() {
    yield takeEvery(NotificationsActionTypes.GET_NOTIFICATIONS_BY_TRANSACTION, getTransactionNotifications);
}

export function* watchCreateNotification() {
    yield takeEvery(NotificationsActionTypes.CREATE_NOTIFICATION, createNotification);
}

export function* watchUpdateNotification() {
    yield takeEvery(NotificationsActionTypes.UPDATE_NOTIFICATION, updateNotification);
}

export function* watchDismissNotification() {
    yield takeEvery(NotificationsActionTypes.DISMISS_NOTIFICATION, dismissNotification);
}

export function* watchAcknowledgeNotification() {
    yield takeEvery(NotificationsActionTypes.ACKNOWLEDGE_NOTIFICATION, acknowledgeNotification);
}

function* notificationSaga() {
    yield all([
        fork(watchGetNotifications),
        fork(watchGetNotification),
        fork(watchGetUserNotifications),
        fork(watchGetTransactionNotifications),
        fork(watchUpdateNotification),
        fork(watchCreateNotification),
        fork(watchDismissNotification),
        fork(watchAcknowledgeNotification),
    ]);
}

export default notificationSaga;
