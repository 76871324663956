import { FilesActionTypes } from './constants';

const INIT_STATE = {
    data: null,
    loading: false,
    error: null,
    updateSuccess: null,
    createFileSuccess: null,
};

const Files = (state = INIT_STATE, action) => {
    switch (action.type) {
        case FilesActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case FilesActionTypes.GET_FILES:
                    return { ...state, data: action.payload.data, loading: false };
                case FilesActionTypes.GET_FILE_BY_ID:
                    return { ...state, data: action.payload.data, loading: false };
                case FilesActionTypes.GET_FILES_BY_USER:
                    return { ...state, data: action.payload.data, loading: false };
                case FilesActionTypes.GET_FILES_BY_LISTING:
                    return { ...state, data: action.payload.data, loading: false };
                case FilesActionTypes.GET_FILES_BY_ORGANIZATION:
                    return { ...state, data: action.payload.data, loading: false };
                case FilesActionTypes.GET_FILES_BY_TASK:
                    return { ...state, data: action.payload.data, loading: false };
                case FilesActionTypes.GET_FILES_BY_TRANSACTION:
                    return { ...state, data: action.payload.data, loading: false };
                case FilesActionTypes.CREATE_FILE:
                    return {
                        ...state,
                        parent: action.payload.parent,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        createFileSuccess: true,
                    };
                case FilesActionTypes.UPDATE_FILE:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                default:
                    return { ...state };
            }
        case FilesActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case FilesActionTypes.GET_FILES:
                    return { ...state, error: action.payload, loading: false };
                case FilesActionTypes.GET_FILE_BY_ID:
                    return { ...state, error: action.payload, loading: false };
                case FilesActionTypes.GET_FILES_BY_USER:
                    return { ...state, error: action.payload, loading: false };
                case FilesActionTypes.GET_FILES_BY_LISTING:
                    return { ...state, error: action.payload, loading: false };
                case FilesActionTypes.GET_FILES_BY_ORGANIZATION:
                    return { ...state, error: action.payload, loading: false };
                case FilesActionTypes.GET_FILES_BY_TASK:
                    return { ...state, error: action.payload, loading: false };
                case FilesActionTypes.GET_FILES_BY_TRANSACTION:
                    return { ...state, error: action.payload, loading: false };
                case FilesActionTypes.CREATE_FILE:
                    return {
                        ...state,
                        error: action.payload,
                        loading: false,
                        updateSuccess: false,
                        createFileSuccess: false,
                    };
                case FilesActionTypes.UPDATE_FILE:
                    return {
                        ...state,
                        error: action.payload,
                        loading: false,
                        updateSuccess: false,
                        createFileSuccess: false,
                    };
                default:
                    return { ...state };
            }
        case FilesActionTypes.GET_FILES:
            return { ...state, loading: true };
        case FilesActionTypes.GET_FILE_BY_ID:
            return { ...state, data: null, loading: true };
        case FilesActionTypes.GET_FILES_BY_USER:
            return { ...state, data: null, loading: true };
        case FilesActionTypes.GET_FILES_BY_LISTING:
            return { ...state, data: null, loading: true };
        case FilesActionTypes.GET_FILES_BY_ORGANIZATION:
            return { ...state, data: null, loading: true };
        case FilesActionTypes.GET_FILES_BY_TASK:
            return { ...state, data: null, loading: true };
        case FilesActionTypes.GET_FILES_BY_TRANSACTION:
            return { ...state, data: null, loading: true };
        case FilesActionTypes.CREATE_FILE:
            return { ...state, data: null, loading: true };
        case FilesActionTypes.UPDATE_FILE:
            return { ...state, data: null, loading: true };
        case FilesActionTypes.RESET_FILES:
            return INIT_STATE;
        default:
            return { ...state, updateSuccess: null, createFileSuccess: null };
    }
};

export default Files;
