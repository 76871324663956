import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { transactionApiResponseSuccess, transactionApiResponseError } from './actions';
import { TransactionsActionTypes } from './constants';
import { toggleLoading } from '../actions';
import {
    createTransaction as createTransactionApi,
    getTransaction as getTransactionApi,
    getTransactions as getTransactionsApi,
    getUserTransactions as getUserTransactionsApi,
    updateTransaction as updateTransactionApi,
} from '../../helpers';

/**
 * Get Transactions
 * @param {object} action
 * @param {string} action.payload
 */
function* getTransactions() {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getTransactionsApi);
        yield put(transactionApiResponseSuccess(TransactionsActionTypes.GET_TRANSACTIONS, response.data));
    } catch (error) {
        yield put(transactionApiResponseError(TransactionsActionTypes.GET_TRANSACTIONS, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Transactions by ID
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 */
function* getTransaction({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getTransactionApi, { id });
        yield put(transactionApiResponseSuccess(TransactionsActionTypes.GET_TRANSACTION_BY_ID, response.data));
    } catch (error) {
        yield put(transactionApiResponseError(TransactionsActionTypes.GET_TRANSACTION_BY_ID, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Transactions by User
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.user
 */
function* getUserTransactions({ payload: { user } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getUserTransactionsApi, { user });
        yield put(transactionApiResponseSuccess(TransactionsActionTypes.GET_TRANSACTIONS_BY_USER, response.data));
    } catch (error) {
        yield put(transactionApiResponseError(TransactionsActionTypes.GET_TRANSACTIONS_BY_USER, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Update a Transaction
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* updateTransaction({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(updateTransactionApi, { id, data });
        yield put(transactionApiResponseSuccess(TransactionsActionTypes.UPDATE_TRANSACTION, response.data));
    } catch (error) {
        yield put(transactionApiResponseError(TransactionsActionTypes.UPDATE_TRANSACTION, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Create a Transaction
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* createTransaction({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(createTransactionApi, { id, data });
        yield put(transactionApiResponseSuccess(TransactionsActionTypes.CREATE_TRANSACTION, response.data));
    } catch (error) {
        yield put(transactionApiResponseError(TransactionsActionTypes.CREATE_TRANSACTION, error));
    }
    yield put(toggleLoading(false));
}

export function* watchGetTransactions() {
    yield takeEvery(TransactionsActionTypes.GET_TRANSACTIONS, getTransactions);
}

export function* watchGetTransaction() {
    yield takeEvery(TransactionsActionTypes.GET_TRANSACTION_BY_ID, getTransaction);
}

export function* watchGetUserTransactions() {
    yield takeEvery(TransactionsActionTypes.GET_TRANSACTIONS_BY_USER, getUserTransactions);
}

export function* watchUpdateTransaction() {
    yield takeEvery(TransactionsActionTypes.UPDATE_TRANSACTION, updateTransaction);
}

export function* watchCreateTransaction() {
    yield takeEvery(TransactionsActionTypes.CREATE_TRANSACTION, createTransaction);
}

function* transactionSaga() {
    yield all([
        fork(watchGetTransactions),
        fork(watchGetTransaction),
        fork(watchGetUserTransactions),
        fork(watchUpdateTransaction),
        fork(watchCreateTransaction),
    ]);
}

export default transactionSaga;
