// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

/**
 * Create a new comment
 * @param {*} params
 * @returns
 */
function createComment(params: any): any {
    const { parent, id, data } = params;
    var baseUrl;
    if (parent && id) {
        baseUrl = `${config.server.api}/v1/${parent}/${id}/comments`;
    } else {
        baseUrl = `${config.server.api}/v1/comments`;
    }
    return api.create(`${baseUrl}`, data);
}

/**
 * Update the details of a comment
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function updateComment(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/comments/${id}`;
    return api.updatePatch(`${baseUrl}`, data);
}

/**
 * Get a single comment
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getComment(params: any): any {
    const { id } = params;
    const baseUrl = `${config.server.api}/v1/comments/${id}`;
    return api.get(`${baseUrl}`);
}

/**
 * Get all comments for a listing
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getComments(params: any): any {
    const { parent, id } = params;
    var baseUrl;
    if (parent && id) {
        baseUrl = `${config.server.api}/v1/${parent}/${id}/comments`;
    } else {
        baseUrl = `${config.server.api}/v1/comments`;
    }
    return api.get(`${baseUrl}`);
}

/**
 * Get all comments submitted by a given user
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getCommentsByUser(params: any): any {
    const { user } = params;
    const baseUrl = `${config.server.api}/v1/users/${user}/comments`;
    return api.get(`${baseUrl}`);
}

/**
 * Get all comments for a listing
 * @param {*} id
 * @param {*} data
 * @param {*} params
 * @returns
 */
function getCommentsByTask(params: any): any {
    const { task } = params;
    const baseUrl = `${config.server.api}/v1/tasks/${task}/comments`;
    return api.get(`${baseUrl}`);
}

/**
 * Create a new comment on a comment
 * @param {*} params
 * @returns
 */
function createCommentAttachment(params: any): any {
    const { id, data } = params;
    const baseUrl = `${config.server.api}/v1/comments/${id}/attachments`;
    return api.create(`${baseUrl}`, data);
}

export {
    createComment,
    updateComment,
    getComment,
    getComments,
    getCommentsByTask,
    getCommentsByUser,
    createCommentAttachment,
};
