import { ExtensionActionTypes } from './constants';

const INIT_STATE = {
    data: null,
    loading: false,
    error: null,
    updateSuccess: null,
    submitSuccess: null,
};

const Extension = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ExtensionActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ExtensionActionTypes.GET_EXTENSION:
                    return { ...state, data: action.payload.data, loading: false };
                case ExtensionActionTypes.SUBMIT_EXTENSION:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        submitSuccess: true,
                    };
                case ExtensionActionTypes.CONFIRM_EXTENSION:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        submitSuccess: true,
                    };
                case ExtensionActionTypes.RETRACT_EXTENSION:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        retractSuccess: true,
                    };
                default:
                    return { ...state };
            }
        case ExtensionActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ExtensionActionTypes.GET_EXTENSION:
                    return { ...state, error: action.payload, loading: false };
                case ExtensionActionTypes.SUBMIT_EXTENSION:
                    return { ...state, error: action.payload, loading: false, submitSuccess: false };
                case ExtensionActionTypes.CONFIRM_EXTENSION:
                    return { ...state, error: action.payload, loading: false, submitSuccess: false };
                case ExtensionActionTypes.RETRACT_EXTENSION:
                    return { ...state, error: action.payload, loading: false, retractSuccess: false };
                default:
                    return { ...state };
            }
        case ExtensionActionTypes.GET_EXTENSION:
            return { ...state, data: null, loading: false };
        case ExtensionActionTypes.SUBMIT_EXTENSION:
            return { ...state, data: null, loading: false };
        case ExtensionActionTypes.CONFIRM_EXTENSION:
            return { ...state, data: null, loading: false };
        case ExtensionActionTypes.RETRACT_EXTENSION:
            return { ...state, data: null, loading: false };
        case ExtensionActionTypes.RESET_EXTENSION:
            return INIT_STATE;
        default:
            return { ...state, updateSuccess: null, submitSuccess: null };
    }
};

export default Extension;
