import { RentbackActionTypes } from './constants';

const INIT_STATE = {
    data: null,
    loading: false,
    error: null,
    updateSuccess: null,
    submitSuccess: null,
};

const Rentback = (state = INIT_STATE, action) => {
    switch (action.type) {
        case RentbackActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case RentbackActionTypes.GET_RENTBACK:
                    return { ...state, data: action.payload.data, loading: false };
                case RentbackActionTypes.SUBMIT_RENTBACK:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        submitSuccess: true,
                    };
                case RentbackActionTypes.CONFIRM_RENTBACK:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        submitSuccess: true,
                    };
                case RentbackActionTypes.RETRACT_RENTBACK:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        retractSuccess: true,
                    };
                default:
                    return { ...state };
            }
        case RentbackActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case RentbackActionTypes.GET_RENTBACK:
                    return { ...state, error: action.payload, loading: false };
                case RentbackActionTypes.SUBMIT_RENTBACK:
                    return { ...state, error: action.payload, loading: false, submitSuccess: false };
                case RentbackActionTypes.CONFIRM_RENTBACK:
                    return { ...state, error: action.payload, loading: false, submitSuccess: false };
                case RentbackActionTypes.RETRACT_RENTBACK:
                    return { ...state, error: action.payload, loading: false, retractSuccess: false };
                default:
                    return { ...state };
            }
        case RentbackActionTypes.GET_RENTBACK:
            return { ...state, data: null, loading: false };
        case RentbackActionTypes.SUBMIT_RENTBACK:
            return { ...state, data: null, loading: false };
        case RentbackActionTypes.CONFIRM_RENTBACK:
            return { ...state, data: null, loading: false };
        case RentbackActionTypes.RETRACT_RENTBACK:
            return { ...state, data: null, loading: false };
        case RentbackActionTypes.RESET_RENTBACK:
            return INIT_STATE;
        default:
            return { ...state };
    }
};

export default Rentback;
