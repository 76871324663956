import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { terminationApiResponseSuccess, terminationApiResponseError } from './actions';
import { TerminationActionTypes } from './constants';
import { toggleLoading } from '../actions';
import {
    confirmTermination as confirmTerminationApi,
    retractTermination as retractTerminationApi,
    submitTermination as submitTerminationApi,
    getTermination as getTerminationApi,
} from '../../helpers';

/**
 * Get Termination by ID
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 */
function* getTermination({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getTerminationApi, { id });
        yield put(terminationApiResponseSuccess(TerminationActionTypes.GET_TERMINATION, response.data));
    } catch (error) {
        console.log(error);
        yield put(terminationApiResponseError(TerminationActionTypes.GET_TERMINATION, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Extend a Termination
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* submitTermination({ payload: { id, data } }) {
    try {
        const response = yield call(submitTerminationApi, { id, data });
        yield put(terminationApiResponseSuccess(TerminationActionTypes.SUBMIT_TERMINATION, response.data));
    } catch (error) {
        console.log(error);
        yield put(terminationApiResponseError(TerminationActionTypes.SUBMIT_TERMINATION, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Confirm a Termination
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* confirmTermination({ payload: { id, data } }) {
    try {
        const response = yield call(confirmTerminationApi, { id, data });
        yield put(terminationApiResponseSuccess(TerminationActionTypes.CONFIRM_TERMINATION, response.data));
    } catch (error) {
        console.log(error);
        yield put(terminationApiResponseError(TerminationActionTypes.CONFIRM_TERMINATION, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Retract a Termination
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* retractTermination({ payload: { id, data } }) {
    try {
        const response = yield call(retractTerminationApi, { id, data });
        yield put(terminationApiResponseSuccess(TerminationActionTypes.RETRACT_TERMINATION, response.data));
    } catch (error) {
        console.log(error);
        yield put(terminationApiResponseError(TerminationActionTypes.RETRACT_TERMINATION, error));
    }
    yield put(toggleLoading(false));
}

export function* watchGetTermination() {
    yield takeEvery(TerminationActionTypes.GET_TERMINATION, getTermination);
}
export function* watchSubmitTermination() {
    yield takeEvery(TerminationActionTypes.SUBMIT_TERMINATION, submitTermination);
}
export function* watchConfirmTermination() {
    yield takeEvery(TerminationActionTypes.CONFIRM_TERMINATION, confirmTermination);
}
export function* watchRetractTermination() {
    yield takeEvery(TerminationActionTypes.RETRACT_TERMINATION, retractTermination);
}

function* terminationSaga() {
    yield all([
        fork(watchGetTermination),
        fork(watchSubmitTermination),
        fork(watchConfirmTermination),
        fork(watchRetractTermination),
    ]);
}

export default terminationSaga;
