import { TransactionsActionTypes } from './constants';

const INIT_STATE = {
    data: null,
    loading: false,
    error: null,
    updateSuccess: null,
};

const Transactions = (state = INIT_STATE, action) => {
    switch (action.type) {
        case TransactionsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case TransactionsActionTypes.CREATE_TRANSACTION:
                    return { ...state, data: action.payload.data, loading: false };
                case TransactionsActionTypes.GET_TRANSACTIONS:
                    return { ...state, data: action.payload.data, loading: false };
                case TransactionsActionTypes.GET_TRANSACTION_BY_ID:
                    return { ...state, data: action.payload.data, loading: false };
                case TransactionsActionTypes.GET_TRANSACTIONS_BY_USER:
                    return { ...state, data: action.payload.data, loading: false };
                case TransactionsActionTypes.UPDATE_TRANSACTION:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                case TransactionsActionTypes.DELETE_TRANSACTION:
                    return { ...state, data: action.payload.data, loading: false };
                case TransactionsActionTypes.ARCHIVE_TRANSACTION:
                    return { ...state, data: action.payload.data, loading: false };
                default:
                    return { ...state };
            }
        case TransactionsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case TransactionsActionTypes.CREATE_TRANSACTION:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case TransactionsActionTypes.GET_TRANSACTIONS:
                    return { ...state, error: action.payload, loading: false };
                case TransactionsActionTypes.GET_TRANSACTION_BY_ID:
                    return { ...state, error: action.payload, loading: false };
                case TransactionsActionTypes.GET_TRANSACTIONS_BY_USER:
                    return { ...state, error: action.payload, loading: false };
                case TransactionsActionTypes.UPDATE_TRANSACTION:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case TransactionsActionTypes.DELETE_TRANSACTION:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                case TransactionsActionTypes.ARCHIVE_TRANSACTION:
                    return { ...state, error: action.payload, loading: false, updateSuccess: false };
                default:
                    return { ...state };
            }
        // Get Transactions
        case TransactionsActionTypes.GET_TRANSACTIONS:
            return { ...state, loading: true };
        // Get a single transaction
        case TransactionsActionTypes.GET_TRANSACTION_BY_ID:
            return { ...state, data: null, loading: true };
        // Get transactions by user ID
        case TransactionsActionTypes.GET_TRANSACTIONS_BY_USER:
            return { ...state, data: null, loading: true };
        // Update a transaction
        case TransactionsActionTypes.UPDATE_TRANSACTION:
            return { ...state, id: action.payload.id, data: action.payload.data, loading: false };
        case TransactionsActionTypes.RESET_TRANSACTIONS:
            return INIT_STATE;
        default:
            return { ...state };
    }
};

export default Transactions;
