// @flow
import { AuthActionTypes } from './constants';

import { APICore } from '../../helpers/api/apiCore';

const api = new APICore();

const INIT_STATE = {
    user: api.getLoggedInUser(),
    userLoggedIn: api.getLoggedInUser() ? true : false,
    loading: false,
    updateSuccess: null,
};

type AuthAction = { type: string, payload: { actionType?: string, data?: any, error?: string } };
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const Auth = (state: State = INIT_STATE, action: AuthAction): any => {
    switch (action.type) {
        case AuthActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AuthActionTypes.LOGIN_USER: {
                    return {
                        ...state,
                        user: action.payload.data,
                        userLoggedIn: true,
                        loading: false,
                        error: null,
                    };
                }
                case AuthActionTypes.SIGNUP_USER: {
                    return {
                        ...state,
                        loading: false,
                        userSignUp: true,
                    };
                }
                case AuthActionTypes.UPDATE_USER: {
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        userUpdate: true,
                    };
                }
                case AuthActionTypes.LOGOUT_USER: {
                    return {
                        ...state,
                        user: null,
                        loading: false,
                        userLogout: true,
                        userLoggedIn: false,
                        error: null,
                    };
                }
                case AuthActionTypes.REFRESH_TOKENS: {
                    return {
                        ...state,
                        user: action.payload.data,
                        refreshToken: true,
                        loading: false,
                    };
                }
                case AuthActionTypes.FORGOT_PASSWORD: {
                    return {
                        ...state,
                        loading: false,
                        forgotPasswordSuccess: action.payload.data,
                        forgotPassword: true,
                    };
                }
                case AuthActionTypes.RESET_PASSWORD: {
                    return {
                        ...state,
                        loading: false,
                        resetPassword: true,
                        resetPasswordSuccess: true,
                    };
                }
                case AuthActionTypes.SEND_VERIFICATION_EMAIL: {
                    return {
                        ...state,
                        loading: false,
                        sendVerificationEmail: true,
                    };
                }
                case AuthActionTypes.CHANGE_PASSWORD: {
                    return {
                        ...state,
                        loading: false,
                        changePassword: true,
                        changePasswordSuccess: true,
                    };
                }
                case AuthActionTypes.UPDATE_PROFILE: {
                    return {
                        ...state,
                        user: action.payload.user,
                        data: action.payload.data,
                        loading: false,
                        updateSuccess: true,
                    };
                }
                default:
                    return { ...state };
            }

        case AuthActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AuthActionTypes.LOGIN_USER: {
                    return {
                        ...state,
                        error: action.payload.error,
                        userLoggedIn: false,
                        loading: false,
                    };
                }
                case AuthActionTypes.REFRESH_TOKENS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        userLoggedIn: false,
                        loading: false,
                        refreshToken: false,
                    };
                }
                case AuthActionTypes.SIGNUP_USER: {
                    return {
                        ...state,
                        registerError: action.payload.error,
                        userSignUp: false,
                        loading: false,
                    };
                }
                case AuthActionTypes.UPDATE_USER: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        updateSuccess: false,
                    };
                }
                case AuthActionTypes.SEND_VERIFICATION_EMAIL: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        sendVerificationEmail: false,
                    };
                }
                case AuthActionTypes.FORGOT_PASSWORD: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        forgotPassword: false,
                    };
                }
                case AuthActionTypes.RESET_PASSWORD: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        resetPassword: false,
                    };
                }
                case AuthActionTypes.CHANGE_PASSWORD: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        changePassword: false,
                        changePasswordSuccess: false,
                    };
                }
                case AuthActionTypes.UPDATE_PROFILE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        updateSuccess: false,
                    };
                }
                default:
                    return { ...state };
            }

        case AuthActionTypes.LOGIN_USER:
            return { ...state, loading: true, userLoggedIn: false };
        case AuthActionTypes.LOGOUT_USER:
            return { ...state, loading: true, userLogout: false };
        case AuthActionTypes.REFRESH_TOKENS:
            return { ...state, loading: true, refreshToken: false };
        case AuthActionTypes.SIGNUP_USER:
            return { ...state, loading: true, userSignUp: false };
        case AuthActionTypes.UPDATE_USER:
            return { ...state, loading: true, userUpdate: false };
        case AuthActionTypes.FORGOT_PASSWORD:
            return { ...state, loading: true, forgotPassword: false };
        case AuthActionTypes.RESET_PASSWORD:
            return { ...state, loading: true, resetPassword: false };
        case AuthActionTypes.CHANGE_PASSWORD:
            return { ...state, loading: true, changePassword: false };
        case AuthActionTypes.SEND_VERIFICATION_EMAIL:
            return { ...state, loading: true, sendVerificationEmail: false };
        case AuthActionTypes.UPDATE_PROFILE:
            return { ...state, loading: true, updateSuccess: false };
        case AuthActionTypes.RESET:
            return {
                user: null,
                userLoggedIn: false,
                loading: false,
                updateSuccess: null,
            };
        default:
            return { ...state };
    }
};

export default Auth;
