export const BillingActionTypes = {
    API_RESPONSE_SUCCESS: '@@billing/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@billing/API_RESPONSE_ERROR',
    // Billing
    GET_BILLING: '@@billing/GET_BILLING',
    GET_BILLING_BY_ID: '@@billing/GET_BILLING_BY_ID',
    GET_BILLING_BY_USER: '@@billing/GET_BILLING_BY_USER',
    GET_BILLING_BY_LISTING: '@@billing/GET_BILLING_BY_LISTING',
    GET_BILLING_BY_ORGANIZATION: '@@billing/GET_BILLING_BY_ORGANIZATION',
    CREATE_BILLING: '@@billing/CREATE_BILLING',
    UPDATE_BILLING: '@@billing/UPDATE_BILLING',
    DELETE_BILLING: '@@billing/DELETE_BILLING',
    RESET_BILLING: '@@billing/RESET_BILLING',
};
