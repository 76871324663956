import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { taskApiResponseSuccess, taskApiResponseError } from './actions';
import { TasksActionTypes } from './constants';
import { toggleLoading } from '../actions';
import {
    createTask as createTaskApi,
    createTaskComment as createTaskCommentApi,
    createTaskAttachment as createTaskAttachmentApi,
    getTask as getTaskApi,
    getTasks as getTasksApi,
    getUserTasks as getUserTasksApi,
    getTasksByTransaction as getTasksByTransactionApi,
    updateTask as updateTaskApi,
} from '../../helpers';

/**
 * Get Tasks
 * @param {object} action
 * @param {string} action.payload
 */
function* getTasks({ payload: {} }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getTasksApi);
        yield put(taskApiResponseSuccess(TasksActionTypes.GET_TASKS, response.data));
    } catch (error) {
        console.log(error);
        yield put(taskApiResponseError(TasksActionTypes.GET_TASKS, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Tasks by ID
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 */
function* getTask({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getTaskApi, { id });
        yield put(taskApiResponseSuccess(TasksActionTypes.GET_TASK_BY_ID, response.data));
    } catch (error) {
        console.log(error);
        yield put(taskApiResponseError(TasksActionTypes.GET_TASK_BY_ID, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Tasks by User
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.user
 */
function* getUserTasks({ payload: { user } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getUserTasksApi, { user });
        yield put(taskApiResponseSuccess(TasksActionTypes.GET_TASKS_BY_USER, response.data));
    } catch (error) {
        console.log(error);
        yield put(taskApiResponseError(TasksActionTypes.GET_TASKS_BY_USER, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Tasks by Transaction
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.user
 */
function* getTasksByTransaction({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getTasksByTransactionApi, { id });
        yield put(taskApiResponseSuccess(TasksActionTypes.GET_TASKS_BY_TRANSACTION, response.data));
    } catch (error) {
        console.log(error);
        yield put(taskApiResponseError(TasksActionTypes.GET_TASKS_BY_TRANSACTION, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Update a Task
 * @param {object} action
 * @param {string} action.payload
 * @param {object} action.payload.data
 */
function* createTask({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(createTaskApi, { id, data });
        yield put(taskApiResponseSuccess(TasksActionTypes.CREATE_TASK, response.data));
    } catch (error) {
        console.log(error);
        yield put(taskApiResponseError(TasksActionTypes.CREATE_TASK, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Update a Task
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* updateTask({ payload: { id, data } }) {
    try {
        const response = yield call(updateTaskApi, { id, data });
        yield put(taskApiResponseSuccess(TasksActionTypes.UPDATE_TASK, response.data));
    } catch (error) {
        console.log(error);
        yield put(taskApiResponseError(TasksActionTypes.UPDATE_TASK, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Comment on a task
 * @param {object} action
 * @param {string} action.payload
 * @param {object} action.payload.data
 */
function* createTaskComment({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(createTaskCommentApi, { id, data });
        yield put(taskApiResponseSuccess(TasksActionTypes.CREATE_COMMENT, response.data));
    } catch (error) {
        console.log(error);
        yield put(taskApiResponseError(TasksActionTypes.CREATE_COMMENT, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Comment on a task
 * @param {object} action
 * @param {string} action.payload
 * @param {object} action.payload.data
 */
function* createTaskAttachment({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(createTaskAttachmentApi, { id, data });
        yield put(taskApiResponseSuccess(TasksActionTypes.CREATE_ATTACHMENT, response.data));
    } catch (error) {
        console.log(error);
        yield put(taskApiResponseError(TasksActionTypes.CREATE_ATTACHMENT, error));
    }
    yield put(toggleLoading(false));
}

export function* watchGetTasks() {
    yield takeEvery(TasksActionTypes.GET_TASKS, getTasks);
}

export function* watchGetTask() {
    yield takeEvery(TasksActionTypes.GET_TASK_BY_ID, getTask);
}

export function* watchGetUserTasks() {
    yield takeEvery(TasksActionTypes.GET_TASKS_BY_USER, getUserTasks);
}

export function* watchGetTasksByTransaction() {
    yield takeEvery(TasksActionTypes.GET_TASKS_BY_TRANSACTION, getTasksByTransaction);
}

export function* watchCreateTask() {
    yield takeEvery(TasksActionTypes.CREATE_TASK, createTask);
}

export function* watchUpdateTask() {
    yield takeEvery(TasksActionTypes.UPDATE_TASK, updateTask);
}

export function* watchCreateTaskComment() {
    yield takeEvery(TasksActionTypes.CREATE_COMMENT, createTaskComment);
}

export function* watchCreateTaskAttachment() {
    yield takeEvery(TasksActionTypes.CREATE_ATTACHMENT, createTaskAttachment);
}

function* taskSaga() {
    yield all([
        fork(watchGetTasks),
        fork(watchGetTask),
        fork(watchGetUserTasks),
        fork(watchGetTasksByTransaction),
        fork(watchUpdateTask),
        fork(watchCreateTask),
        fork(watchCreateTaskComment),
        fork(watchCreateTaskAttachment),
    ]);
}

export default taskSaga;
