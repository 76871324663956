import { DisclosuresActionTypes } from './constants';

type DisclosuresAction = { type: string, payload: {} | string };

// common success
export const disclosuresApiResponseSuccess = (actionType: string, data: any): DisclosuresAction => ({
    type: DisclosuresActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const disclosuresApiResponseError = (actionType: string, error: string): DisclosuresAction => ({
    type: DisclosuresActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const createDisclosure = (listing: string, data: object): DisclosuresAction => ({
    type: DisclosuresActionTypes.CREATE_DISCLOSURE,
    payload: { listing, data },
});

export const deleteDisclosure = (id: string): DisclosuresAction => ({
    type: DisclosuresActionTypes.DELETE_DISCLOSURE,
    payload: { id },
});

export const getDisclosures = (options): DisclosuresAction => ({
    type: DisclosuresActionTypes.GET_DISCLOSURES,
    payload: options,
});

export const getDisclosure = (id: string): DisclosuresAction => ({
    type: DisclosuresActionTypes.GET_DISCLOSURE_BY_ID,
    payload: { id },
});

export const getDisclosuresByUser = (user: string): DisclosuresAction => ({
    type: DisclosuresActionTypes.GET_DISCLOSURES_BY_USER,
    payload: { user },
});

export const getDisclosuresByListing = (listing: string): DisclosuresAction => ({
    type: DisclosuresActionTypes.GET_DISCLOSURES_BY_LISTING,
    payload: { listing },
});

export const updateDisclosure = (id: string, data: object): DisclosuresAction => ({
    type: DisclosuresActionTypes.UPDATE_DISCLOSURE,
    payload: { id, data },
});

export const submitDisclosures = (id: string, data: object): DisclosuresAction => ({
    type: DisclosuresActionTypes.SUBMIT_DISCLOSURES,
    payload: { id, data },
});

export const confirmDisclosures = (id: string, data: object): DisclosuresAction => ({
    type: DisclosuresActionTypes.CONFIRM_DISCLOSURES,
    payload: { id, data },
});

export const rejectDisclosures = (id: string, data: object): DisclosuresAction => ({
    type: DisclosuresActionTypes.REJECT_DISCLOSURES,
    payload: { id, data },
});

export const resetDisclosures = (): DisclosuresAction => ({
    type: DisclosuresActionTypes.RESET_DISCLOSURES,
});
