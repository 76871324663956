import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import { offerApiResponseSuccess, offerApiResponseError } from './actions';
import { OfferActionTypes } from './constants';
import { toggleLoading } from '../actions';
import {
    createOffer as createOfferApi,
    getOffer as getOfferApi,
    getOffers as getOffersApi,
    getOffersByUser as getOffersByUserApi,
    getOffersByListing as getOffersByListingApi,
    getAccessByOffer as getAccessByOfferApi,
    revokeAccessByOffer as revokeAccessByOfferApi,
    updateOffer as updateOfferApi,
    submitOffer as submitOfferApi,
    retractOffer as retractOfferApi,
    rejectOffer as rejectOfferApi,
    acceptOffer as acceptOfferApi,
    submitCounterOffer as submitCounterOfferApi,
} from '../../helpers';

/**
 * Create a new offer
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.listing
 * @param {object} action.payload.data
 */
function* createOffer({ payload: { listing, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(createOfferApi, { listing, data });
        yield put(offerApiResponseSuccess(OfferActionTypes.CREATE_OFFER, response.data));
    } catch (error) {
        console.log(error);
        yield put(offerApiResponseError(OfferActionTypes.CREATE_OFFER, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Create a new counter offer
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.listing
 * @param {object} action.payload.data
 */
function* createCounterOffer({ payload: { listing, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(createOfferApi, { listing, data });
        yield put(offerApiResponseSuccess(OfferActionTypes.CREATE_COUNTER_OFFER, response.data));
    } catch (error) {
        console.log(error);
        yield put(offerApiResponseError(OfferActionTypes.CREATE_COUNTER_OFFER, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get Offers
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.listing
 */
function* getOffers({ payload: { listing } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getOffersApi, { listing });
        yield put(offerApiResponseSuccess(OfferActionTypes.GET_OFFERS, response.data));
    } catch (error) {
        console.log(error);
        yield put(offerApiResponseError(OfferActionTypes.GET_OFFERS, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get offers submitted by a given user
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.user
 */
function* getOffersByUser({ payload: { user, isBuying, isSelling } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getOffersByUserApi, { user, isBuying, isSelling });
        yield put(offerApiResponseSuccess(OfferActionTypes.GET_OFFERS_BY_USER, response.data));
    } catch (error) {
        console.log(error);
        yield put(offerApiResponseError(OfferActionTypes.GET_OFFERS_BY_USER, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get offers submitted for a given listing
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.listing
 */
function* getOffersByListing({ payload: { listing } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getOffersByListingApi, { listing });
        yield put(offerApiResponseSuccess(OfferActionTypes.GET_OFFERS_BY_LISTING, response.data));
    } catch (error) {
        console.log(error);
        yield put(offerApiResponseError(OfferActionTypes.GET_OFFERS_BY_LISTING, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get offer by ID
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 */
function* getOffer({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getOfferApi, { id });
        yield put(offerApiResponseSuccess(OfferActionTypes.GET_OFFER_BY_ID, response.data));
    } catch (error) {
        console.log(error);
        yield put(offerApiResponseError(OfferActionTypes.GET_OFFER_BY_ID, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Get access for an offer by ID
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 */
function* getAccessByOffer({ payload: { id } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(getAccessByOfferApi, { id });
        yield put(offerApiResponseSuccess(OfferActionTypes.GET_OFFER_ACCESS, response.data));
    } catch (error) {
        console.log(error);
        yield put(offerApiResponseError(OfferActionTypes.GET_OFFER_ACCESS, error));
    }
    yield put(toggleLoading(false));
}

function* revokeAccessByOffer({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(revokeAccessByOfferApi, { id, data });
        yield put(offerApiResponseSuccess(OfferActionTypes.REVOKE_OFFER_ACCESS, response.data));
    } catch (error) {
        console.log(error);
        yield put(offerApiResponseError(OfferActionTypes.REVOKE_OFFER_ACCESS, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Submit a completed offer
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 */
function* submitOffer({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(submitOfferApi, { id, data });
        yield put(offerApiResponseSuccess(OfferActionTypes.SUBMIT_OFFER, response.data));
    } catch (error) {
        console.log(error);
        yield put(offerApiResponseError(OfferActionTypes.SUBMIT_OFFER, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Update a offer
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* updateOffer({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(updateOfferApi, { id, data });
        yield put(offerApiResponseSuccess(OfferActionTypes.UPDATE_OFFER, response.data));
    } catch (error) {
        console.log(error);
        yield put(offerApiResponseError(OfferActionTypes.UPDATE_OFFER, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Retract an offer
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* retractOffer({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(retractOfferApi, { id, data });
        yield put(offerApiResponseSuccess(OfferActionTypes.RETRACT_OFFER, response.data));
    } catch (error) {
        console.log(error);
        yield put(offerApiResponseError(OfferActionTypes.RETRACT_OFFER, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Reject an offer
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* rejectOffer({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(rejectOfferApi, { id, data });
        yield put(offerApiResponseSuccess(OfferActionTypes.REJECT_OFFER, response.data));
    } catch (error) {
        console.log(error);
        yield put(offerApiResponseError(OfferActionTypes.REJECT_OFFER, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Accept an offer
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* acceptOffer({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(acceptOfferApi, { id, data });
        yield put(offerApiResponseSuccess(OfferActionTypes.ACCEPT_OFFER, response.data));
    } catch (error) {
        console.log(error);
        yield put(offerApiResponseError(OfferActionTypes.ACCEPT_OFFER, error));
    }
    yield put(toggleLoading(false));
}

/**
 * Counter an offer
 * @param {object} action
 * @param {string} action.payload
 * @param {string} action.payload.id
 * @param {object} action.payload.data
 */
function* counterOffer({ payload: { id, data } }) {
    yield put(toggleLoading(true));
    try {
        const response = yield call(submitCounterOfferApi, { id, data });
        yield put(offerApiResponseSuccess(OfferActionTypes.COUNTER_OFFER, response.data));
    } catch (error) {
        console.log(error);
        yield put(offerApiResponseError(OfferActionTypes.COUNTER_OFFER, error));
    }
    yield put(toggleLoading(false));
}

export function* watchCreateOffer() {
    yield takeEvery(OfferActionTypes.CREATE_OFFER, createOffer);
}

export function* watchCreateCounterOffer() {
    yield takeEvery(OfferActionTypes.CREATE_COUNTER_OFFER, createCounterOffer);
}

export function* watchGetOffers() {
    yield takeEvery(OfferActionTypes.GET_OFFERS, getOffers);
}

export function* watchGetOffersByUser() {
    yield takeEvery(OfferActionTypes.GET_OFFERS_BY_USER, getOffersByUser);
}

export function* watchGetOffersByListing() {
    yield takeEvery(OfferActionTypes.GET_OFFERS_BY_LISTING, getOffersByListing);
}

export function* watchGetOffer() {
    yield takeEvery(OfferActionTypes.GET_OFFER_BY_ID, getOffer);
}

export function* watchGetOfferAccess() {
    yield takeEvery(OfferActionTypes.GET_OFFER_ACCESS, getAccessByOffer);
}

export function* watchRevokeOfferAccess() {
    yield takeEvery(OfferActionTypes.REVOKE_OFFER_ACCESS, revokeAccessByOffer);
}

export function* watchUpdateOffer() {
    yield takeEvery(OfferActionTypes.UPDATE_OFFER, updateOffer);
}

export function* watchSubmitOffer() {
    yield takeEvery(OfferActionTypes.SUBMIT_OFFER, submitOffer);
}

export function* watchRetractOffer() {
    yield takeEvery(OfferActionTypes.RETRACT_OFFER, retractOffer);
}

export function* watchRejectOffer() {
    yield takeEvery(OfferActionTypes.REJECT_OFFER, rejectOffer);
}

export function* watchAcceptOffer() {
    yield takeEvery(OfferActionTypes.ACCEPT_OFFER, acceptOffer);
}

export function* watchCounterOffer() {
    yield takeEvery(OfferActionTypes.COUNTER_OFFER, counterOffer);
}

function* offerSaga() {
    yield all([
        fork(watchCreateOffer),
        fork(watchCreateCounterOffer),
        fork(watchGetOffers),
        fork(watchGetOffersByUser),
        fork(watchGetOffersByListing),
        fork(watchGetOfferAccess),
        fork(watchRevokeOfferAccess),
        fork(watchGetOffer),
        fork(watchUpdateOffer),
        fork(watchSubmitOffer),
        fork(watchRetractOffer),
        fork(watchAcceptOffer),
        fork(watchRejectOffer),
        fork(watchCounterOffer),
    ]);
}

export default offerSaga;
