import { AddendumActionTypes } from './constants';

const INIT_STATE = {
    data: null,
    loading: false,
    error: null,
    updateSuccess: null,
    submitSuccess: null,
    retractSuccess: null,
};

const Addendum = (state = INIT_STATE, action) => {
    switch (action.type) {
        case AddendumActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AddendumActionTypes.GET_ADDENDUM:
                    return { ...state, data: action.payload.data, loading: false };
                case AddendumActionTypes.SUBMIT_ADDENDUM:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        submitSuccess: true,
                    };
                case AddendumActionTypes.CONFIRM_ADDENDUM:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        submitSuccess: true,
                    };
                case AddendumActionTypes.RETRACT_ADDENDUM:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        retractSuccess: true,
                    };

                case AddendumActionTypes.SUBMIT_COUNTER_ADDENDUM:
                    return {
                        ...state,
                        id: action.payload.id,
                        data: action.payload.data,
                        loading: false,
                        submitCounterSuccess: true,
                    };
                default:
                    return { ...state };
            }
        case AddendumActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AddendumActionTypes.GET_ADDENDUM:
                    return { ...state, error: action.payload, loading: false };
                case AddendumActionTypes.SUBMIT_ADDENDUM:
                    return { ...state, error: action.payload, loading: false, submitSuccess: false };
                case AddendumActionTypes.CONFIRM_ADDENDUM:
                    return { ...state, error: action.payload, loading: false, submitSuccess: false };
                case AddendumActionTypes.RETRACT_ADDENDUM:
                    return { ...state, error: action.payload, loading: false, retractSuccess: false };
                case AddendumActionTypes.SUBMIT_COUNTER_ADDENDUM:
                    return { ...state, error: action.payload, loading: false, submitCounterSuccess: false };
                default:
                    return { ...state };
            }
        case AddendumActionTypes.GET_ADDENDUM:
            return { ...state, data: null, loading: false };
        case AddendumActionTypes.SUBMIT_ADDENDUM:
            return { ...state, data: null, loading: false };
        case AddendumActionTypes.CONFIRM_ADDENDUM:
            return { ...state, data: null, loading: false };
        case AddendumActionTypes.RETRACT_ADDENDUM:
            return { ...state, data: null, loading: false };
        case AddendumActionTypes.SUBMIT_COUNTER_ADDENDUM:
            return { ...state, data: null, loading: false };
        case AddendumActionTypes.RESET_ADDENDUM:
            return INIT_STATE;
        default:
            return { ...state };
    }
};

export default Addendum;
