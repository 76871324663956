export const CommentsActionTypes = {
    API_RESPONSE_SUCCESS: '@@comments/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@comments/API_RESPONSE_ERROR',
    // Comments
    GET_COMMENTS: '@@comments/GET_COMMENTS',
    GET_COMMENT_BY_ID: '@@comments/GET_COMMENT_BY_ID',
    GET_COMMENTS_BY_USER: '@@comments/GET_COMMENTS_BY_USER',
    GET_COMMENTS_BY_TASK: '@@comments/GET_COMMENTS_BY_TASK',
    CREATE_COMMENT: '@@comments/CREATE_COMMENT',
    UPDATE_COMMENT: '@@comments/UPDATE_COMMENT',
    DELETE_COMMENT: '@@comments/DELETE_COMMENT',
    ARCHIVE_COMMENT: '@@comments/ARCHIVE_COMMENT',
    CREATE_ATTACHMENT: '@@comments/CREATE_ATTACHMENT',
    RESET_COMMENTS: '@@comments/RESET_COMMENTS',
};
