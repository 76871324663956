export const MessagesActionTypes = {
    API_RESPONSE_SUCCESS: '@@messages/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@messages/API_RESPONSE_ERROR',
    // Tasks
    GET_MESSAGES: '@@messages/GET_MESSAGES',
    GET_MESSAGE_BY_ID: '@@messages/GET_MESSAGE_BY_ID',
    GET_MESSAGES_BY_USER: '@@messages/GET_MESSAGES_BY_USER',
    GET_MESSAGES_BY_TRANSACTION: '@@messages/GET_MESSAGES_BY_TRANSACTION',
    CREATE_MESSAGE: '@@messages/CREATE_MESSAGE',
    UPDATE_MESSAGE: '@@messages/UPDATE_MESSAGE',
    DELETE_MESSAGE: '@@messages/DELETE_MESSAGE',
    ARCHIVE_MESSAGE: '@@messages/ARCHIVE_MESSAGE',
    CREATE_ATTACHMENT: '@@messages/CREATE_ATTACHMENT',
    RESET_MESSAGES: '@@messages/RESET_MESSAGES',
};
